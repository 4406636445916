// 아이디 비번 찾기

import axios from "axios";
import { useContext, useState } from "react";
import { Button, Container, Form, Navbar, Row, Col } from "react-bootstrap";
import { alert_context } from "../App";
import Title from "../brand";
import { backend_address } from "../data";

function Help() {
    const [phone, setPhone] = useState('');
    const [setMsg, setAlert] = useContext(alert_context);
    return (
        <div>
        <Navbar bg='dark' variant='dark' expand='lg'>
            <Container>
                <Title to='/' />
            </Container>
        </Navbar>
            <h5>아이디/비번 찾기</h5>
            <ul style={{textAlign: 'start'}}>
                <li> 등록하신 폰번호로 아이디와 임시패스워드가 전송됩니다</li>
                <li> 임시패스워드를 이용해 로그인 하신후, '정보수정' 메뉴에서 패스워드를 변경해주세요 </li>
            </ul>
            <Form>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Control type='text' value={phone} onChange={phoneHandler} placeholder='전화번호를 입력하세요' />
                        </Col>
                        <Col xs={3}>
                            <Button size='sm' onClick={async ()=> {await requestPassword(phone)}}>전송</Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </div>
    );
    function phoneHandler(event) {
        setPhone(event.target.value);
    }
    async function requestPassword(phone) {
        await axios.post(backend_address+'/lost-pass', {'phone':phone}).then(success).catch(fail); 
    }
    
    function success() {
        setMsg('아이디와 임시패스워드를 발송하였습니다');
        setAlert(true);
    }

    function fail() {
        setMsg('아이디와 임시패스워드 발송에 실패했습니다. 전화번호를 확인해주세요');
        setAlert(true);
    }
}



export default Help;