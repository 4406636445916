// 이용내역 확인

import { Container, Navbar } from "react-bootstrap";
import Title from "../brand";

function BookHistory() {
    // 토큰 검사해서 토큰 없으면 튕겨야함
    return (
        <div>
        <Navbar bg='dark' variant='dark' expand='lg'>
            <Container>
                <Title to='/book' />
            </Container>
        </Navbar>
            <p>이용내역 확인</p>
            <p>백엔드에서 유저의 과거 3개월 예약정보 받아서 출력</p>
        </div>
    );
}

export default BookHistory;

