// 유저일람 페이지

import axios from "axios";
import React, { useContext, useEffect, useState} from "react";
import { useAsync } from "react-async";
import { Container, Form, Navbar,Button, Table } from "react-bootstrap";
import { cookies } from "../App";
import { NowLoading } from "../brand";
import { backend_address, frontend_address, User } from "../data";
import Head from "../root/head";

const userlist_context = React.createContext();

async function admincheck() {
    // 토큰 검사해서 true, false
    let result = await axios.post(backend_address + '/admincheck', '',{
        headers : { 'Authorization': cookies.get('access_token') }
    })
    return result.data;
}


function Users() {
    // 토큰 검사해서 토큰 없으면 /manage/login으로 튕겨야함
    const { data: result, error, isLoading } = useAsync({ promiseFn: admincheck });
    const [nick, setNick] = useState('');
    const [phone, setPhone] = useState('');
    const [userlist, setUserlist] = useState([]);
    const [alluser, setAlluser] = useState();
    const [page, setPage] = useState(0);
    useEffect(()=>{
        
        let data = { 'type': 'all' , 'data': '0', 'skip': 0, 'limit': 11}
        axios.post(backend_address+'/manage-userinfo', data, {
            headers : { 'Authorization': cookies.get('access_token') }
        }).then((res)=>{
            let _data = JSON.parse(res.data);
            setAlluser(_data);
            if (userlist.length === 0) {
                setUserlist(_data);
            }
        }).catch((err)=>{
            console.log(err);
        });
        
    },[]);
    if (isLoading) {
        return (
            <NowLoading />
        );
    }

    if (error) {
        let cont = '0;url='+frontend_address
        return (
            <meta httpEquiv="refresh" content={cont}></meta>
        );
    }

    if (!result) {
        let cont = '0;url='+frontend_address
        return (
            <meta httpEquiv="refresh" content={cont}></meta>
        );
    }
    return (
        <div>
            <Head />
            <Form>
                <Form.Control value={nick} onChange={nickHandler} placeholder='닉'/>
                <Form.Control value={phone} onChange={phoneHandler} placeholder='폰번호'/>
            </Form>
            <userlist_context.Provider value={[userlist, setUserlist]}>
                <UserList list={userlist} />
            </userlist_context.Provider>
            {page > 0 && <Button size='sm' onClick={()=>{
                if ( nick.length >= 1 ) {
                    let data = { 'type': 'nick' , 'data': nick, 'skip': (page-1)*10, 'limit': 11 }
                    setPage(page-1);
                    axios.post(backend_address+'/manage-userinfo', data, {
                        headers : { 'Authorization': cookies.get('access_token') }
                    }).then((res)=>{
                        data = JSON.parse(res.data);
                        setUserlist(data);
                    }).catch((err)=>{
                        console.log(err);
                    });
                } 
                else if (phone.length > 6) {
                    let data = { 'type': 'phone' , 'data': phone, 'skip': (page-1)*10, 'limit': 11 }
                    setPage(page-1);
                    axios.post(backend_address+'/manage-userinfo', data, {
                        headers : { 'Authorization': cookies.get('access_token') }
                    }).then((res)=>{
                        data = JSON.parse(res.data)
                        
                        setUserlist(data);
                      
                        
                    }).catch((err)=>{
                        console.log(err);
                    });
                }
                else {
                    let data = { 'type': 'all' , 'data': phone, 'skip': (page-1)*10, 'limit': 11 }
                    setPage(page-1);
                    axios.post(backend_address+'/manage-userinfo', data, {
                        headers : { 'Authorization': cookies.get('access_token') }
                    }).then((res)=>{
                        data = JSON.parse(res.data)
                        if(res.data.length > 0) {
                            setAlluser(data);
                            setUserlist(data);
                        } 
                    }).catch((err)=>{
                        console.log(err);
                    });
                }
            
            }}>이전</Button>}
            &nbsp;&nbsp;
            {userlist.length === 11 && <Button size='sm' onClick={()=>{
                if ( nick.length >= 1 ) {
                    let data = { 'type': 'nick' , 'data': nick, 'skip': (page+1)*10, 'limit': 11 }
                    setPage(page+1);
                    axios.post(backend_address+'/manage-userinfo', data, {
                        headers : { 'Authorization': cookies.get('access_token') }
                    }).then((res)=>{
                        data = JSON.parse(res.data);
                        
                        setUserlist(data);
                        
                        
                    }).catch((err)=>{
                        console.log(err);
                    });
                } 
                else if (phone.length > 6) {
                    let data = { 'type': 'phone' , 'data': phone, 'skip': (page+1)*10, 'limit': 11 }
                    setPage(page+1);
                    axios.post(backend_address+'/manage-userinfo', data, {
                        headers : { 'Authorization': cookies.get('access_token') }
                    }).then((res)=>{
                        data = JSON.parse(res.data)
                        
                        setUserlist(data);
                         
                    }).catch((err)=>{
                        console.log(err);
                    });
                }
                else {
                    let data = { 'type': 'all' , 'data': phone, 'skip': (page+1)*10, 'limit': 11 }
                    setPage(page+1);
                    axios.post(backend_address+'/manage-userinfo', data, {
                        headers : { 'Authorization': cookies.get('access_token') }
                    }).then((res)=>{
                        data = JSON.parse(res.data)
                        
                        setAlluser(data);
                        setUserlist(data);
                         
                    }).catch((err)=>{
                        console.log(err);
                    });
                }
            }}>다음</Button>}
        </div>
    );
    async function nickHandler(event) {
        let _nick = event.target.value;
        setNick(_nick);
        setPage(0);
        let data = { 'type': 'nick' , 'data': _nick, 'skip': 0, 'limit': 100 }
        if (_nick.length >= 1) {
            await axios.post(backend_address+'/manage-userinfo', data, {
                headers : { 'Authorization': cookies.get('access_token') }
            }).then((res)=>{
                data = JSON.parse(res.data);
                if(res.data.length > 0) {
                    setUserlist(data);
                }
            }).catch((err)=>{
                console.log(err);
            });
        }
        else {
            setUserlist(alluser);
        }
    }
    async function phoneHandler(event) {
        let _phone = event.target.value;
        setPhone(_phone);
        setPage(0);
        let data = { 'type': 'phone' , 'data': _phone, 'skip': 0, 'limit': 100 }
        if (_phone.length > 6) {
            await axios.post(backend_address+'/manage-userinfo', data, {
                headers : { 'Authorization': cookies.get('access_token') }
            }).then((res)=>{
                
                data = JSON.parse(res.data)
                if(res.data.length > 0) {
                    setUserlist(data);
                }
                
            }).catch((err)=>{
                console.log(err);
            });
        }
        else {
            setUserlist(alluser);
        }
    }
}

function UserList(props) {
    const startree = cookies.get('startree');
    
    return (
        <Table>
            <thead style={{textAlign:'center', fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                <tr>
                <th style={{width: '6vw'}}>닉네임</th>
                <th style={{width: '6vw'}}>전화번호</th>
                <th style={{width: '5vw'}}>결제액</th>
                <th style={{width: '2vw'}}>장부</th>
                <th style={{width: '5vw'}}>선불금액</th>
                <th style={{width: '3.5vw'}}>횟수</th>
                <th style={{width: '3.5vw'}}>회차</th>
                <th style={{width: '2vw'}}>할인</th>
                {startree.rooms.map((room)=>(
                    <th key={room.name} style={{width: '5vw'}}>{room.name}</th>
                ))}
                <th>메모</th>
                <th style={{width: '3.5vw'}}></th>
                </tr>

            </thead>
            <tbody>
                {props.list.slice(0,10).map((user, index)=>(
                    <tr key={user['id']}>
                        <UserInfo user={user} index={index} />
                    </tr>
                ))}
            </tbody>
        </Table>
    );
    
}

function UserInfo (props) {
    const startree = cookies.get('startree');
    const [balance, setBalance] = useState(props.user['balance']);
    const [balance_edit, setBalance_edit] = useState(false);
    const [postpay, setPostpay] = useState(props.user['postpayment']);
    const [prepay_edit, setPrepay_edit] = useState(false);
    const [prepayamount,setPrepayamount] = useState(props.user['prepayment']);
    const [countmax, setCountmax] = useState(props.user['count_max']);
    const [max_edit, setMax_edit] = useState(false);
    const [now_edit, setNow_edit] = useState(false);
    const [countnow, setCountnow] = useState(props.user['count_now']);
    const [custom, setCustom] = useState(props.user['custom']);
    const [userlist, setUserlist] = useContext(userlist_context);
    const [descedit, setDescedit] = useState(false);
    const [description, setDescription] = useState(props.user['description']);
    
    return(
        <>
            <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>{props.user['nick_name']}</td>
            <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>{props.user['phone']}</td>     
            { !balance_edit && <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                 <span style={{cursor: 'pointer'}} onClick={()=>setBalance_edit(true)}>{balance}</span>
                 </td>}
            { balance_edit && <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                <Form>
                    <Form.Control style={{fontSize: '1.3vh'}} type='number' value={balance} onChange={balanceHandler}/>
                    <Button size='sm' onClick={async ()=>{
                        let tempuser = new User();
                        tempuser.setMember(props.user);
                        tempuser.balance = balance;
                        let data = tempuser.getData();
                        
                        updateUserList(tempuser, props.index, userlist, setUserlist);
                        setBalance_edit(false);
                        await updateUser(data);
                    }}>전송</Button>
                </Form>
            </td>}
            <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}> 
                <Form.Check type='checkbox' checked={postpay} onChange={postpaySet}/>
            </td>
            {!prepay_edit && <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                <span style={{cursor: 'pointer'}} onClick={()=>setPrepay_edit(true)}>{prepayamount}</span>
            </td>}
            {prepay_edit && <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                <Form>
                    <Form.Control style={{fontSize: '1.3vh'}} type='number' value={prepayamount} onChange={prepayHandler}/>
                    <Button size='sm' onClick={async ()=>{
                        let tempuser = new User();
                        tempuser.setMember(props.user);
                        tempuser.prepayment = prepayamount;
                        let data = tempuser.getData();
                        
                        updateUserList(tempuser, props.index, userlist, setUserlist);
                        setPrepay_edit(false);
                        await updateUser(data);
                    }}>전송</Button>
                </Form>
            </td>}
            {!max_edit && <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}> 
                <span style={{cursor: 'pointer'}} onClick={()=>setMax_edit(true)}>{countmax}</span>
            </td>}
            {max_edit && <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                <Form>
                    <Form.Control size='sm' type='number' value={countmax} onChange={maxHandler} style={{ fontSize: '1.3vh', padding:'0', margin:'0'}} />
                    <Button size='sm' onClick={async ()=>{
                        let tempuser = new User();
                        tempuser.setMember(props.user);
                        tempuser.count_max = countmax;
                        let data = tempuser.getData();
                        
                        updateUserList(tempuser, props.index, userlist, setUserlist);
                        setMax_edit(false);
                        await updateUser(data);
                    }}>전송</Button>
                </Form>
            </td>}
            {!now_edit && <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                <span style={{cursor: 'pointer'}} onClick={()=>setNow_edit(true)}>{countnow}</span>
            </td>}
            {now_edit && <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                <Form>
                    <Form.Control size='sm' type='number' value={countnow} onChange={nowHandler} style={{ fontSize: '1.3vh', padding:'0', margin:'0'}} />
                    <Button size='sm' style={{whiteSpace: 'nowrap', display: 'inline-block'}} onClick={async ()=>{
                        let tempuser = new User();
                        tempuser.setMember(props.user);
                        tempuser.count_now = countnow;
                        let data = tempuser.getData();
                        
                        updateUserList(tempuser, props.index, userlist, setUserlist);
                        setNow_edit(false);
                        await updateUser(data);
                    }}>전송</Button>
                </Form>
            </td>}
            <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
            <Form.Check type='checkbox' checked={custom} onChange={customSet}/>
            </td>
            {startree.rooms.map((room)=>{
                if (props.user.custom === false) {
                    return (
                        <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}} key={props.user.id+room.name}>{room.price}</td> 
                    );
                }
                else {
                    return (
                        <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}} key={props.user.id+room.name+'custom'}>    
                            <CustomPrice user={props.user} index={props.index} room={room} />
                        </td>
                    )    
                }
            })}
            <td style={{textAlign:'start', fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                {!descedit && 
                    <span style={{cursor: 'pointer'}} onClick={()=>setDescedit(true)}>{props.user.description}
                </span>}
                {descedit && 
                    <Form.Control style={{fontSize: '1.3vh'}} type='textarea' size='sm' value={description} onChange={descHandler} placeholder='메모를 입력하세요' />
                }
            </td>
            <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                {!descedit && 
                    <Button size='sm' onClick={()=>setDescedit(true)}>메모</Button>
                }
                {descedit && 
                    <Button size='sm' onClick={updateDesc} >전송</Button>
                }
            </td>
        </>        
    );
    
    function descHandler(event) {
        let _desc = event.target.value;
        setDescription(_desc);
    }

    function balanceHandler(event) {
        let _balance = event.target.value;
        setBalance(_balance);
    }
    
    function prepayHandler(event) {
        setPrepayamount(event.target.value);
    }

    function maxHandler(event) {
        setCountmax(event.target.value);
    }

    function nowHandler(event) {
        setCountnow(event.target.value);
    }        

    async function customSet(event) {
        let _custom = event.target.checked;
        setCustom(_custom);
        let tempuser = new User();
        tempuser.setMember(props.user);
        tempuser.custom = _custom;     
        
        for (let room of startree.rooms) {
            if (tempuser.custom_price[room.name] === undefined) {
                tempuser.custom_price[room.name] = room.price;
            }
        }

        updateUserList(tempuser, props.index, userlist, setUserlist);
        let data = tempuser.getData();  
        await updateUser(data);                
    }

    async function updateDesc(){
        let tempuser = new User();
        tempuser.setMember(props.user);
        tempuser.description = description;
        updateUserList(tempuser, props.index, userlist, setUserlist);
        let data = tempuser.getData();
        await updateUser(data);
        setDescedit(false);
    }

    async function postpaySet(event) {
        let _postpay = event.target.checked;
        setPostpay(_postpay);
        let tempuser = new User();
        tempuser.setMember(props.user);
        tempuser.postpayment = _postpay;
        let data = tempuser.getData();
        updateUserList(tempuser, props.index, userlist, setUserlist);
        await updateUser(data);
    } 
}

async function updateUser(user) {
    
    let data = JSON.stringify(user);
    await axios.post(backend_address + '/manage-moduser', {'data': data}, {
        headers : { 'Authorization': cookies.get('access_token') }
    })
}

function updateUserList(user, index, userlist, setUserlist) {
    let templist = [...userlist];
    templist[index] = user;
    setUserlist(templist);
}

function CustomPrice(props) {
    const [userlist, setUserlist] = useContext(userlist_context);
    const [edit, setEdit] = useState(false);
    const [cost, setCost] = useState(props.room.price);
    useEffect(()=>{
        if (props.user.custom_price[props.room.name]) {
            setCost(props.user.custom_price[props.room.name]);
        }
        else {
            setCost(props.room.price);
        }
    },[props.user, props.room]);
    return (
        <>
            { !edit && !props.user.custom_price[props.room.name] &&
                <span style={{cursor: 'pointer'}} onClick={()=>{setEdit(true)}}>{props.room.price}</span>
            }
            { !edit && props.user.custom_price[props.room.name] && 
                <span style={{width: '8vh', cursor: 'pointer'}} onClick={()=>{setEdit(true)}}>{props.user.custom_price[props.room.name]}</span>
            }
            { edit && 
                <Form style={{ textAlign: 'start', whiteSpace: 'nowrap', display: 'inline-block'}}>
                    <Form.Control style={{ fontSize: '1.3vh', padding: '0', margin:'0', textAlign: 'start'}} type='number' value={cost} onChange={costHandler} />
                    <Button size='sm' onClick={async ()=>{await costSet()}}>전송</Button>
                </Form>    
            }
        </>
    );

    function costHandler(event) {
        setCost(event.target.value);
    }
    async function costSet() {
       
        let tempuser = new User();
        tempuser.setMember(props.user);
        tempuser.custom_price[props.room.name] = Number(cost);
      
        let data = tempuser.getData();
        
        await updateUser(data);
        updateUserList(tempuser, props.index, userlist, setUserlist);
        
        setEdit(false);
    }
}

export default Users;