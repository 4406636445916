//휴면계정활성화

import axios from "axios";

import { useContext, useState } from "react";
import { Container, Form, Navbar, Button } from "react-bootstrap";
import { alert_context, cookies } from "../App";
import Title from "../brand";
import { backend_address } from "../data";

function Activation() {
    const [phone, setPhone] = useState('');
    const [auth, setAuth] = useState('');
    const [setMsg, setAlert] = useContext(alert_context);
    return (
        <div>
        <Navbar bg='dark' variant='dark' expand='lg'>
            <Container>
                <Title to='/' />
            </Container>
        </Navbar>
            <br/>
            <h4>회원님의 계정은 현재 휴면상태입니다.</h4>
            <br/>
            <p>등록하신 전화번호를 인증하여 계정을 활성화한 후 다시 로그인해주세요.</p>
            <Form className="d-flex">
                <Form.Group>
                    <Form.Control type='text' size='sm' value={phone} onChange={phoneHandler} />
                    <Button size='sm' onClick={async ()=> await sendPhone(phone)}>전송</Button>
                </Form.Group>
                <Form.Group>
                    <Form.Control type='text' size='sm' value={auth} onChange={authHandler} autoComplete="one-time-code" placeholder="인증번호" />
                    <Button size='sm' onClick={async ()=> await sendAuth(auth)}>인증</Button>
                </Form.Group>
            </Form>
        </div>
    );

    function authHandler(event) {
        let item = event.target.value;
        setAuth(item);
    }
    
    async function sendPhone(phone) {
        var patternPhone = /01[016789][^0][0-9]{2,3}[0-9]{3,4}/;
        if (!patternPhone.test(phone)) {
            setMsg('전화번호를 확인해주세요');
            setAlert(true);
        }
        else {
            await axios.post(backend_address + '/get-authnumber', {'phone':phone}).then((res)=>setTimer(res)).catch(err=>{phoneNotAvail(err)});
        }
        // 3분타이머 표시
    }
    async function sendAuth(auth) {
        
        await axios.post(backend_address + '/wake-user', {'phone':phone, 'auth':auth}
        ).then(res => {authOK(res)}).catch(err => {deniedAuth(err)});
        
    }
    
    async function authOK(result) {
        window.location.href = '/';
    }
    
    function phoneNotAvail(err) {
        setMsg('인증번호를 보낼 수 없었습니다');
        setAlert(true);   
    }
    
    function deniedAuth(err) {
        setMsg('인증에 실패했습니다');
        setAlert(true);
    }
    
    function phoneHandler(event) {
        let item = event.target.value;
        
        const regExp = /[^0-9]/g; 
        if(item.length > 11) {}
        else if(item=='') setPhone(item);
        else if(regExp.test(item)) {} 
        else setPhone(item);
    }
    
    function setTimer(res) {
        if (res.data == 'exist'){
            setMsg('인증번호가 전송되었습니다');
            setAlert(true);
        }
        else {
            setMsg('등록되지 않은 번호입니다');
            setAlert(true);
        }
        /* pass */
    }

}




export default Activation;