// Navbar에 들어가는 별나무합주실 타이틀

import { Navbar, Spinner } from "react-bootstrap";

function Title(props) {
    return(
        <Navbar.Brand href={props.to}>별나무합주실</Navbar.Brand>  
    );
}

function NowLoading() {
    return(
        <Spinner animation="border" role="status">
            <span  style={{display: 'flex', alignItems: 'center'}} className="visually-hidden">Loading...</span>
        </Spinner>
    );
}

export default Title;
export { NowLoading };

