// 회원가입

import React, { useContext, useState } from "react";
import { Button, Col, Container, Form, Navbar, Row, Table, useAccordionButton } from "react-bootstrap";
import Title from "../brand";
import { alert_context, cookies, info_context, log_context } from '../App';
import axios from "axios";
import { backend_address, User, BookList, frontend_address } from "../data";
import { useHistory } from "react-router-dom";


function SignUp() {
    const [user, setUser] = useContext(info_context);
    const [is_logged, setIs_logged] = useContext(log_context);
    let [setMsg, setAlert] = useContext(alert_context);
    let [user_id, setUser_id] = useState('');
    let [id_ok, setId_ok] = useState('영문과 숫자, 대소문자구분, 4-16자');
    let [unique_check, setUnique_check] = useState('중복체크');
    let [password, setPassword] = useState('');
    let [pass_ok, setPass_ok] = useState('패스워드 길이는 8자 이상이어야 합니다');
    let [pass_confirm, setPass_confirm] = useState('');
    let [same_password, setSame_password] = useState('');
    let [phone, setPhone] = useState('');
    let [auth, setAuth] = useState('');
    let [auth_ok, setAuth_ok] = useState(false);
    let [nick, setNick] = useState('');
    let [phone_auth_passed_token, setPT] = useState();
    let [id_test_passed_token, setIP] = useState();
    const [is_unique, setIs_unique] = useState(false);
    const [is_send_phone, setIs_send_phone] = useState(false);
    const [allow, setAllow] = useState(false);
    let history = useHistory();

    function idHandler(event) {
        let item = event.target.value;        
        setUnique_check('중복체크');
        setIs_unique(false);
       
        const first = /[^a-zA-Z]/;
        const regExp = /[^a-zA-Z0-9]/g; 
        if (first.test(item[0])) setId_ok('첫자는 영문이어야합니다');
        else if(item.search(/\s/) !== -1) setId_ok('공백은 포함될 수 없습니다');
        else if(regExp.test(item) || item.length > 16) {             
            setId_ok('영문과 숫자, 대소문자구분, 4-16자');
        }
        else if(item.length < 4) {
            if(item.length == 0) setId_ok('영문과 숫자, 대소문자구분, 4-16자');
            else setId_ok('아이디는 4글자 이상이어야 합니다');
            setUser_id(item);
        }
        else {
            setId_ok('OK!');
            setUser_id(item);
        }
    }

    function passHandler(event) {
        let item = event.target.value;

        if(item.search(/\s/) !== -1) setPass_ok('공백은 포함될 수 없습니다');
        else if(item.length < 8 || item.length > 20) {
            setPass_ok('패스워드 길이는 8-20자 입니다');
            setPassword(item);
        }
        else {
            setPass_ok('OK!');
            setPassword(item);
        }
    }

    function confHandler(event) {
        let item = event.target.value;
        setPass_confirm(item);
        
        if (item == '') {
            setSame_password('');
        }
        else if (item == password) {
            setSame_password('OK!');
        }
        else {
            setSame_password('패스워드가 일치하지 않습니다');
        }
    }

    function phoneHandler(event) {
        let item = event.target.value;
        setAuth_ok(false);
        setIs_send_phone(false);
        const regExp = /[^0-9]/g; 
        if(item.length > 11) {}
        else if(item=='') setPhone(item);
        else if(regExp.test(item)) {} 
        else setPhone(item);
        
    
    }

    function authHandler(event) {
        let item = event.target.value;
        setAuth(item);
    }

    function nickHandler(event) {
        let item = event.target.value;
        if(item.search(/\s/) !== -1) {}
        else if(item.search(/_/) !== -1) {}
        else if (item.length > 10) {}
        else setNick(item);
    }

    // 가입버튼
    // 각 필드에 값이 제대로 들어가있는지 확인한다. 문제 있으면 alert로 유저에게 알림
    // 값에 문제 없으면 백엔드로 회원가입 요청
    //  백엔드에서 토큰이 돌아오면 쿠키에 저장하고 메인페이지로 이동
    //  백엔드에서 에러가 돌아오면 alert로 알림.

    async function onSubmitHandler(event) {
        event.preventDefault();
        if (!allow) {
            setMsg('개인정보수집동의에 체크하셔야 서비스 이용이 가능합니다');
            setAlert(true);
        }
        else if (id_ok != 'OK!') {
            setMsg('아이디를 확인해주세요');
            setAlert(true);
        }

        else if (unique_check == '중복체크') {
            setMsg('아이디 중복체크를 해주세요');
            setAlert(true);
        }
        else if (pass_ok != 'OK!' ) {
            setMsg('패스워드를 확인해주세요');
            setAlert(true);
        }
        else if (pass_confirm != password) {
            setMsg('패스워드가 일치하지 않습니다');
            setAlert(true);
        }
        else if (!auth_ok) {
            setMsg('전화번호를 인증해주세요');
            setAlert(true);
        }
        else if (nick.length < 1) {
            setMsg('닉네임을 입력해주세요');
            setAlert(true);
        }
        
        else if (id_test_passed_token === undefined) {
            setMsg('아이디 중복체크를 다시 해주세요');
            setAlert(true);
        }
        else if (phone_auth_passed_token === undefined) {
            setMsg('전화번호 인증을 다시 해주세요');
            setAlert(true);
        }
        else {
            let data = {
                'id_test_passed_token' : id_test_passed_token,
                'phone_auth_passed_token' : phone_auth_passed_token,
                'user' : {
                    'user_id' : user_id,
                    'phone' : phone,
                    'nick_name' : nick,
                    'password' : password
                }
            }
            
            await axios.post(backend_address + '/sign-up', data).then(()=>{
                data = {
                    'user_id' : user_id,
                    'password' : password
                }
                axios.post(backend_address + '/login', data).then((res)=>{
                    let utc = new Date(res.data.exp);
                    let locale = new Date(utc - (new Date().getTimezoneOffset() * 60 *1000));
                    
                    //쿠키에 토큰 저장
                    cookies.set('access_token', res.data.access_token, {path: '/', expires: locale});
                    let userAccount = new User();
                    userAccount.id = res.data.user.id;
                    userAccount.user_id = res.data.user.user_id;
                    userAccount.phone = res.data.user.phone;
                    userAccount.nick_name = res.data.user.nick_name;
                    userAccount.last_contact = res.data.user.last_contact;
                    userAccount.created_at = res.data.user.created_at;
                    userAccount.books = new BookList(res.data.user.books);
                    userAccount.books.nick_name = userAccount.nick_name;
                    setUser(userAccount);
                    setIs_logged(true);

                    //루트 화면으로 전환
                    window.location.href = frontend_address;
                    });
            }).catch(err=>{signFailed(err)});
        }
    }
    
    function signFailed(err) {
        console.log('가입실패 에러코드:');
        console.log(err);
        setMsg('회원가입에 실패했습니다');
        setAlert(true);
    }


    // 중복체크버튼
    // 체크변수 default 값은 false
    // 아이디값이 비어있는지 확인한다. 비어있으면 리턴
    // 아이디값에 문제가 없는지 체크한다. 문제 있으면 alert로 유저에게 알림
    // 값에 문제 없으면 백엔드로 체크요청
    //  백엔드에서 OK가 오면 사용할 수 있는 ID라고 유저에게 알리고
    //    체크변수를 true로 저장
    //  백엔드에서 중복이라고 오면 alert로 유저에게 알리고 필드 초기화
    
    async function checkID() {
        // 필드 상태 점검 필요
        if (id_ok == 'OK!') {
            // 서버로 ID 전송해서 200 OK 받으면 됨
            await axios.post(backend_address + '/check-id', {
                'user_id': user_id
            }).then((res)=>{idAvailable(res)}).catch((error)=>{idNotAvailable(error)});
        }
    }

    function idAvailable(res) {
        setIP(res.data.id_test_passed_token);
        setUnique_check('사용가능');
        setIs_unique(true);
    }

    function idNotAvailable(error) {

        setUnique_check('중복체크');
        setUser_id('');
        setMsg('이미 존재하는 아이디입니다');
        setAlert(true);
        
    }

    async function sendPhone() {
        var patternPhone = /01[016789][^0][0-9]{2,3}[0-9]{3,4}/;
        if (!patternPhone.test(phone)) {
            setMsg('전화번호를 확인해주세요');
            setAlert(true);
        }
        else {
            setIs_send_phone(true);
            await axios.post(backend_address + '/get-authnumber-for-signup', {'phone':phone}).then(setTimer).catch(err=>{phoneNotAvail(err)});
        }
        // 3분타이머 표시
    }
    function setTimer() {
        setMsg('인증번호가 전송되었습니다');
        setAlert(true);
        /* pass */
    }

    function phoneNotAvail(err) {
        console.log('sms에러');
        console.log(err);
        setMsg('인증번호를 보낼 수 없었습니다');
        setAlert(true);

    }

    async function sendAuth() {
        await axios.post(backend_address + '/auth-phone', {'phone':phone, 'auth':auth}).then(res => {authOK(res)}).catch(err => {deniedAuth(err)});
    }

    function authOK(result) {
        
        setPT(result.data.phone_auth_passed_token);
        setAuth_ok(true);
        // '인증하기' 버튼을 '인증완료'로 글씨 바꿔야함
        setMsg('인증되었습니다');
        setAlert(true);
    }

    function deniedAuth(err) {
        console.log('인증에러');
        console.log(err);
        setMsg('인증에 실패했습니다');
        setAlert(true);
    }

    return(
        <div>
        <Navbar bg='dark' variant='dark' expand='lg'>
            <Container>
                <Row>
                    <Col>
                        <Title to='/' />
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                        <Navbar.Brand>회원가입</Navbar.Brand>
                    </Col>
                </Row>
            </Container>
        </Navbar>
        <br></br>
        <Container> 
        
        <div style={{width: '320px', margin: 'auto', display: 'flex'}}>  
        
        <Form>
            <Form.Group  className='mb-3' controlId="formBasicId">
            <Row>
                <Col>
                    <Form.Control type='text' value={user_id} onChange={idHandler} placeholder="아이디" />
                </Col>
                <Col xs={4} style={{display: 'flex',alignItems:'center'}} >
                    <Button size='sm' disabled={is_unique} onClick={checkID}>{unique_check}</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                <Form.Text className="text-muted">&nbsp;{id_ok}</Form.Text>
                </Col>
            </Row>
            </Form.Group>
            <Form.Group className='mb-3' controlId="formBasicPassword">
            <Row>
                <Col>
                    <Form.Control type='password' value={password} onChange={passHandler} placeholder="패스워드" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Text className="text-muted">&nbsp;{pass_ok}</Form.Text>
                </Col>
            </Row>  
            </Form.Group>
            <Form.Group className='mb-3' controlId="formBasicPasswordConfirm">
            <Row>
                <Col>
                    <Form.Control type='password' value={pass_confirm} onChange={confHandler} placeholder="패스워드확인" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Text className="text-muted">&nbsp;{same_password}</Form.Text>
                </Col>
            </Row>          
            </Form.Group>
            {/* 전화번호인증, 개인정보보호방침 */}
            <Form.Group className="mb-3"> 
                <Row>
                    <Col>
                        <Form.Control type='text' value={phone} onChange={phoneHandler} placeholder="휴대폰번호" />
                    </Col>
                    <Col xs={4} style={{display: 'flex',alignItems:'center'}}>
                        <Button size='sm' disabled={is_send_phone} onClick={sendPhone}>번호입력</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="one-time-code" value={auth} onChange={authHandler} placeholder="인증번호" /> 
                    </Col>
                    <Col xs={4} style={{display: 'flex',alignItems:'center'}}>
                        <Button size='sm' onClick={sendAuth} disabled={auth_ok}>인증하기</Button>
                    </Col>
                </Row>     
            </Form.Group>
            <Form.Group>
                <Form.Control type='text' value={nick} onChange={nickHandler} placeholder='기본닉네임' />
                <Form.Text className="text-muted">예약시에 사용되는 기본 예약명입니다</Form.Text>
            </Form.Group>
           
        </Form>
        </div>
        
        <div style={{width: '340px', margin: 'auto', alignItems: 'center'}}>
        <br></br>
        <h6>별나무 온라인 예약을 위한</h6>
        <h6> 개인정보 수집·이용 동의</h6>
        <p style={{fontSize: '9px', textAlign: 'start'}}>서비스 이용을 위하여 아래의 개인정보 수집·이용에 대한 내용을 자세히 읽어보신 후 동의여부를 결정하여 주시기 바랍니다.</p>
        
        <Table>
            <thead>
                <tr>
                    <td style={{fontSize: '12px', width: '60px'}}>항목</td>
                    <td style={{fontSize: '12px'}}>수집목적</td>
                    <td style={{fontSize: '12px'}}>보유기간</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{fontSize: '11px', fontWeight: 'bold'}}>전화번호</td>
                    <td style={{fontSize: '11px', fontWeight: 'bold'}}>예약자 식별, 예약문자 발송, 기타 예약관련 안내</td>
                    <td style={{fontSize: '11px', fontWeight: 'bold'}}>마지막 접속일로부터 3년</td>
                </tr>
            </tbody>
        </Table>
        <p style={{fontSize: '9px', textAlign:'start'}}>위와 같이 개인정보를 수집·이용하는데 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 서비스 이용이 불가능합니다.</p>
        
        <Form className='d-flex'>
            
            <Form.Check checked={allow} onChange={(event)=>{ 
                setAllow(event.target.checked);
                }}/>
            <Form.Label>&nbsp;&nbsp;상기 내용으로 개인정보제공에 동의합니다.</Form.Label>
        </Form>
        <br/>
        <Button onClick={onSubmitHandler}>회원가입</Button>
        <br/>
        <br/>
        <br/>
        </div>
        </Container>
        {/* alert 출력 컴포넌트 */}
        </div>
    );
}

export default SignUp;