import axios from "axios";
import React,{ useContext, useState } from "react";
import { useEffect } from "react";

import { useAsync } from "react-async";
import { Button, Container, Form, Navbar, Table } from "react-bootstrap";
import { cookies } from "../App";
import { NowLoading } from "../brand";
import { backend_address, cloneObject, frontend_address} from "../data";
import Head from "../root/head";



const customlist_context = React.createContext();
async function admincheck() {
    // 토큰 검사해서 true, false
    let result = await axios.post(backend_address + '/admincheck', '',{
        headers : { 'Authorization': cookies.get('access_token') }
    })

    return result.data;
}


function NonMembers() {
    // 토큰 검사해서 토큰 없으면 /manage/login으로 튕겨야함
    const { data: result, error, isLoading } = useAsync({ promiseFn: admincheck });
    if (isLoading) {
        return (
            <NowLoading />
        );
    }

    if (error) {
        let cont = '0;url='+frontend_address
        return (
            <meta httpEquiv="refresh" content={cont}></meta>
        );
    }

    if (!result) {
        let cont = '0;url='+frontend_address
        return (
            <meta httpEquiv="refresh" content={cont}></meta>
        );
    }
    return (
        <div>
            <Head />
            <NonMemberCustomList/>
        </div>
    );
}

async function LoadCustom() {
    let result = await axios.post(backend_address + '/nonmembercustom', '',{
        headers : { 'Authorization': cookies.get('access_token') }
    })
    let data = JSON.parse(result.data);
    
    return data;
}

function NonMemberCustomList () {
    const { data: clist, error, isLoading } = useAsync({promiseFn: LoadCustom});
    const [customlist, setCustomlist] = useState();
    if (isLoading) {
        return (
            <NowLoading />
        );
    }
    if (error) {
        return (
            <div>에러발생!</div>
        );
    }
    if (clist) {
        if (customlist === undefined){
            setCustomlist(clist);
        } 
        return (
            <div>
            <customlist_context.Provider value={[customlist, setCustomlist]}>
                <CustomerList />
            </customlist_context.Provider>
            </div>
        );
    }
    else {
        return(
            <div></div>
        );
    }
}
const _custom = {
    'phone' : '',
    'nick_name' : '',
    'description' : '',
    'balance' : 0,
    'custom' : false,
    'custom_price' : {},
    'prepayment' : 0,
    'count_max' : 0,
    'count_now' : 0,
    'postpay' : false,
}

function CustomerList() {
    const [customer, setCustomer] = useState(cloneObject(_custom));
    const [customlist, setCustomlist] = useContext(customlist_context);
    const startree = cookies.get('startree')
    const [phone, setPhone] = useState('');
    const [nick, setNick] = useState('');
    const [description, setDescription] = useState('');
    const [balance, setBalance] = useState(0);
    const [custom, setCustom] = useState(false);
    const [prepay, setPrepay] = useState(0);
    const [countmax, setCountmax] = useState(0);
    const [countnow, setCountnow] = useState(0);
    const [postpay, setPostpay] = useState(false);

    useEffect(()=>{
        if (Object.keys(customer.custom_price).length === 0) {
            let temp_c = cloneObject(customer);
            for (let room of startree.rooms) {
                temp_c.custom_price[room.name] = room.price;
            }
            setCustomer(temp_c);
        }
    },[customer, startree.rooms]);
    if (customer.custom_price !== {}) {
        return (
            <Table>
                <thead style={{textAlign:'center', fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                    <tr>
                    <th style={{width: '6vw'}}>폰번호</th>
                    <th style={{width: '6vw'}}>닉네임</th>
                    <th style={{width: '2vw'}}>장부</th>
                    <th style={{width: '5vw'}}>잔고</th>
                    <th style={{width: '5vw'}}>선불</th>
                    <th style={{width: '3.5vw'}}>횟수</th>
                    <th style={{width: '3.5vw'}}>경과회차</th>
                    <th style={{width: '2vw'}}>할인</th>
                    {startree.rooms.map((room)=>(
                        <th key={room.name+'label'} style={{width: '5vw'}}>{room.name}</th>
                    ))}
                    <th>메모</th>
                    <th style={{width: '3.5vw'}}></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{width: '5vw'}}>
                            <Form.Control style={{fontSize: '1.3vh'}} type='text' size='sm' value={phone} onChange={phoneHandler} placeholder='폰번호' />
                        </td>
                        <td style={{width: '5vw'}}>
                            <Form.Control style={{fontSize: '1.3vh'}} type='text' size='sm' value={nick} onChange={nickHandler} placeholder='닉네임' />
                        </td>
                        <td>
                            <Form.Check type='checkbox' checked={postpay} onChange={postpayHandler} />
                        </td>
                        <td style={{width: '5vw'}}>
                            <Form.Control style={{fontSize: '1.3vh'}} type='number' size='sm' value={balance} onChange={balanceHandler} />
                        </td>
                        <td style={{width: '5vw'}}>
                            <Form.Control style={{fontSize: '1.3vh', textAlign:'end'}} type='number' size='sm' value={prepay} onChange={prepayHandler} />
                        </td>
                        <td style={{width: '3.5vw'}}>
                            <Form.Control style={{fontSize: '1.3vh', textAlign:'end'}} type='number' size='sm' value={countmax} onChange={maxHandler} />
                        </td>
                        <td style={{width: '3.5vw'}}>                        
                            <Form.Control style={{fontSize: '1.3vh', textAlign:'end'}} type='number' size='sm' value={countnow} onChange={nowHandler} />
                        </td>
                        <td>
                            <Form.Check type='checkbox' checked={custom} onChange={customHandler}/>
                        </td>
                        
                        {startree.rooms.map((room)=>(
                            <td key={room.name+'input'} style={{width: '5vw'}} >
                                <RoomPrice room={room} customer={customer} setCustomer={setCustomer} />
                            </td>
                        ))}
                        
                        <td>
                            <Form.Control style={{fontSize: '1.3vh'}} type='textarea' size='sm' value={description} onChange={descHandler} placeholder='비고' />
                        </td>
                        <td style={{width: '3.5vw'}}>
                            <Button style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}} onClick={async ()=>{
                                await submit(customer, customlist, setCustomlist);
                                setCustomer(cloneObject(_custom));
                                setNick('');
                                setPhone('');
                                setBalance(0);
                                setPostpay(false);
                                setPrepay(0);
                                setCountmax(0);
                                setCountnow(0);
                                setCustom(false);
                                setDescription('');
                            }}>추가</Button>
                        </td>
                    </tr>
                    {customlist.map((custom, index)=>(
                        <tr key={custom.phone+'row'} style={{fontSize: '1.3vh'}}>
                            <MemberCol custom={custom} index={index}/>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }
    else {
        return (
            <div></div>
        );
    }

   
    function balanceHandler(event) {
        setBalance(event.target.value);
        let temp_c = cloneObject(customer);
        temp_c.balance = Number(event.target.value);
        setCustomer(temp_c);
    }
   
    function phoneHandler(event) {
        setPhone(event.target.value);
        let temp_c = cloneObject(customer);
        temp_c.phone = event.target.value;
        setCustomer(temp_c);
    }
    function nickHandler(event) {
        setNick(event.target.value);
        let temp_c = cloneObject(customer);
        temp_c.nick_name = event.target.value;
        setCustomer(temp_c);
    }
    function postpayHandler(event) {
        setPostpay(event.target.checked);
        let temp_c = cloneObject(customer);
        temp_c.postpay = event.target.checked;
        setCustomer(temp_c);
    }
    function customHandler(event) {
        setCustom(event.target.checked);
        let temp_c = cloneObject(customer);
        temp_c.custom = event.target.checked;
        setCustomer(temp_c);
    }
    function prepayHandler(event) {
        setPrepay(event.target.value);
        let temp_c = cloneObject(customer);
        temp_c.prepayment = Number(event.target.value);
        setCustomer(temp_c);
    }
    function maxHandler(event) {
        setCountmax(event.target.value);
        let temp_c = cloneObject(customer);
        temp_c.count_max = Number(event.target.value);
        setCustomer(temp_c);
    }
    function nowHandler(event) {
        setCountnow(event.target.value);
        let temp_c = cloneObject(customer);
        temp_c.count_now = Number(event.target.value);
        setCustomer(temp_c);
    }
    function descHandler(event) {
        setDescription(event.target.value);
        let temp_c = cloneObject(customer);
        temp_c.description = event.target.value;
        setCustomer(temp_c);
    }
}

function MemberCol(props) {
    const [customlist, setCustomlist] = useContext(customlist_context);
    const startree = cookies.get('startree');
    const [nickedit, setNickedit] = useState(false);
    const [balanceedit, setBalanceedit] = useState(false);
    const [prepayedit, setPrepayedit] = useState(false);
    const [countmaxedit, setCountmaxedit] = useState(false);
    const [countnowedit, setCountnowedit] = useState(false);
    const [descedit, setDescedit] = useState(false);
    const [nick, setNick] = useState(props.custom.nick_name);
    const [postpay, setPostpay] = useState(props.custom.postpay);
    const [balance, setBalance] = useState(props.custom.balance);
    const [prepay, setPrepay] = useState(props.custom.prepayment);
    const [countmax, setCountmax] = useState(props.custom.count_max);
    const [countnow, setCountnow] = useState(props.custom.count_now);
    const [desc, setDesc] = useState(props.custom.description);
    const [custom, setCustom] = useState(props.custom.custom);
    return (
        <>
            <td>
                {props.custom.phone}
            </td>
            <td>
                {!nickedit && <span style={{cursor: 'pointer'}} onClick={()=>{setNickedit(true)}}>
                    {props.custom.nick_name}
                </span> }
                {nickedit && <Form>
                    <Form.Control type='text' size='sm' value={nick} onChange={nickHandler} />
                    <Button size='sm' onClick={sendNick}>전송</Button>
                </Form>
                }
            </td>
            <td>
                <Form.Check checked={postpay} onChange={postpayHandler} />
            </td>
            <td>
                {!balanceedit && <span style={{cursor: 'pointer'}} onClick={()=>{setBalanceedit(true)}}>
                    {props.custom.balance}
                </span>}
                {balanceedit && <Form>
                    <Form.Control type='number' size='sm' value={balance} onChange={balanceHandler}/>
                    <Button size='sm' onClick={sendBalance}>전송</Button>
                </Form>
                }
            </td>
            <td>
                {!prepayedit && <span style={{cursor: 'pointer'}} onClick={()=>{setPrepayedit(true)}}>
                    {props.custom.prepayment}
                </span>}
                {prepayedit && <Form>
                    <Form.Control type='number' size='sm' value={prepay} onChange={prepayHandler} />
                    <Button size='sm' onClick={sendPrepay}>전송</Button>
                </Form>
                }
            </td>
            <td>
                {!countmaxedit && <span style={{cursor: 'pointer'}} onClick={()=>{setCountmaxedit(true)}}>
                    {props.custom.count_max}
                </span>}
                {countmaxedit && <Form>
                    <Form.Control type='number' size='sm' value={countmax} onChange={countmaxHandler} />
                    <Button size='sm' onClick={sendCountmax}>전송</Button>
                </Form>
                }
            </td>
            <td>
                {!countnowedit && <span style={{cursor: 'pointer'}} onClick={()=>{setCountnowedit(true)}}> 
                    {props.custom.count_now} 
                </span>}
                {countnowedit && <Form>
                    <Form.Control type='number' size='sm' value={countnow} onChange={countnowHandler}/>
                    <Button size='sm' onClick={sendCountnow}>전송</Button>
                </Form>
                }
            </td>
            <td>
                <Form.Check checked={custom} onChange={customHandler}/>
            </td>
            {startree.rooms.map((room)=>{
                if (props.custom === false) {
                    return (
                        <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}} key={props.custom.phone+room.name+'price'}>{room.price}</td>
                    );
                }
                else {
                    return (
                        <td style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}} key={props.custom.phone+room.name+'price'}>
                            <CustomPrice user={props.custom} index={props.index} room={room} />
                        </td>
                    );
                }                
            })}
            <td style={{textAlign: 'start'}}>
                {!descedit && <span style={{cursor: 'pointer'}} onClick={()=>setDescedit(true)}>
                    {props.custom.description}
                </span>}
                {descedit && <Form>
                    <Form.Control style={{fontSize: '1.3vh'}} type='textarea' size='sm' value={desc} onChange={descHandler} placeholder='메모를 입력하세요' />
                    <Button size='sm' onClick={sendDesc} >전송</Button>
                </Form>
                }
            </td>
            <td>
                <Button variant='danger' style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}} onClick={async ()=>{ await delmember(props.custom, customlist, setCustomlist) }}>삭제</Button>
            </td>
        </>
    );

    async function sendNick() {
        let _custom = cloneObject(props.custom);
        _custom.nick_name = nick;
        await updateNonMember(_custom);
        updateNonMemberList(_custom, props.index, customlist, setCustomlist);
        setNickedit(false);
        return
    }
    async function sendBalance() {
        let _custom = cloneObject(props.custom);
        _custom.balance = balance;
        await updateNonMember(_custom);
        updateNonMemberList(_custom, props.index, customlist, setCustomlist);
        setBalanceedit(false);
        return
    }

    async function sendPrepay() {
        let _custom = cloneObject(props.custom);
        _custom.prepayment = prepay;
        await updateNonMember(_custom);
        updateNonMemberList(_custom, props.index, customlist, setCustomlist);
        setPrepayedit(false);
        return
    }

    async function sendCountnow() {
        let _custom = cloneObject(props.custom);
        _custom.count_now = countnow;
        await updateNonMember(_custom);
        updateNonMemberList(_custom, props.index, customlist, setCustomlist);
        setCountnowedit(false);
        return
    }

    async function sendCountmax() {
        let _custom = cloneObject(props.custom);
        _custom.count_max = countmax;
        await updateNonMember(_custom);
        updateNonMemberList(_custom, props.index, customlist, setCustomlist);
        setCountmaxedit(false);
        return
    }

    async function sendDesc() {
        let _custom = cloneObject(props.custom);
        _custom.description = desc;
        await updateNonMember(_custom);
        updateNonMemberList(_custom, props.index, customlist, setCustomlist);
        setDescedit(false);
        return
    }

    function nickHandler(event) {
        setNick(event.target.value);
    }

    function balanceHandler(event) {
        setBalance(event.target.value);
    }

    function prepayHandler(event) {
        setPrepay(event.target.value);
    }

    function countmaxHandler(event) {
        setCountmax(event.target.value);
    }

    function countnowHandler(event) {
        setCountnow(event.target.value);
    }

    function descHandler(event) {
        setDesc(event.target.value);
    }

    async function customHandler(event) {
        setCustom(event.target.checked);
        let temp_c = {...props.custom};
        temp_c.custom = event.target.checked;
        let templist = [...customlist];
        templist[props.index] = temp_c;
        await updateNonMember(temp_c);
        setCustomlist(templist);     
    }
    async function postpayHandler(event) {
        setPostpay(event.target.checked);
        let temp_c = {...props.custom};
        temp_c.postpay = event.target.checked;
        let templist = [...customlist];
        templist[props.index] = temp_c;
        await updateNonMember(temp_c);
        setCustomlist(templist);     
    }
}

function CustomPrice(props) {
    const [userlist, setUserlist] = useContext(customlist_context);
    const [edit, setEdit] = useState(false);
    const [cost, setCost] = useState(props.room.price);
    useEffect(()=>{
        if (props.user.custom_price[props.room.name]) {
            setCost(props.user.custom_price[props.room.name]);
        }
        else {
            setCost(props.room.price);
        }
    },[props.user, props.room]);

    return (
        <>
            { !edit && !props.user.custom_price[props.room.name] &&
                <span style={{cursor: 'pointer'}} onClick={()=>{setEdit(true)}}>{props.room.price}</span>
            }
            { !edit && props.user.custom_price[props.room.name] && 
                <span style={{width: '8vh', cursor: 'pointer'}} onClick={()=>{setEdit(true)}}>{props.user.custom_price[props.room.name]}</span>
            }
            { edit && 
                <Form style={{ textAlign: 'start', whiteSpace: 'nowrap', display: 'inline-block'}}>
                    <Form.Control style={{ fontSize: '1.3vh', padding: '0', margin:'0', textAlign: 'start'}} type='number' value={cost} onChange={costHandler} />
                    <Button size='sm' onClick={async ()=>{await costSet()}}>전송</Button>
                </Form>    
            }
        </>
    );

    function costHandler(event) {
        setCost(event.target.value);
    }
    async function costSet() {
        
        let tempuser = cloneObject(props.user);
        tempuser.custom_price[props.room.name] = Number(cost);       
        await updateNonMember(tempuser);
        updateNonMemberList(tempuser, props.index, userlist, setUserlist);
        setEdit(false);
    }
}
async function updateNonMember(user) {
    
    let data = JSON.stringify(user);
    await axios.post(backend_address + '/register-non-member', {'data': data}, {
        headers : { 'Authorization': cookies.get('access_token') }
    })
}

function updateNonMemberList(user, index, userlist, setUserlist) {
    let templist = [...userlist];
    templist[index] = user;
    setUserlist(templist);
}



async function delmember(customer, customlist, setCustomlist) {
    let data = JSON.stringify(customer);
    await axios.post(backend_address+'/delete-non-member',{'data':data},{
        headers : { 'Authorization': cookies.get('access_token') }
    });
    let temp_list = [...customlist];
    let idx = temp_list.findIndex((c)=>{
        return c.phone === customer.phone;
    });
    temp_list.splice(idx,1);
    setCustomlist(temp_list);
}

async function submit(customer, customlist, setCustomlist) {
    let data = JSON.stringify(customer);
    await axios.post(backend_address+'/register-non-member',{'data':data},{
        headers : { 'Authorization': cookies.get('access_token') }
    });
    let temp_list = [...customlist];
    let idx = temp_list.findIndex((c)=>{
        return c.phone === customer.phone;
    });
    if (idx > -1) {
        temp_list[idx] = customer;
    }
    else {
        temp_list.push(customer);
    }
    setCustomlist(temp_list);
}

function RoomPrice(props) {
    const [price, setPrice] = useState(0);
    useEffect(()=>{
        if(!props.customer.custom) {
            setPrice(props.room.price);
        }
        else if(props.customer.custom_price[props.room.name]) {
            setPrice(props.customer.custom_price[props.room.name]);
        }
        
    },[props.customer, props.room.price, props.room.name]);

    return (
        <Form.Control style={{fontSize: '1.3vh', textAlign:'end'}} type='number' size='sm' value={price} onChange={priceHandler} />
    );
    function priceHandler(event) {
        setPrice(event.target.value);
        let temp_c = {...props.customer};
        temp_c.custom_price[props.room.name] = Number(event.target.value);
    }
}





export default NonMembers;
