// 관리자 메인페이지

import axios from "axios";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useAsync } from "react-async";
import MediaQuery from 'react-responsive'
import { Popover, Carousel, Col, Container, ListGroup, Row, Button, Overlay, Form, Badge, Toast, ToastContainer } from "react-bootstrap";
import { alert_context, cookies, date_context, info_context } from "../App";
import { NowLoading } from "../brand";
import { backend_address, Clock, frontend_address, is_book_conflict, ScheduleTable, TimeUnit, User, BookListManage, BookManage, miniClock, DayCalc, ToClipboard } from "../data";
import Head from "../root/head";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {ko} from 'date-fns/esm/locale';





const daily_book_context = React.createContext();
const websocket_context = React.createContext();
const table_context = React.createContext();
const editing_context = React.createContext();
const member_context = React.createContext();
const popup_context = React.createContext();
const daycalc_context = React.createContext();
const sms_context = React.createContext();
const del_context = React.createContext();

async function admincheck() {
    // 토큰 검사해서 admin level(str), false
    let result = await axios.post(backend_address + '/admincheck', '',{
        headers : { 'Authorization': cookies.get('access_token') }
    })
    return result.data;
}

function Manage() {
    
    const { data: result, error, isLoading } = useAsync({ promiseFn: admincheck });
    
    if (isLoading) {
        return (
            <NowLoading />
        );
    }

    if (error) {
        let cont = '0;url='+frontend_address
        return (
            <meta httpEquiv="refresh" content={cont}></meta>
        );
    }

    if (!result) {
        let cont = '0;url='+frontend_address
        return (
            <meta httpEquiv="refresh" content={cont}></meta>
        );
    }
    
    return (
    
        <div onContextMenu={(e)=>{
            e.preventDefault();
        }}>
        
        
        <Head page='manage' is_admin={true} />
       
        
        
        <ManageBook />
        
        

        {/*
            <p>관리자메인페이지</p>
            <p>스케쥴확인, 편집</p>
            <p>특이사항알림 설정된 유저의 예약은 표시된다.</p>
            <p>스케쥴 우클릭하면 유저정보 확인편집가능, 유저에 특이사항알림설정</p>
            <p>  해당 예약에 대여료, 복사료, 음료수, 비고 등 추가가능</p>
            <p>기타매출, 지출, 특이사항 별도입력 가능</p>
            <p>스케쥴과 입력정보에 장부가 연동되어야 함</p>
            <p>장부일람페이지로 이동</p>
            <p>유저일람페이지로 이동</p>
            <p>설정페이지로 이동</p>
            <p>예약/취소 알림기능</p>
            <p>주요연락처 확인기능</p>
            <p>스케쥴 엑셀에 출력해서 백업하기 기능</p>
        */}
        </div>
    );
}

function DelAlert(props) {
    const ws = useContext(websocket_context);
    const [del_alert, setDel_alert, book_to_del, setBook_to_del] = useContext(del_context);
    if(book_to_del) {
        console.log('del_alert', del_alert);
        console.log('book_to_del',book_to_del);
        return(
            <ToastContainer className="p-3" position='middle-center'>
                <Toast show={del_alert}>
                    <Toast.Header closeButton={false}>
                        <strong>예약삭제 버튼을 누르셨습니다.</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <p>{book_to_del.nick_name}님의 예약</p>
                        <p>정말 해당 예약을 삭제하시겠습니까?</p>
                        <Row>
                            <Col>
                                <Button variant='danger' onClick={async ()=> {
                                    props.setDeletedbooks(delBook(ws, book_to_del, setBook_to_del, props.deletedbooks));
                                }} >확인</Button>
                            </Col>
                            <Col>
                                <Button onClick={()=>setDel_alert(false)}>취소</Button>
                            </Col>
                        </Row>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        );
    }

    else {
        return (
            <div></div>
        );
    }
}

function delBook(ws, book, setBook, deletedbooks) {
    let request = [{'todo': 'cancel', 'book': book}];
    ws.current.send(JSON.stringify(request));
    setBook(null);
    console.log('delBook, deletedbooks', deletedbooks);
    let _temp = [...deletedbooks];
    _temp.push(book);
    console.log('delBook, _temp', _temp);
    return _temp;
}

async function undo_delete(ws, deletedbooks, setDeletedbooks) {
    console.log('undo_delete, books', deletedbooks);
    let temp = [...deletedbooks]
    let book = temp.pop();
    if (book) {
        let request = [{'todo': 'make', 'book': book}];
        ws.current.send(JSON.stringify(request));
    }
    setDeletedbooks(temp);
}


function ManageBook() {
    const ws = useRef(null);
    const [is_connected, setIs_connected] = useState(false);
    const [admin, setAdmin] = useContext(info_context);
    const [daily_booklist, setDaily_booklist] = useState(new BookListManage());
    const [daycalc, setDaycalc] = useState(new DayCalc());
    const [is_editing, setIs_editing] = useState(false);
    const [setMsg,setAlert] = useContext(alert_context);
    const [connect_available, setConnect_available] = useState(false);
    const [date, setDate] = useContext(date_context);
    const [editing_book, setEditing_book] = useState(new BookListManage());
    const [members, setMembers] = useState([]);
    const [editstatus, setEditstatus] = useState('editWindowClosed');
    const [sms_nick, setSms_nick] = useState('');
    const [sms_phone, setSms_phone] = useState('');
    const [disconnected, setDisconnected] = useState(false);
    const [del_alert, setDel_alert] = useState(false);
    const [book_to_del, setBook_to_del] = useState();
    const [deletedbooks, setDeletedbooks] = useState([]);
    let work = [];
    
    function processResponse(e) {
        let data = JSON.parse(e.data);
        
        if (data.type === 'daily') {
            
            let temp = new BookListManage(data.book_list);
            
            temp.sort();
            // 들어온 데일리북 체크해서 다른 사람 예약이 지금 진행중인 예약이랑 겹치면 
            // 지금 진행중인 예약내역을 삭제해야함.
            
            let temp_editing = new BookListManage(editing_book.books);
            
            for (let i in temp_editing.books) {
                let idx = temp.books.findIndex((dailybook)=>{
                    return dailybook.book_id != temp_editing.books[i].book_id && is_book_conflict(temp_editing.books[i], dailybook);
                });
                if (idx > -1) {
                    
                    let idx2 = daily_booklist.books.findIndex((book)=>{
                        return is_book_conflict(temp_editing.books[i], book);
                    });
                    if (idx2 > -1){
                                              
                        temp_editing.books[i].start_time = daily_booklist.books[idx2].start_time;
                        temp_editing.books[i].end_time = daily_booklist.books[idx2].end_time;
                    }
                    else {
                        temp_editing.books.splice(idx, 1);
                    }
                }
            }
            
            setEditing_book(new BookListManage(temp_editing.books));
            setDaily_booklist(new BookListManage(temp.books));
            
            
        }
        //손님정보 읽을 필요있을 것. 백엔드에서는 마찬가지로 관리자 확인하고 유저정보 보내줘야함.
        //단골정책이 있을 경우 병합해서 보내줘야 함.
        else if (data.type === 'members') {  
            let m = [];
            for (let member of data.data) {
                let memberAccount = new User(); 
                member['books'] = [];
                memberAccount.setMember(member);
                m.push(memberAccount);
            }
            setMembers([...m]);
        }

        else if (data.type === 'daycalc') {
            
            setDaycalc(new DayCalc(data.data));
        }

        else if (data.type === 'sms_OK') {
            setMsg(data.data);
            setAlert(true);
        }
        else if (data.type === 'user') {}

        else if (data.type === 'error') {
            console.log(data);
            setMsg(data.status);
            setAlert(true);
        }
    
        else {
            console.log('data.type', data.type);
            console.log('data.status', data.status);
        }
    }

    useEffect(()=>{
        if (connect_available) {
            ws.current = new WebSocket('wss://'+ backend_address.substring(8) +'/book');
            ws.current.onopen = () => {
                let auth_data = { 
                    'access_token': cookies.get('access_token'),
                    'id': admin.id
                }
                ws.current.send(JSON.stringify(auth_data));
                console.log('websocket opened');
                setIs_connected(true);
            }
            ws.current.onclose = () => {
                window.location.href = frontend_address;
                setDisconnected(true);
                console.log('websocket closed');
                
            }
            const wsCurrent = ws.current;

            //이펙트가 갱신된 후에 실행됨. 그런데 최초1회만 실행이니.
            return () => {
                wsCurrent.close();
            }
        }
    },[connect_available]);

    useEffect(()=>{
        if(editing_book.books.length > 0) {
            setEditstatus('editWindowOpened');
            setIs_editing(true);
        }
        else if(work.length === 0) {
            setEditstatus('editWindowClosed');
            setIs_editing(false);
        }
    },[editing_book]);

    useEffect(()=>{
        if (disconnected) {
            window.location.href = frontend_address;
        }
        if (admin.id === null) {
            return (
                <NowLoading />
            );
        }
        else if(!connect_available) {
            setConnect_available(true);
        }
        if (!ws.current) {
            return(
                <NowLoading />
            );
        }
        else {
            ws.current.onmessage = (e)=>{processResponse(e)}
        }
    });
    
    useEffect(()=>{
        setDaycalc(new DayCalc());
        if (is_connected && date) {
            let work = [{'todo': 'change_date', 'date': date.getDateString()}];
            ws.current.send(JSON.stringify(work));
            let temp = new BookListManage(editing_book.books);
            temp.books = editing_book.books.filter((book)=>{
                return book.book_id !== undefined && book.book_id !== '';
            });
            setEditing_book(temp);
        }
    },[date]);

    if(is_connected) {
        return (
            <div onContextMenu={(e)=>{
                e.preventDefault();
                setEditing_book(new BookListManage());
                setIs_editing(false);
            }}>
                <MediaQuery minWidth={1024}>
                    <Container fluid >
                    <Row>
                        <Col xs={9}>
                            <websocket_context.Provider value={ws}>
                                <SendSMS nick={sms_nick} phone={sms_phone} setNick={setSms_nick} setPhone={setSms_phone} deletedbooks={deletedbooks} setDeletedbooks={setDeletedbooks} />
                            </websocket_context.Provider>
                            <Container fluid className={editstatus}>
                            
                            <daily_book_context.Provider value={[daily_booklist,setDaily_booklist]}>
                            <websocket_context.Provider value={ws}>
                            <editing_context.Provider value={[editing_book, setEditing_book]}>
                            <daycalc_context.Provider value={[daycalc,setDaycalc]}>
                                <TimeTable />
                            </daycalc_context.Provider>
                            </editing_context.Provider>
                            </websocket_context.Provider>      
                            </daily_book_context.Provider>
                            
                            </Container>
                            
                        </Col>

                        <Col xs={3} >
                            <div className={editstatus}>
                            <Container>
                            <daily_book_context.Provider value={[daily_booklist,setDaily_booklist]}>
                            <websocket_context.Provider value={ws}>
                            <daycalc_context.Provider value={[daycalc,setDaycalc]}>
                                <Calc />
                            </daycalc_context.Provider>
                            </websocket_context.Provider>
                            </daily_book_context.Provider>
                            </Container>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    
                    <Container fluid>
                    
                            <websocket_context.Provider value={ws}>
                                <SendSMS nick={sms_nick} phone={sms_phone} setNick={setSms_nick} setPhone={setSms_phone} deletedbooks={deletedbooks} setDeletedbooks={setDeletedbooks}/>
                            </websocket_context.Provider>
                            <Container fluid className={editstatus}>
                            
                            <daily_book_context.Provider value={[daily_booklist,setDaily_booklist]}>
                            <websocket_context.Provider value={ws}>
                            <editing_context.Provider value={[editing_book, setEditing_book]}>
                            <daycalc_context.Provider value={[daycalc,setDaycalc]}>
                                <TimeTable />
                            </daycalc_context.Provider>
                            </editing_context.Provider>
                            </websocket_context.Provider>      
                            </daily_book_context.Provider>
                            
                            </Container>

                    </Container>
                    
                </MediaQuery>
                <sms_context.Provider value={[setSms_nick, setSms_phone]}>
                <daily_book_context.Provider value={[daily_booklist,setDaily_booklist]}>
                <websocket_context.Provider value={ws}>
                <editing_context.Provider value={[editing_book, setEditing_book]}>
                <member_context.Provider value={[members, setMembers]}>
                <del_context.Provider value={[del_alert, setDel_alert, book_to_del, setBook_to_del]}>
                    <div className="overflowed">
                    {is_editing && <NowEditing className="overflowed"/>}
                    </div>
                </del_context.Provider>
                </member_context.Provider>
                </editing_context.Provider>
                </websocket_context.Provider>
                </daily_book_context.Provider>
                </sms_context.Provider>
                
                <websocket_context.Provider value={ws}>
                <del_context.Provider value={[del_alert, setDel_alert, book_to_del, setBook_to_del]}>
                {del_alert && <DelAlert deletedbooks={deletedbooks} setDeletedbooks={setDeletedbooks} /> }
                </del_context.Provider>
                </websocket_context.Provider>
            </div>
        );
    }

    return (
        <NowLoading />
    );
}

function Calc() {
    const ws = useContext(websocket_context);
    const [date, setDate] = useContext(date_context);
    const [daily_booklist,setDaily_booklist] = useContext(daily_book_context);
    const [daycalc, setDaycalc] = useContext(daycalc_context);
    const [is_beginset, setIs_beginset] = useState(false);
    const [is_endset, setIs_endset] = useState(false);
    const [cash_begin1, setCash_begin1] = useState('');
    const [cash_begin2, setCash_begin2] = useState('');
    const [cash_end1, setCash_end1] = useState('');
    const [cash_end2, setCash_end2] = useState('');
    const [description, setDescription] = useState('');
    const [fee_input, setFee_input] = useState(false);
    const [item, setItem] = useState('');
    const [cost, setCost] = useState('');
    const [method, setMethod] = useState('카드');
    const [is_descset, setIs_descset] = useState(true);

    useEffect(()=>{
        setIs_beginset(false);
        setIs_endset(false);
        setCash_begin1('');
        setCash_begin2('');
        setCash_end1('');
        setCash_end2('');
        setFee_input(false);
        setDescription('');
        setItem('');
        setCost('');
        setMethod('카드');
    },[date]);

    useEffect(()=>{ 
        if (daycalc.date === date.getDateString()) {
            setCalc(daycalc);
        }
    },[daily_booklist]);
    
    return (
        <div>
            <div className="calc_fee">
                <Row>
                    <Col style={{textAlign: 'start'}}>
                        {date.getDateString()}
                    </Col>
                    <Col xs={2}>
                        <ToClipboard date={date.getDateString()} daycalc={daycalc} daily_booklist={daily_booklist} />
                    </Col>
                </Row>
                
                <Row>
                    {is_beginset && <div style={{textAlign: 'start', whiteSpace: 'nowrap', display: 'inline-block', fontSize: '1.5vh'}}>
                        <span onClick={()=>{
                                setCash_begin1('');
                                setCash_begin2('');                                
                                setIs_beginset(false);
                            }} style={{cursor: 'pointer'}}>➖</span>
                        시작현금{daycalc.cash_begin1}+{daycalc.cash_begin2}     
                    </div>}
                    {!is_beginset && <div style={{textAlign: 'start', whiteSpace: 'nowrap', display: 'inline-block'}}>
                    <Form className="d-flex">
                    시작현금
                    <Form.Control column='sm' style={{width: '25%'}} size="sm" type='text' value={cash_begin1} onChange={cb1Handler}/>+
                    <Form.Control column='sm' style={{width: '25%'}} size='sm' type='text' value={cash_begin2} onChange={cb2Handler}/>
                    <Button size="sm" onClick={()=>setCashBegin()} style={{ whiteSpace: 'nowrap', fontSize: '1.3vh', paddingTop: '0', paddingBottom: '0'}} >입력</Button>
                    </Form>
                    </div>}
                </Row>
                {is_descset && <div style={{textAlign: 'start'}}>
                    <Button size='sm' onClick={()=>{
                        setDescription(daycalc.description);
                        setIs_descset(false);
                    }} style={{ whiteSpace: 'nowrap', fontSize: '1.3vh', paddingTop: '0', paddingBottom: '0'}} >메모</Button>
                    <span style={{whiteSpace: 'pre-wrap', fontSize: '1.3vh'}}>&nbsp;{daycalc.description}</span>
                </div>}
                {!is_descset &&
                <Form>
                    <Form.Control as="textarea" rows={3} style={{fontSize: '1.3vh'}} value={description} onChange={descHandler}></Form.Control>
                    <Button size='sm' onClick={()=>updateDescription()} style={{ whiteSpace: 'nowrap', fontSize: '1.3vh', paddingTop: '0', paddingBottom: '0'}} >전송</Button>
                </Form>
                }
                <Row>
                    {daycalc.extra_fee.map((fee, index)=>(
                
                        <div className='calc_align_start' key={fee[0]+fee[1]+index}>
                            <Row>
                                
                                <Col>
                                    <span onClick={()=>{
                                        subFee(index);
                                    }} style={{cursor: 'pointer', fontSize: '1.3vh'}}>➖</span>
                                </Col>
                                <Col style={{whiteSpace: 'nowrap', fontSize: '1.3vh'}}>
                                    {fee[0]}
                                </Col>
                                <Col style={{whiteSpace: 'nowrap', fontSize: '1.3vh'}}>
                                    {fee[1]}
                                </Col>
                                <Col style={{whiteSpace: 'nowrap', fontSize: '1.3vh'}}>
                                    {fee[2]}완료
                                </Col>
                                <Col xs={1}></Col>
                            </Row>
                        </div>
                    ))}
                    {fee_input && <div className='calc_fee'>
                        <Form className="d-flex">
                            <span onClick={()=>{
                                setItem('');
                                setCost('');
                                setFee_input(false);
                            }} style={{cursor: 'pointer', fontSize: '1.3vh'}}>➖</span>                        
                            <Form.Control style={{whiteSpace: 'nowrap', fontSize: '1.3vh'}} size='sm' type='text' value={item} onChange={itemHandler} placeholder='항목' />
                            <Form.Control style={{whiteSpace: 'nowrap', fontSize: '1.3vh'}} size='sm' type='text' value={cost} onChange={costHandler} placeholder='금액' />
                            <Form.Select style={{whiteSpace: 'nowrap', fontSize: '1.3vh'}} size="sm" value={method} onChange={methodHandler}>
                                <option value='카드'>카드</option>
                                <option value='현금'>현금</option>
                                <option value='계좌'>계좌</option>
                            </Form.Select>
                            <Button style={{fontSize: '1.3vh', margin: '0', }} onClick={()=>{
                                addFee(item, cost, method);
                                setItem('');
                                setCost('');
                                setMethod('카드');
                                setFee_input(false);
                            }}>
                                입력
                            </Button>
                        </Form>
                    </div>}
                    <div className="calc_fee">
                        <Row>
                            <Col style={{textAlign: 'start'}}>
                                <span onClick={()=>setFee_input(true)} style={{cursor: 'pointer', fontSize: '1.3vh'
                            }}>요금➕</span>
                            </Col>
                            <Col>
                            </Col>
                            <Col>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </div>
                </Row>
                <Row>
                    ================================
                </Row>
            </div>
            <div>
            {daily_booklist.books.map((book)=>(
                <div key={book.book_id}>
                    
                    <CalcItem book={book} />
                   
                </div>
            ))}
            </div>
            <div className="calc_info">
                <Row>
                    <Col>
                        카드:&nbsp;{daycalc.card_total}
                    </Col>
                    
                    <Col>
                        현금:&nbsp;{daycalc.cash_total+daycalc.trans_total}(계좌:{daycalc.trans_total}&nbsp;매장{daycalc.cash_total})
                    </Col>
                    
                </Row>
                <Row>
                    {is_endset && <div style={{textAlign: 'start', whiteSpace: 'nowrap', display: 'inline-block', fontSize: '1.5vh'}}>
                        <span onClick={()=>{
                                setCash_end1('');
                                setCash_end2('');                                
                                setIs_endset(false);
                            }} style={{cursor: 'pointer'}}>➖</span>
                        종료현금{daycalc.cash_end1}+{daycalc.cash_end2}     
                    </div>}
                    {!is_endset && <div style={{textAlign: 'start', whiteSpace: 'nowrap', display: 'inline-block'}}>
                    <Form className="d-flex">
                    종료현금
                    <Form.Control column='sm' style={{width: '25%'}} size="sm" type='text' value={cash_end1} onChange={ce1Handler}/>+
                    <Form.Control column='sm' style={{width: '25%'}} size='sm' type='text' value={cash_end2} onChange={ce2Handler}/>
                    <Button size="sm" onClick={()=>setCashEnd()} style={{ whiteSpace: 'nowrap', fontSize: '1.3vh', paddingTop: '0', paddingBottom: '0'}} >입력</Button>
                    </Form>
                    </div>}
                </Row>
            </div>
            
        </div>
    );

    async function setCashBegin() {
        let tempcalc;
        if (cash_begin1 !== '' && cash_begin2 !== '') {
            tempcalc = new DayCalc(daycalc);
            tempcalc.cash_begin1 = Number(cash_begin1);
            tempcalc.cash_begin2 = Number(cash_begin2);
            await updateDayCalc(tempcalc);
            setDaycalc(tempcalc);
            setIs_beginset(true);
        }
    }

    async function updateDescription() {
        let tempcalc = new DayCalc(daycalc);
        tempcalc.description = description;
        await updateDayCalc(tempcalc);
        setDaycalc(tempcalc);
        setIs_descset(true);
    }
    async function setCashEnd() {
        let tempcalc;
        if (cash_end1 !== '' && cash_end2 !== '') {
            tempcalc = new DayCalc(daycalc);
            tempcalc.cash_end1 = Number(cash_end1);
            tempcalc.cash_end2 = Number(cash_end2);
            await updateDayCalc(tempcalc);
            setDaycalc(tempcalc);
            setIs_endset(true);
        }
    }
    
    async function updateDayCalc(calc) {
        let calcdata = calc.getData();
        
        let request = [{'todo': 'update_daycalc', 'data': calcdata}];
        await ws.current.send(JSON.stringify(request));
    }

    function cb1Handler(event) {
        let cash = event.target.value;
        const patternNum = /^[0-9]*$/;
        if(cash === '') {
            setCash_begin1(cash);
        }
        if(patternNum.test(cash)) {
            setCash_begin1(cash);
        }
    }

    function cb2Handler(event) {
        let cash = event.target.value;
        const patternNum = /^[0-9]*$/;
        if(cash === '') {
            setCash_begin2(cash);
        }
        if(patternNum.test(cash)) {
            setCash_begin2(cash);
        }
    }
    
    function ce1Handler(event) {
        let cash = event.target.value;
        const patternNum = /^[0-9]*$/;
        if(cash === '') {
            setCash_end1(cash);
        }
        if(patternNum.test(cash)) {
            setCash_end1(cash);
        }
    }

    function ce2Handler(event) {
        let cash = event.target.value;
        const patternNum = /^[0-9]*$/;
        if(cash === '') {
            setCash_end2(cash);
        }
        if(patternNum.test(cash)) {
            setCash_end2(cash);
        }
    }

    async function setCalc(dcalc = new DayCalc()) {
        let tempcalc = new DayCalc(dcalc);
        if (tempcalc.date === '') {
            tempcalc.date = date.getDateString();
        }
        
        if (tempcalc.cash_begin1 !== -1 && tempcalc.cash_begin2 !== -1) {
            setIs_beginset(true);
        }
        else {
            setIs_beginset(false);
        }
        if (tempcalc.cash_end1 !== -1 && tempcalc.cash_end2 !== -1) {
            setIs_endset(true);
        }
        else {
            setIs_endset(false);
        }
        tempcalc.card_total = 0;
        tempcalc.cash_total = 0;
        tempcalc.trans_total = 0;
        for (let book of daily_booklist.books) {
            for (let pay of book.pay_method) {
                
                if (pay[0] === '카드' && pay[2] === tempcalc.date) {
                    tempcalc.card_total += pay[1];
                }
                else if (pay[0] === '현금' && pay[2] === tempcalc.date) {
                    tempcalc.cash_total += pay[1];
                }
                else if (pay[0] === '계좌' && pay[2] === tempcalc.date && pay[3] === true) {
                    tempcalc.trans_total += pay[1];
                }
            }
        }
        for (let fee of tempcalc.extra_fee) {
            if (fee[2] === '카드') {
                tempcalc.card_total += fee[1];
            }
            else if (fee[2] === '현금') {
                tempcalc.cash_total += fee[1];
            }
            else if (fee[2] === '계좌') {
                tempcalc.trans_total += fee[1];
            }
        }
        await updateDayCalc(tempcalc);
        setDaycalc(tempcalc);

    }
    async function subFee(index) {
        let tempcalc = new DayCalc(daycalc);
        tempcalc.extra_fee.splice(index,1);
        await updateDayCalc(tempcalc);
        setDaycalc(tempcalc);
        setCalc(tempcalc);
    }

    async function addFee(item, cost, method) {
        if (item === '' || cost === '') {}
        else {
            let tempcalc = new DayCalc(daycalc);
            tempcalc.extra_fee.push([item, Number(cost), method])
            
            await updateDayCalc(tempcalc);
            setDaycalc(tempcalc);
            setCalc(tempcalc);
        }
    }
    function itemHandler(event) {
        let _item = event.target.value;
        setItem(_item);
    }

    async function descHandler(event) {
        let _desc = event.target.value;
        setDescription(_desc);
    }

    function costHandler(event) {
        let _cost = event.target.value;
        const patternNum = /^[-]?[0-9]*$/;
        if(_cost === '') {
            setCost(_cost);
        }
        if(patternNum.test(_cost)) {
            setCost(_cost);
        }
    }

    function methodHandler(event) {
        let _method = event.target.value;
        setMethod(_method);
    }

}

function CalcItem(props) {
    const ws = useContext(websocket_context);
    const [date, setDate] = useContext(date_context);
    const [daily_booklist,setDaily_booklist] = useContext(daily_book_context);
    const startree = cookies.get('startree');
    const [fee_input,setFee_input] = useState(false);
    const [item, setItem] = useState('');
    const [cost, setCost] = useState('');
    const [method, setMethod] = useState('카드');
    const [price, setPrice] = useState('');
    const [method_input, setMethod_input] = useState(false);
    const [bgcolor, setBgcolor] = useState('bg_white');
    const [paid_stat, setPaid_stat] = useState('bg_red');
    const [description, setDescription] = useState(props.book.description);
    const [is_descset, setIs_descset] = useState(true);
    const [daycalc, setDaycalc] = useContext(daycalc_context);
    const [totalPrice, setTotalPrice] = useState(props.book.totalPrice(startree, daycalc.room_state));
    
    useEffect(()=>{
        let tempbook = props.book.getCopy();
        tempbook.setBookManage(props.book);
        
        let _t = daily_booklist.books.filter((b)=>{
            return b.phone === props.book.phone;
        });
        if (_t.length > 1) {
            setBgcolor('bg_color');
        }
        else {
            setBgcolor('bg_white');
        }
        if((tempbook.pay_stat(startree, daycalc.room_state) === '결제완료' || tempbook.user.postpayment) && paid_stat === 'bg_red') {
            setPaid_stat('bg_dark');
        }
        else if (tempbook.pay_stat(startree, daycalc.room_state) !== '결제완료' && !tempbook.user.postpayment && paid_stat === 'bg_dark') {
            setPaid_stat('bg_red');
        }
        
        
    },[daily_booklist, props.book, totalPrice]);
    
    return(
        <div className={bgcolor}>
            
            <div className="calc_user">
                <Row>
                    <Col xs={4}>
                        {props.book.nick_name}님
                    </Col>
                    <Col xs={7}>
                        {props.book.room_name}룸&nbsp;&nbsp;
                        {miniClock(props.book.start_time)}-{miniClock(props.book.end_time)}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </div>
            {is_descset && <div style={{textAlign: 'start'}}>
                <Button size='sm' onClick={()=>{
                    setDescription(props.book.description);
                    setIs_descset(false);
                }} style={{ whiteSpace: 'nowrap', fontSize: '1.3vh', paddingTop: '0', paddingBottom: '0'}} >메모</Button>
                <span style={{whiteSpace: 'pre-wrap', fontSize: '1.6vh'}}>&nbsp;{props.book.description}</span>
            </div>}
            {!is_descset &&
            <Form>
                <Form.Control as="textarea" rows={3} style={{fontSize: '1.3vh'}} value={description} onChange={descHandler}></Form.Control>
                <Button size='sm' onClick={()=>updateDescription()} style={{ whiteSpace: 'nowrap', fontSize: '1.3vh', paddingTop: '0', paddingBottom: '0'}} >전송</Button>
            </Form>
            }
            
            <PrepayTeam book={props.book} setTotalPrice={setTotalPrice}/>
            
            {props.book.extra_fee.map((fee, index)=>(
                
                <div className='calc_fee' key={fee[0]+fee[1]+index}>
                    <Row>
                        <Col xs={2}>
                            
                        </Col>
                        <Col>
                            <span onClick={()=>{
                                subFee(props.book,fee);
                            }} style={{cursor: 'pointer'}}>➖</span>
                        </Col>
                        <Col>
                            {fee[0]}
                        </Col>
                        <Col>
                            {fee[1]}
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </div>
            ))}
            {fee_input &&
            <div className='calc_fee'>
                <Row>
                    <Col xs={2}>
                        
                    </Col>
                    <Col>
                        <span onClick={()=>{
                            setItem('');
                            setCost('');
                            setFee_input(false);
                        }} style={{cursor: 'pointer'}}>➖</span>
                    </Col>
                    <Col>
                        <Form.Control style={{whiteSpace:'nowrap', fontSize: '1.3vh' }} size='sm' type='text' value={item} onChange={itemHandler} placeholder='항목' />
                    </Col>
                    <Col>
                        <Form.Control style={{whiteSpace:'nowrap', fontSize: '1.3vh' }} size='sm' type='text' value={cost} onChange={costHandler} placeholder='금액' />
                        
                    </Col>
                    <Col xs={2}>
                        <Button style={{fontSize: '1.4vh', margin: '0' }} onClick={()=>{
                            addFee(props.book, item, cost);
                            setItem('');
                            setCost('');
                            setFee_input(false);
                        }}>
                            입력
                        </Button>
                    </Col>
                </Row>
            </div>
            }

            <div className="calc_fee">
                <Row>
                    <Col xs={2}>
                            
                    </Col>
                    <Col>
                        <span onClick={()=>setFee_input(true)} style={{cursor: 'pointer'}}>➕</span>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </div>

            <div className='calc_fee_total'>
                <Row>
                    <Col xs={2} />
                    <Col>
                    </Col>
                    <Col>
                        합계
                    </Col>
                    <Col>
                        {totalPrice}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </div>
            <div className={paid_stat}>
            <Row>
                
                {props.book.pay_method.map((method, index)=>{
                    
                    if(method[0] ==='계좌') {
                        if(method[3]) {
                            return(
                                <div className="calc_method" key={method[0]+method[1]+index}>
                                    <Row>
                                        <Col xs={2}>
                                            <span onClick={()=>{
                                                subMethod(props.book,method);
                                            }} style={{cursor: 'pointer'}}>➖</span>
                                        </Col>
                                        <Col>
                                            <SelectPayDate date={method[2]} index={index} book_id={props.book.book_id} />
                                        </Col>
                                        <Col>
                                            이체완료
                                        </Col>
                                        <Col>
                                            {method[1]}
                                        </Col>
                                        
                                        <Col xs={1}></Col>
                                    </Row>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div className='calc_method' key={method[0]+method[1]+index}>
                                    <Row>
                                        <Col xs={2}>
                                            <span onClick={()=>{
                                                subMethod(props.book,method);
                                            }} style={{cursor: 'pointer'}}>➖</span>
                                        </Col>
                                        <Col>
                                            <SelectPayDate  date={method[2]} index={index} book_id={props.book.book_id} />
                                        </Col>
                                        <Col>
                                            이체예정
                                        </Col>
                                        <Col>
                                            {method[1]}
                                        </Col>
                                        <Col xs={1} style={{textAlign: 'start', paddingInlineStart: '0'}}>
                                            <span style={{cursor: 'pointer'}} 
                                                onClick={()=>confirmMethod(props.book, method)}>✔</span>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    }
                    else {
                        return(
                            <div className="calc_method" key={method[0]+method[1]+index}>
                                <Row>
                                    <Col xs={2}>
                                        <span onClick={()=>{
                                            subMethod(props.book,method);
                                        }} style={{cursor: 'pointer'}}>➖</span>
                                    </Col>
                                    <Col>
                                        <SelectPayDate  date={method[2]} index={index} book_id={props.book.book_id} />
                                    </Col>
                                    <Col>
                                        {method[0]}결제
                                    </Col>
                                    <Col>
                                        {method[1]}
                                    </Col>
                                    
                                    <Col xs={1}></Col>
                                </Row>
                            </div>
                        );  
                    }
                })}
            </Row>

            <Row>
                {method_input &&
                <div className='calc_method'>
                    <Row>
                        <Col xs={2}>
                            <span onClick={()=>{
                                setMethod('카드');
                                setPrice('');
                                setMethod_input(false);
                            }} style={{cursor: 'pointer'}}>➖</span>
                        </Col>
                        <Col>
                            <Form.Select size='sm' value={method} onChange={methodHandler}>
                                <option value='카드'>카드</option>
                                <option value='현금'>현금</option>
                                <option value='계좌'>계좌</option>
                            </Form.Select>

                        </Col>
                        <Col>
                            <Form.Control size='sm' type='text' value={price} onChange={priceHandler} placeholder='금액' />
                        </Col>
                        <Col xs={2} style={{textAlign: 'start', paddingInlineStart: '0'}}>
                            <Button size='sm' style={{fontSize: '1.4vh', margin: '0', whiteSpace: 'nowrap' }} onClick={()=>{
                                addMethod(props.book, method, price);
                                setMethod('카드');
                                setPrice('');
                                setMethod_input(false);
                            }}>
                                입력
                            </Button>
                        </Col>
                    </Row>
                </div>
                }
            </Row>
            <div className="calc_method">
                <Row>
                    <Col xs={2}>
                        <span size="sm"  onClick={()=>setMethod_input(true)} style={{ cursor: 'pointer', whiteSpace: 'nowrap', fontSize: '1.3vh', paddingTop: '0', paddingBottom: '0'}} >➕결제</span>
                    </Col>
                    <Col>
                        
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </div>
            </div>
            ================================
        </div>
    );

    async function updateDescription() {
        let tempbook = props.book.getCopy();
        
        tempbook.description = description;
        
        let bookdata = tempbook.getData();
        let request = [{'todo': 'update_book_description', 'data': bookdata}];
        await ws.current.send(JSON.stringify(request));

        let tempbooks = new BookListManage(daily_booklist.books);
        let idx = tempbooks.books.findIndex((b)=>{
            return tempbook.book_id === b.book_id;
        });
        tempbooks.books[idx].description = tempbook.description;
        setDaily_booklist(tempbooks);
        setIs_descset(true);
    }

    async function confirmMethod(book, method) {
        let tempbook = book.getCopy();
        tempbook.setBookManage(book);
        let idx = tempbook.pay_method.findIndex((m)=>{
            return method[0] === m[0] && method[1] === m[1] && method[2] === method[2] && method[3] === m[3];
        });
        tempbook.pay_method[idx][3] = true;
        tempbook.paid_status = tempbook.pay_stat(startree, daycalc.room_state);
        
        let bookdata = tempbook.getData();
        let request = [{'todo': 'mod_pay_method', 'data': bookdata}]
        await ws.current.send(JSON.stringify(request));

        request = [{
            'todo': 'pay', 
            'data': { 'phone': tempbook.phone, 'cost' : tempbook.pay_method[idx][1] }
        }];
        await ws.current.send(JSON.stringify(request));

        let tempbooks = new BookListManage(daily_booklist.books);
        let idx2 = tempbooks.books.findIndex((b)=>{
            return book.book_id === b.book_id;
        });
        
        tempbooks.books[idx2].pay_method = tempbook.pay_method;
        setDaily_booklist(tempbooks);
        
    }

    async function subMethod(book, method) {
        let tempbook = book.getCopy();
        let idx;
        tempbook.setBookManage(book);
        if (method[0] === '계좌') {
            idx = tempbook.pay_method.findIndex((m)=>{
                return method[0] === m[0] && method[1] === m[1] && method[2] === m[2] && method[3] === m[3];
            });
        }
        else {
            idx = tempbook.pay_method.findIndex((m)=>{
                return method[0] === m[0] && method[1] === m[1] && method[2] === method[2];
            });
        }
        
        console.log(tempbook.pay_method[idx]);
        let request = [{
            'todo': 'pay', 
            'data': { 'phone': tempbook.phone, 'cost' : 0 - tempbook.pay_method[idx][1] }
        }];
        await ws.current.send(JSON.stringify(request));
        

        tempbook.pay_method.splice(idx,1);
        tempbook.paid_status = tempbook.pay_stat(startree, daycalc.room_state);
        let bookdata = tempbook.getData();
        request = [{'todo': 'mod_pay_method', 'data': bookdata}]
        await ws.current.send(JSON.stringify(request));

        

        let tempbooks = new BookListManage(daily_booklist.books);
        let idx2 = tempbooks.books.findIndex((b)=>{
            return book.book_id === b.book_id;
        });
         
        tempbooks.books[idx2].pay_method = tempbook.pay_method;
        setDaily_booklist(tempbooks);
    }

    async function addMethod(book, method, price) {
        if (price === '') {}
        else {
            
            let tempbook = book.getCopy();
            tempbook.setBookManage(book);
            if (method === '계좌') {
                tempbook.pay_method.push([method, Number(price), new Date().getDateString(), false]);
            }
            else {
                tempbook.pay_method.push([method, Number(price), new Date().getDateString()]);
            }
            if (method !== '계좌') {
                let request = [{
                    'todo': 'pay', 
                    'data': { 'phone': book.phone, 'cost' : Number(price) }
                }];
                await ws.current.send(JSON.stringify(request));
            }
            tempbook.paid_status = tempbook.pay_stat(startree, daycalc.room_state);
            let bookdata = tempbook.getData();
            let request = [{'todo': 'mod_pay_method', 'data': bookdata}];
            await ws.current.send(JSON.stringify(request));

            
            let tempbooks = new BookListManage(daily_booklist.books);
            let idx = tempbooks.books.findIndex((b)=>{
                return tempbook.book_id === b.book_id;
            });
            tempbooks.books[idx].pay_method = tempbook.pay_method;
            
            setDaily_booklist(tempbooks);
        }
    }
    
    async function subFee(book, fee) {
        let tempbook = book.getCopy();
        tempbook.setBookManage(book);
        let idx = tempbook.extra_fee.findIndex((f)=>{
            return fee[0] === f[0] && fee[1] === f[1];
        });
        
        tempbook.extra_fee.splice(idx,1);
        setTotalPrice(tempbook.totalPrice(startree, daycalc.room_state));
        let bookdata = tempbook.getData();
        let request = [{'todo': 'mod_extra_fee', 'data': bookdata}]
        await ws.current.send(JSON.stringify(request));

        let tempbooks = new BookListManage(daily_booklist.books);
        let idx2 = tempbooks.books.findIndex((b)=>{
            return book.book_id === b.book_id;
        });
        
        tempbooks.books[idx2].extra_fee = tempbook.extra_fee;
        setDaily_booklist(tempbooks);
    }

    async function addFee(book, item, cost) {
        if (item === '' || cost === '') {}
        else {
            let tempbook = book.getCopy();
            tempbook.setBookManage(book);
            tempbook.extra_fee.push([item, Number(cost)]);
            setTotalPrice(tempbook.totalPrice(startree, daycalc.room_state));
            let bookdata = tempbook.getData();
            let request = [{'todo': 'mod_extra_fee', 'data': bookdata}];
            await ws.current.send(JSON.stringify(request));

            let tempbooks = new BookListManage(daily_booklist.books);
            let idx = tempbooks.books.findIndex((b)=>{
                return tempbook.book_id === b.book_id;
            });
            tempbooks.books[idx].extra_fee = tempbook.extra_fee;
            setDaily_booklist(tempbooks);
            
        }
    }

    function descHandler(event) {
        let _desc = event.target.value;
        setDescription(_desc);
    }

    function itemHandler(event) {
        let _item = event.target.value;
        setItem(_item);
    }

    function methodHandler(event) {
        let _method = event.target.value;
        setMethod(_method);
    }

    function priceHandler(event) {
        let _price = event.target.value;
        const patternNum = /^[-]?[0-9]*$/;
        if(_price === '') {
            setPrice(_price);
        }
        if(patternNum.test(_price)) {
            setPrice(_price);
        }
    }

    function costHandler(event) {
        let _cost = event.target.value;
        const patternNum = /^[-]?[0-9]*$/;
        if(_cost === '') {
            setCost(_cost);
        }
        if(patternNum.test(_cost)) {
            setCost(_cost);
        }
    }
    
}

function PrepayTeam(props) {
    const ws = useContext(websocket_context);
    const [daily_booklist, setDaily_booklist] = useContext(daily_book_context);
    const [apply, setApply] = useState(props.book.apply_prepay);
    const startree = cookies.get('startree');
    const [daycalc, setDaycalc] = useContext(daycalc_context);
    const [prepaycount, setPrepaycount] = useState(props.book.prepaycount);
    
    useEffect(()=>{
        
        setApply(props.book.apply_prepay);
        setPrepaycount(props.book.prepaycount);
    },[daily_booklist]);
    
    if (props.book.user.prepayment > 0) {
        if (apply) {
            return (
                <div className='calc_fee'>
                <Row>
                    <Col xs={2} />
                        <Button size='sm' style={{fontSize: '1.3vh'}} onClick={async ()=>{
                            

                            let tempbook = props.book.getCopy();
                            tempbook.setBookManage(props.book);
                            tempbook.apply_prepay = false;
                            
                            tempbook.paid_status = tempbook.pay_stat(startree, daycalc.room_state);
                            tempbook.user.count_now = tempbook.user.count_now - (tempbook.end_time-tempbook.start_time)
                            if(tempbook.user.count_now < 0) {
                                tempbook.user.count_now = tempbook.user.count_max + tempbook.user.count_now;
                            }
                            
                            tempbook.user.balance -= tempbook.roomPrice(startree, daycalc.room_state)
                            let request = [{
                                'todo' : 'update_user',
                                'data' : tempbook.user.getData()
                            }];
                            await ws.current.send(JSON.stringify(request));

                            let request2 = [{
                                'todo' : 'update_book_prepay',
                                'data' : tempbook.getData()
                            }];
                            
                            
                            await ws.current.send(JSON.stringify(request2));

                            let tempbooks = new BookListManage(daily_booklist.books);
                            let idx = tempbooks.books.findIndex((b)=>{
                                return tempbook.book_id === b.book_id;
                            });
                            tempbooks.books[idx].user.count_now = tempbook.user.count_now;
                            tempbooks.books[idx].user.balance = tempbook.user.balance;
                            tempbooks.books[idx].paid_status = tempbook.paid_status;
                            tempbooks.books[idx].apply_prepay = tempbook.apply_prepay;
                            tempbooks.books[idx].prepaycount = tempbook.prepaycount;

                            props.setTotalPrice(tempbook.totalPrice(startree, daycalc.room_state));
                            setDaily_booklist(tempbooks);
                            setApply(false);
                        }}>선불해제</Button>
                    <Col>
                    </Col>
                    <Col>
                        회차
                    </Col>
                    <Col>
                        <Form className="d-flex">
                            <Form.Control  style={{fontSize: '1.4vh', whiteSpace: 'nowrap', width: '60%'}} size="sm" type='number' value={prepaycount} onChange={async (event)=>{
                            setPrepaycount(event.target.value);
                            let tempbook = props.book.getCopy();
                            tempbook.setBookManage(props.book);
                            tempbook.prepaycount = event.target.value;
                            let request2 = [{
                                'todo' : 'update_book_prepay',
                                'data' : tempbook.getData()
                            }];
                            
                            await ws.current.send(JSON.stringify(request2));
                            }}/>
                        /{props.book.user.count_max}
                        </Form>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
                </div>
            );
        }
        else {
            return (
                <div className='calc_fee'>
                <Row>
                    <Col xs={2} />
                        <Button size='sm' style={{fontSize: '1.3vh'}} onClick={async ()=>{
                            

                            let tempbook = props.book.getCopy();
                            tempbook.setBookManage(props.book);
                            tempbook.apply_prepay = true;
                            tempbook.paid_status = tempbook.pay_stat(startree, daycalc.room_state);

                            tempbook.user.count_now = tempbook.user.count_now + (tempbook.end_time-tempbook.start_time)
                            if(tempbook.user.count_now >= tempbook.user.count_max) {
                                tempbook.user.count_now -= tempbook.user.count_max;
                            }
                            tempbook.user.balance += tempbook.roomPrice(startree, daycalc.room_state);
                            let request = [{
                                'todo' : 'update_user',
                                'data' : tempbook.user.getData()
                            }];
                            await ws.current.send(JSON.stringify(request));

                            let request2 = [{
                                'todo' : 'update_book_prepay',
                                'data' : tempbook.getData()
                            }];
                            
                            await ws.current.send(JSON.stringify(request2));

                            let tempbooks = new BookListManage(daily_booklist.books);
                            let idx = tempbooks.books.findIndex((b)=>{
                                return tempbook.book_id === b.book_id;
                            });
                            tempbooks.books[idx].user.count_now = tempbook.user.count_now;
                            tempbooks.books[idx].user.balance = tempbook.user.balance;
                            tempbooks.books[idx].paid_status = tempbook.paid_status;
                            tempbooks.books[idx].apply_prepay = tempbook.apply_prepay;
                            
                            props.setTotalPrice(tempbook.totalPrice(startree, daycalc.room_state));
                            setDaily_booklist(tempbooks);
                            setApply(true);
                            
                        }}>선불적용</Button>
                    <Col>
                    </Col>
                    <Col>
                        대실료
                    </Col>
                    <Col>
                        {props.book.roomPrice(startree, daycalc.room_state)}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
                </div>
            );
        }
    }
    else {
        return (
            <div className='calc_fee'>
            <Row>
                <Col xs={2} />
                <Col>
                </Col>
                <Col>
                    대실료
                </Col>
                <Col>
                    {props.book.roomPrice(startree, daycalc.room_state)}
                </Col>
                <Col xs={1}></Col>
            </Row>
            </div>
        );
    }
}

function TimeTable() {
    // daily_booklist 받아서 타임테이블 만들기
    const startree = cookies.get('startree');
    const [date, setDate] = useContext(date_context);
    const [daily_booklist,setDaily_booklist] = useContext(daily_book_context);
    const [manage_table,setManage_table] = useState();
    const [index, setIndex] = useState(0);
    const [times, setTimes] = useState();
    const [is_edited, setIs_edited] = useState(false);
    const css_name = 'bookWindowClosed'
    const [scrollY, setScrollY] = useState(0);

    useEffect(()=>{
        if (startree && date) {
            
            let temp = new ScheduleTable(startree, date);
            
            temp.setManageTable(daily_booklist);
            
            setManage_table(temp);
            if (times === undefined) {
                let temp = [];
                for (let i = startree.open_time; i <= startree.close_time; i++) {
                    temp.push(i);
                }
                setTimes(temp);
            }
        }
    
    },[daily_booklist]);
    
    if(manage_table){
        return(
            <div>
                <MediaQuery minWidth={1024}>
                    {/* <Container className={css_name}> */}
                    <Row style={{padding: '0px'}}>
                        
                        <Col sm='auto' style={{padding: '0px'}}>
                        <ListGroup className="container_no_margin">
                            <ListGroup.Item key='left_clock' className="clock" style={{borderWidth: '0px'}}>
                                <h3>&nbsp;</h3>
                            </ListGroup.Item>
                            {times.map((time)=>{
                                return(
                                    <ListGroup.Item key={time} className="clock" style={{paddingRight: '0px', borderWidth: '0px'}}>
                                        <p className="clock_text">{Clock(time)}</p>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                        </Col>
                        
                        <Col style={{paddingLeft: '0.7vw'}}>  
                            
                            <Row>
                                {startree.rooms.map((room)=>{
                                    let room_name = room['name']
                                    
                                    return (
                                        
                                        <Col key={room_name} style={{padding: '0px'}}>
                                            <ListGroup>
                                                <ListGroup.Item key={room['name']}>
                                                    <p className="room" >{room_name}</p>
                                                </ListGroup.Item>
                                                
                                                <table_context.Provider value={[manage_table, setManage_table]}>
                                                    <ShowTable room_name={room_name} />
                                                </table_context.Provider>
                                                
                                            </ListGroup>
                                        </Col>
                                        
                                    );
                                })}
                            </Row>
                            
                        </Col>
                        <Col sm='auto' style={{padding: '0px'}}>
                        <ListGroup>
                            <ListGroup.Item key='right_clock' className="clock" style={{borderWidth: '0px'}}>
                                <h3>&nbsp;</h3>
                            </ListGroup.Item>
                            {times.map((time)=>{
                                return(
                                    <ListGroup.Item key={time} className="clock" style={{paddingLeft: '0px', borderWidth: '0px'}}>
                                        <p className="clock_text_right">{Clock(time)}</p>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                        </Col>
                        
                    </Row>
                    {/* </Container> */}

                </MediaQuery>
                <MediaQuery maxWidth={1023} minWidth={667}>
                    <Row style={{padding: '0px'}}>
                        
                        <Col xs='1' style={{padding: '0px'}}>
                        <ListGroup className="container_no_margin">
                            <ListGroup.Item key='left_clock' className="clock" style={{borderWidth: '0px'}}>
                                <h3>&nbsp;</h3>
                            </ListGroup.Item>
                            {times.map((time)=>{
                                return(
                                    <ListGroup.Item key={time} className="clock" style={{paddingLeft: '0px', borderWidth: '0px'}}>
                                        <p className="clock_text_right">{Clock(time)}</p>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                        </Col>
                        
                        <Col style={{paddingLeft: '0.7vw'}}>  
                            
                            <Row>
                                {startree.rooms.map((room)=>{
                                    let room_name = room['name']
                                    
                                    return (
                                        
                                        <Col key={room_name} style={{padding: '0px'}}>
                                            <ListGroup>
                                                <ListGroup.Item key={room['name']}>
                                                    <p className="room" >{room_name}</p>
                                                </ListGroup.Item>
                                                
                                                <table_context.Provider value={[manage_table, setManage_table]}>
                                                    <ShowTable room_name={room_name} />
                                                </table_context.Provider>
                                                
                                            </ListGroup>
                                        </Col>
                                        
                                    );
                                })}
                            </Row>
                            
                        </Col>
                        
                        
                    </Row>
                </MediaQuery>
                
                <MediaQuery maxWidth={666}>
                    <Container className="roomName">
                        <h1>{startree.rooms[index]['name']}</h1>
                    </Container>
                    
                    
                <Container fluid>
                    <Carousel nextIcon='' prevIcon='' indicators={false} variant='dark' interval={null} activeIndex={index} onSelect={(selectedIndex,e)=>{setIndex(selectedIndex)}} onSlide={()=>{
                        document.getElementById('scroll_container'+startree.rooms[index]['name']).scrollTo(0,scrollY);
                    }} >
                        {startree.rooms.map((room)=>{
                            
                            return(
                            
                            <Carousel.Item key={room.name}>        
                            <table_context.Provider value={[manage_table, setManage_table]}>
                                <Container id={'scroll_container'+room.name} className={css_name} onScroll={(e)=>{
                                    setScrollY(e.target.scrollTop);
                                }}>
                                    <ShowTable room_name={room.name} />
                                </Container>
                            </table_context.Provider> 
                            </Carousel.Item>
                            
                            );
                        })}
                    </Carousel>   
                </Container>        
                </MediaQuery>
                
            </div>
        );
    }
    return (
        <NowLoading />
    );
}

let inpopup = false;
function ShowTable(props) {    
    let [manage_table,setManage_table] = useContext(table_context);
    // const target = useRef(null);
    const [target, setTarget] = useState(useRef(null));
    const [bookitem, setBookitem] = useState();
    const [show, setShow] = useState(false);
    
    return(
        <div>
        <MediaQuery minWidth={667}>
            <ListGroup>
                { manage_table.table[props.room_name].map((item)=>{
                    
                    return (
                        <div key={item.time+props.room_name} className='bookbutton' >
                            <div className='bookitem'>
                            <BookButton item={item} setShow={setShow} getItem={getItem} getTarget={getTarget} />
                            </div>
                            
                        </div>
                    );
                })
                }
                
                {/* <TimeUnit time={Clock(dailytable.close_time)} /> */}
            </ListGroup>
            <Overlay target={target.current} show={show} placement='right' >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div {...props}>
                        <div className="bookitem_popover">
                        <popup_context.Provider value={setShow}>
                            <MemberInfo item={bookitem} target={target}/>
                        </popup_context.Provider>    
                        </div>
                    </div>
                )}
            </Overlay>
        
        </MediaQuery>
        <MediaQuery maxWidth={666}>
            <ListGroup>
                { manage_table.table[props.room_name].map((item)=>{
                    return (
                        <div key={item.time}>
                        <TimeUnit time={Clock(item.time)} />
                        <BookButton item={item}/>
                        </div>
                    );
                })
                }
                <TimeUnit time={Clock(manage_table.close_time)} />
            </ListGroup>
        </MediaQuery>
        
        </div>
        
    );
    function getTarget(target) {
        setTarget(target);
    }
    function getItem(item) {    
        setBookitem(item);
    }                
}

function BookButton(props) {
    const [setMsg, setAlert] = useContext(alert_context);
    const [manage_table,setManage_table] = useContext(table_context);
    const [admin, setAdmin] = useContext(info_context);
    const [editing_book, setEditing_book] = useContext(editing_context);
    const startree = cookies.get('startree');
    const [label, setLabel] = useState(' ');
    const [daily_booklist, setDaily_booklist] = useContext(daily_book_context);
    const [run_getItem, setRun_getItem] = useState(false);
    const target = useRef(null);
    const [badge, setBadge] = useState('');
    const [daycalc, setDaycalc] = useContext(daycalc_context);
    useEffect(()=>{
        if (run_getItem) {
            if(props.item.id !== '_empty' && props.item.id !== '') {
                props.getItem(props.item);
            }
        }
    },[run_getItem]);
    useEffect(()=>{
        setBadge('');
    },[daily_booklist]);
    useEffect(()=>{
        setLabel(' ');
        let book = daily_booklist.books.find((b)=>{
            return b.book_id === props.item.book_id
        });
        if(book !== undefined) {

            if (badge === '' && props.item.time === book.start_time) {
                if (book.user.phone === book.phone) {
                    if (book.user.postpayment) {
                        setBadge((<Badge bg='dark' style={{float: 'right', textAlign: 'end', margin: '0', padding: '2px'}}>장부</Badge>));
                    }
                    
                
                    if (book.pay_stat(startree, daycalc.room_state) === '결제완료') {
                        setBadge((<Badge bg='dark' style={{float: 'right', textAlign: 'end', margin: '0', padding: '2px'}}>결제완료</Badge>));
                    }
                    

                    if (book.user.prepayment > 0 && book.apply_prepay) {
                        setBadge((<Badge bg='dark' style={{float: 'right', textAlign: 'end', margin: '0', padding: '2px'}}>선불</Badge>));
                    }
                }
            }
            else if((badge !== '' && (book.user.prepayment === 0 || book.apply_prepay === false) && !book.user.postpayment && book.pay_stat(startree, daycalc.room_state) !== '결제완료') || props.item.time !== book.start_time) {
                setBadge('');
            }
        }

    },[props.item, daily_booklist]);
    
    if (props.item.id === '_empty') {
        
        return (
            <ListGroup.Item className="bookbutton_bigscreen" action onClick={()=>{bookHandler()}}>
                &nbsp;
            </ListGroup.Item>
        );
    }

    else if (props.item.id === admin.id && props.item.book_id === '') {
        return (
            <ListGroup.Item className="bookbutton_bigscreen" active action onClick={()=>{cancelHandler()}}>
                선택중
            </ListGroup.Item>
        );
    }
    else {
        
        // 자기 앞 타임 검사해서 자기랑 같은 id라면 아무것도 안띄우기. 그러면 최초컬럼에만 유저이름뜬다.
        if (label != props.item.nick_name + '님') {
            
            if (props.item.time === startree.open_time) {
                
                setLabel(props.item.nick_name + '님'); 
                
            }
            
            else if (manage_table.table[props.item.room_name][props.item.time-startree.open_time-1]['nick_name'] !== props.item.nick_name || manage_table.table[props.item.room_name][props.item.time-startree.open_time-1]['id'] !== props.item.id ) {
                setLabel(props.item.nick_name + '님');
                
            }
        }
        else if (props.item.time !== startree.open_time) {
            if (manage_table.table[props.item.room_name][props.item.time-startree.open_time-1]['id'] === props.item.id && manage_table.table[props.item.room_name][props.item.time-startree.open_time-1]['nick_name'] === props.item.nick_name ) {
                setLabel(' ');
               
            }
        }
        return (
            <div>      
            <ListGroup.Item ref={target} style={{ backgroundColor: props.item.color, padding: '0px'}} 
                onMouseEnter={()=>{
                    setTimeout(()=>{
                        props.getTarget(target);
                        setRun_getItem(true);
                        props.setShow(true);
                    },500); 
                }} 
                onMouseLeave={()=>{
                    setTimeout(()=>{
                        setRun_getItem(false);
                        props.setShow(false);
                    },500);
                }} 
                onClick={()=>{
                    let thisbook = daily_booklist.books.find((b)=>{
                        return b.book_id === props.item.book_id;
                    });
                    let idx = editing_book.books.findIndex((b)=>{
                        return b.book_id === thisbook.book_id;
                    });
                    let temp = new BookListManage(editing_book.books);
                    if (idx === -1) {   
                        temp.books.push(thisbook);
                        setEditing_book(temp);
                    }
                    else {
                        temp.books.splice(idx,1);
                        setEditing_book(temp);
                    }
                }}
                className="bookbutton_bigscreen" action >
                {label}{badge}
            </ListGroup.Item>
            
            </div>
        )
    }

    function bookHandler() {

        // 테이블 업데이트
        let temp = new ScheduleTable();
        temp.copyTable(manage_table);
        temp.table[props.item.room_name][props.item.time-startree.open_time]['id'] = admin.id;
        temp.table[props.item.room_name][props.item.time-startree.open_time]['nick_name'] = '관리자가등록';
        temp.table[props.item.room_name][props.item.time-startree.open_time]['color'] = '';
        temp.table[props.item.room_name][props.item.time-startree.open_time]['book_id'] = '';
        setManage_table(temp);
    
        // NowEditing 업데이트 필요
        let temp_edit = new BookListManage(editing_book.books);
        temp_edit.updateManage(temp.table[props.item.room_name][props.item.time-startree.open_time]); 
        temp_edit.sort();
        setEditing_book(new BookListManage(temp_edit.books));

    }
    
    function cancelHandler() {

        // 테이블 업데이트
        let temp = new ScheduleTable();
        temp.copyTable(manage_table);
        temp.table[props.item.room_name][props.item.time-startree.open_time]['id'] = '_empty';
        temp.table[props.item.room_name][props.item.time-startree.open_time]['nick_name'] = '';
        setManage_table(temp);

        // NowEditing 업데이트 필요
        let temp_edit = new BookListManage(editing_book.books);
        temp_edit.updateManage(temp.table[props.item.room_name][props.item.time-startree.open_time]); 
        temp_edit.sort();
        setEditing_book(new BookListManage(temp_edit.books));              
    }
}

function MemberInfo(props) {
    const [memberbook,setMemberbook] = useState(new BookManage());
    const [daily_booklist, setDaily_booklist] = useContext(daily_book_context);
    const startree = cookies.get('startree');
    const [cost,setCost] = useState(0);
    const setShow = useContext(popup_context);
    const [daycalc, setDaycalc] = useContext(daycalc_context);
    
    useEffect(()=>{
        if (memberbook !== undefined && props.item !== undefined){
                
            let tempbook = daily_booklist.books.find((book)=>{
                return book.book_id == props.item.book_id;
            });
            setMemberbook(tempbook);
            
        }
    },[daily_booklist, props.item]);
    if (memberbook === undefined) {
        return (
            <div></div>
        );
    }
    if (memberbook.user === undefined || props.item === undefined) {
        return (
            <div></div>
        );
    }
    if (memberbook.user.id === props.item.id) {
        
        return(
            <Popover id='memberinfo' 
                onMouseEnter={()=>{inpopup = true}} 
                onMouseLeave={()=>{
                    inpopup=false;
                    setShow(false);
            }}>
                <Popover.Header>
                    <h6>{props.item.nick_name}님</h6>
                    <h6>({memberbook.user.phone})</h6>
                </Popover.Header>
                <Popover.Body>
                    
                    <h6>장부총계:{memberbook.user.balance}</h6>
                    <h6>예약메모:{memberbook.description}</h6>
                    <h6>비고:{memberbook.user.description}</h6>
                </Popover.Body>
            </Popover>
            
        );
    }
    else {
        return (
            <div></div>
        );
    }
}

function NowEditing() {
    const ws = useContext(websocket_context);
    const [editing_book, setEditing_book] = useContext(editing_context);
    const [nick, setNick] = useState('');
    const [phone, setPhone] = useState('');
    const [members, setMembers] = useContext(member_context);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [daily_booklist, setDaily_booklist] = useContext(daily_book_context);
    const [sendable, setSendable] = useState(false);
    const startree = cookies.get('startree');
    const [setSms_nick, setSms_phone] = useContext(sms_context);
    const [del_alert, setDel_alert, book_to_del, setBook_to_del] = useContext(del_context);

    useEffect(()=>{
    
        if(members.length > 0 && phone === '') {
            setShow(true);    
        }
        else if(show) {
            setShow(false);
        }
    },[members]);

    useEffect(()=>{
        
        setMembers([]);
        setShow(false);  
    },[target, editing_book]);
    async function nickHandler(event) {
        let item = event.target.value;
    
        if (item.length > 10) {}
        else {
            setNick(item);
        }
        if (item.length >= 1) {
            
            let request = [{
                'todo' : 'search_user_by_nick',
                'data' : item
            }]
            await ws.current.send(JSON.stringify(request));
        }
        else {
            
            setMembers([]);
            setShow(false);
        }
        
    }

    function phoneHandler(event) {
        let item = event.target.value;
        const regExp = /[^0-9]/g; 
        if(item.length > 11) {}
        else if(item=='') setPhone(item);
        else if(regExp.test(item)) {} 
        else setPhone(item);
    }
    async function sendBook(book) {
        let request = [];
        // 예약자 이름끝에 '님' 넣은 경우 빼기

        //앞뒤에 같은 예약자의 기존예약이 존재하는지 체크해서 같은 예약이 되도록 처리
        let temp_daily = new BookListManage(daily_booklist.books);
        let prevbook = temp_daily.books.find((b)=>{
            return b.phone === book.phone && b.end_time === book.start_time && b.room_name === book.room_name && b.date === book.date;
        });
        let nextbook = temp_daily.books.find((b)=>{
            return b.phone === book.phone && b.start_time === book.end_time && b.room_name === book.room_name && b.date === book.date;
        });
        
        if (prevbook) {
            let bookdata = prevbook.getData();
            
            request.push({ 'todo' : 'cancel', 'book' : bookdata });
            prevbook.end_time = book.end_time;
            prevbook.description = prevbook.description + book.description;
            if (prevbook.paid_status !== '결제완료' || book.paid_status !== '결제완료') {
                prevbook.paid_status = '부분결제';
            }
            if (prevbook.paid_status === '미납' && book.paid_status === '미납') {
                prevbook.paid_status = '미납';
            }
            prevbook.pay_method = prevbook.pay_method + book.pay_method;
            book = prevbook;
        }
        if (nextbook) {
            let bookdata = nextbook.getData();
            request.push({ 'todo' : 'cancel', 'book' : bookdata });
            nextbook.start_time = book.start_time;
            nextbook.description = nextbook.description + prevbook.description;
            if (nextbook.paid_status !== '결제완료' || book.paid_status !== '결제완료') {
                nextbook.paid_status = '부분결제';
            }
            if (nextbook.paid_status === '미납' && book.paid_status === '미납') {
                nextbook.paid_status = '미납';
            }
            nextbook.pay_method = nextbook.pay_method + book.pay_method;
            book = nextbook;
        }
        if (book.book_id === '') {
            book.book_id = undefined;
        }
        
        let bookdata = book.getData();
        
        if (nick.length > 1 && nick.charAt(nick.length-1) === '님') {
            bookdata['nick_name'] = nick.slice(0,-1);
        }
        else {
            bookdata['nick_name'] = nick;
        }
        
        bookdata['phone'] = phone;
        
        request.push({'todo' : 'make', 'book' : bookdata });
        
        await ws.current.send(JSON.stringify(request));
        setSms_nick(bookdata['nick_name']);
        setSms_phone(bookdata['phone']);
    }

    async function cancelBook(book) {
        let bookdata = book.getData();
        setBook_to_del(bookdata);
        setDel_alert(true);
        // let request = [{'todo': 'cancel', 'book': bookdata}];
        // await ws.current.send(JSON.stringify(request));
        setSms_nick(bookdata['nick_name']);
        setSms_phone(bookdata['phone']);
    }
    
    return (
        <div className="bookList-manage">
            <Container>
                <ListGroup>
                    {editing_book.books.map((book)=>{
                        let item =book.date.slice(5) + '  '+ Clock(book.start_time) + ' - ' + Clock(book.end_time) + '시 ' + book.room_name + '룸';
                        
                        
                        if (book.book_id !== undefined && book.book_id !== "") {
                            let stimelist = [];
                            for (let i=startree.open_time; i<book.end_time;i++){
                                stimelist.push(i);
                            }
                            let etimelist = [];
                            for (let i= book.start_time+1; i <= startree.close_time; i++) {
                                etimelist.push(i);
                            }
                            return (
                                <ListGroup.Item key={item}>
                                    <Row>
                                        <Col xs={1}>
                                            <Button size='sm' variant="danger" onClick={()=>{
                                                cancelBook(book);
                                                // 편집창에서 지우기
                                                let temp_edit = new BookListManage(editing_book.books);
                                                let idx = temp_edit.books.findIndex((b)=>{
                                                    return b.book_id === book.book_id;
                                                });
                                                temp_edit.books.splice(idx,1);
                                                setEditing_book(temp_edit);
                                                
                                            }}>예약취소</Button>{' '}
                                        </Col>
                                        <Col>
                                            <ChangeDate book={book} />
                                        </Col>
                                        <Col>
                                            <Form.Select defaultValue={book.start_time} size="sm" onChange={(event)=>{
                                                let newtime = event.target.value;
                                                let temp = new BookListManage(editing_book.books);
                                                let idx = temp.books.findIndex((b)=>{
                                                    return b.book_id === book.book_id;
                                                });
                                                temp.books[idx].start_time = Number(newtime);
                                                setEditing_book(temp);
                                            }} >                                                
                                                {stimelist.map((stime)=>{
                                                    return (
                                                        <option key={'s'+stime} value={stime} >{Clock(stime)}</option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select defaultValue={book.end_time} size="sm" onChange={(event)=>{
                                                let newtime = event.target.value;
                                                let temp = new BookListManage(editing_book.books);
                                                let idx = temp.books.findIndex((b)=>{
                                                    return b.book_id === book.book_id;
                                                });
                                                temp.books[idx].end_time = Number(newtime);
                                                setEditing_book(temp);
                                        
                                            }}>
                                                
                                                {etimelist.map((etime)=>{
                                                    return(
                                                        <option key={'e'+etime} value={etime}>{Clock(etime)}</option>
                                                    );
                                                })}
                                                
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select defaultValue={book.room_name} size='sm' onChange={(event)=>{
                                                let newroom = event.target.value;
                                                let temp = new BookListManage(editing_book.books);
                                                let idx = temp.books.findIndex((b)=>{
                                                    return b.book_id === book.book_id;
                                                });
                                                temp.books[idx].room_name = newroom;
                                                setEditing_book(temp);
                                            }}>
                                                {startree.rooms.map((room)=>{
                                                    return(
                                                        <option key={room.name} value={room.name} >{room.name+'룸'}</option>
                                                    );
                                                })}
                                                
                                            </Form.Select>
                                        </Col>
                                        <Col className="editingbookinfo" xs={12}>
                                            {book.nick_name+'님 '+book.phone}
                                        </Col>
                                        <Col>
                                            <Button onClick={async ()=>{
                                                // book_id만 제대로면 삭제되도록 백엔드 수정했음.
                                                let request = [];
                                                
                                                let del_bookdata = book.getData();
                                                request.push({'todo':'cancel','book': del_bookdata});
                                                
                                                let make_bookdata = book.getData();
                                                make_bookdata['book_id'] = undefined;
                                                make_bookdata['color'] = '';
                                                make_bookdata['created_at'] = new Date().getDateString();
                                                request.push({'todo': 'make', 'book': make_bookdata});

                                                await ws.current.send(JSON.stringify(request)); 

                                                let temp_edit = new BookListManage(editing_book.books);
                                                let idx = temp_edit.books.findIndex((b)=>{
                                                    return b.book_id === book.book_id;
                                                });
                                                temp_edit.books.splice(idx,1);
                                                setEditing_book(temp_edit);
                                                setSms_nick(book.nick_name);
                                                setSms_phone(book.phone);
                                                
                                            }}>변경</Button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            );
                        }
                        else {
                            return (
                                
                                <ListGroup.Item key={item + 'new'} >
                                    <Row>
                                        <Col>
                                        </Col>
                                        <Col xs={12} className='editingbookinfo'>
                                            <h5>{item}</h5>
                                        </Col>
                                        <Col>
                                            <Form.Control ref={target} type='text' value={nick} onChange={(e)=>{nickHandler(e)}} placeholder='예약명' />
                                        </Col>
                                        <Col>
                                            <Form.Control type='text' value={phone} onChange={(e)=>{phoneHandler(e)}} placeholder='폰번호' />
                                        </Col>
                                        <Col>
                                            <Button onClick={()=>{
                                                sendBook(book)
                                                // 편집창에서 예약 지워야함.
                                                let temp_edit = new BookListManage(editing_book.books);
                                                let idx = temp_edit.books.findIndex((b)=>{
                                                    return b.date === book.date && b.start_time === book.start_time && b.room_name === book.room_name;
                                                });
                                                temp_edit.books.splice(idx,1);
                                                setEditing_book(temp_edit);
                                            }}>전송</Button>
                                        </Col>
                                    </Row>
                                    
                                <Overlay target={target.current} show={show} placement='right'>
                                    {({ placement, arrowProps, show: _show, popper, ...props }) => (
                                        <div {...props}>
                                            <Popover id='memberlist'>
                                                <Popover.Body>
                                                    <ListGroup>
                                                        
                                                        {members.map((member)=>{
                                                            
                                                            
                                                            return(
                                                                <ListGroup.Item key={member.phone} action onClick={()=>selectMember(member,book)} className="memberitem" >
                                                                    {member.nick_name}&nbsp;&nbsp;
                                                                    {member.phone.substr(0,3)+'-'+member.phone.substr(3,4)+'-'+member.phone.substr(7)}
                                                                </ListGroup.Item>
                                                            );
                                                            
                                                        })}
                                                    </ListGroup>
                                                </Popover.Body>
                                            </Popover>
                                        </div>
                                    )}
                                </Overlay>
                                </ListGroup.Item>
                                
                               
                            );
                        }
                    })}
                </ListGroup>
            </Container>
        </div>
    );

    function selectMember(member, book) {
        let temp = new BookListManage(editing_book.books);
        let idx = temp.books.findIndex((b)=>{
            return b.date === book.date && b.start_time === book.start_time && b.room_name === book.room_name;
        });
        temp.books[idx].id = member.id;
        temp.books[idx].phone = member.phone;
        temp.books[idx].nick_name = member.nick_name;
        temp.books[idx].user.balance = member.balance;
        temp.books[idx].user.nick_name = member.nick_name;
        temp.books[idx].user.custom = member.custom;
        temp.books[idx].user.custom_price = member.custom_price;
        temp.books[idx].user.postpayment = member.postpayment;
        temp.books[idx].user.prepayment = member.prepayment;
        temp.books[idx].user.count_max = member.count_max;
        temp.books[idx].user.count_now = member.count_now;
        setEditing_book(temp);
        setPhone(member.phone);
        setNick(member.nick_name);
        
        setMembers([]);
        setShow(false);
    }

    function ChangeDate(props) {

        let [date, setDate] = useContext(date_context);
        const CInput = React.forwardRef(({ value, onClick }, ref) => (
            <Button size='sm' onClick={onClick} ref={ref}>
              {value}
            </Button>
        ));
        return (
            
            <DatePicker 
            popperPlacement = 'top-end'
            dateFormat='MM/dd(eee)'
            locale={ko}
            selected={new Date(props.book.date)}
            disabledKeyboardNavigation 
            customInput={<CInput />}
            onChange={(d) => {
                if (d != new Date(props.book.date)) {
                    let temp = new BookListManage(editing_book.books);
                    let idx = temp.books.findIndex((b)=>{
                        return props.book.book_id === b.book_id; 
                    });
                    temp.books[idx].date = d.getDateString();
                    setEditing_book(temp);
                }
            }}/>
            
        );
    }
}

function SendSMS(props) {
    const ws = useContext(websocket_context);
    const [nick, setNick] = useState(props.nick);
    const [phone, setPhone] = useState(props.phone);
    useEffect(()=>{
        setNick(props.nick);
        setPhone(props.phone);
    },[props.nick, props.phone]); 
    return (
        <Form className="d-flex">
            <Form.Group>
                <Button size='sm' variant="danger" onClick={()=>undo_delete(ws,props.deletedbooks, props.setDeletedbooks)}>UNDO</Button>
            </Form.Group>
            <Form.Group>
                
                <Form.Control size='sm' value={nick} placeholder='닉네임' onChange={(event)=>{
                    setNick(event.target.value);
                }} />
            </Form.Group>
            <Form.Group>
                <Form.Control size='sm' value={phone} placeholder='폰번호' onChange={(event)=>{
                    setPhone(event.target.value);
                }} />
            </Form.Group>
            <Form.Group>
            {phone !== '' && <Button size='sm' style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}} onClick={async ()=>{
                let request = [{'todo': 'send_sms_book', 'data': phone}];
                await ws.current.send(JSON.stringify(request));
                props.setNick('');
                props.setPhone('');
                setNick('');
                setPhone('');
            }}>문자전송</Button>}
            {phone === '' && <Button size='sm' style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}} disabled>문자전송</Button> }
            </Form.Group>
        </Form>
    );
}

function SelectPayDate(props) {
    const ws = useContext(websocket_context);
    const [daily_booklist, setDaily_booklist] = useContext(daily_book_context);
    
    let date = new Date(props.date);
    const CInput = React.forwardRef(({ value, onClick }, ref) => (
        <Button size='sm' onClick={onClick} ref={ref} style={{fontSize: '1.3vh', paddingTop: '0', paddingBottom: '0'}}>
          {value}
        </Button>
    ));
    
    return (
        <DatePicker 
        dateFormat='MM/dd'
        locale={ko}
        selected={date}
        disabledKeyboardNavigation 
        customInput={<CInput />}
        onChange={async (d) => {
        if (d != date) {
            let tempbook = daily_booklist.books.find((book)=>{
                return book.book_id === props.book_id;
            });

            tempbook.pay_method[props.index][2] = d.getDateString();
            
            let bookdata = tempbook.getData();
            let request = [{'todo': 'mod_pay_method', 'data': bookdata}];
            await ws.current.send(JSON.stringify(request));
            let temp = new BookListManage(daily_booklist.books);
            let idx = temp.books.findIndex((b)=>{
                return b.book_id === tempbook.book_id;
            });
            temp.books[idx].pay_method = tempbook.pay_method;
            
            setDaily_booklist(temp);
           
        }}}/>
    );
}


export default Manage;