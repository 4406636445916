// 장부일람

import { Container, Navbar } from "react-bootstrap";
import Title from "../brand";


function Profits() {
    // 토큰 검사해서 토큰 없으면 /manage/login으로 튕겨야함
    return (
        <div>
        <Navbar bg='dark' variant='dark' expand='lg'>
            <Container>
                <Title />
            </Container>
        </Navbar>
            <p>장부열람페이지</p>
            <p>아이템 클릭하면 그 날짜의 상세장부열람기능</p>
        </div>
    );

}

export default Profits;