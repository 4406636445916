import { Button, Container, Navbar } from "react-bootstrap";
import Title from "../brand";

// 예약내역 확정
function Confirm() {
    // 토큰 검사해서 토큰 없으면 튕겨야함
    // Books 객체를 받아서 예약내역을 출력하고 예약명을 편집하거나 예약을 캔슬한다.
    // 전일과 당일 예약캔슬 의 처리는 book과 같다. <- 같은 함수 쓰면 될듯?

    return (
        <div>
        <Navbar bg='dark' variant='dark' expand='lg'>
            <Container>
                <Title to='/book' />
            </Container>
        </Navbar>
            <p>예약확정</p>
            <p>캔슬버튼//예약내역출력//예약명편집창</p>
            
        <Button>예약확정</Button>
                <p>백엔드로 예약내역 전송후 성공하면 성공 메시지 출력</p>
                <p>백엔드에서 에러나오면 에러 메시지 출력</p>

        </div>
    );
}

export default Confirm;
