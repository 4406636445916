import { Container, Navbar } from "react-bootstrap";
import Title from "../brand";

//회원탈퇴 페이지
function Resign() {
    // 토큰 검사해서 토큰 없으면 튕겨야함
    return (
        <div>
        <Navbar bg='dark' variant='dark' expand='lg'>
            <Container>
                <Title />
            </Container>
        </Navbar>
            <p>회원탈퇴페이지</p>
            <p>모든 정보 삭제 경고 출력</p>
            <p>예약내역이 있는지 체크해서 예약내역 있을시 예약취소해달라고 안내, 메인으로 이동</p>
            <p>암호입력받고</p>
            <p>확인버튼 누르면 백엔드로 암호 넘겨주면서 삭제요청</p>
            <p> 정상적으로 처리되면 쿠키에서 토큰 삭제하고 회원탈퇴페이지 account/bye 로 이동</p>
            <p> 비정상처리되면 오류메시지 출력</p>
        </div>
    );
}

export default Resign;