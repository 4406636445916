import axios from "axios";
import { useState } from "react";
import { useAsync } from "react-async";
import { Table } from "react-bootstrap";
import { cookies } from "../App";
import { NowLoading } from "../brand";
import { backend_address, Clock } from "../data";
import Head from "../root/head";


async function loadTodayList({setList}) {
    let res = await axios.post(backend_address+'/todaylist', '', {
        headers: {'Authorization': cookies.get('access_token')}
    })
    setList(res.data);
    return res.data;
}

function TodayBook() {
    const [list, setList] = useState();
    const {data: clist, error, isLoading } = useAsync({promiseFn: loadTodayList, setList});

    if (isLoading) {
        return (
            <NowLoading />
        );
    }
    if(error) {
        console.log(error);
        return (
            <div></div>
        );
    }
    if(clist !== undefined) {
        
        return (
            <div>
                <Head />
                <h4>최근예약변동</h4>
                <Table>
                    <thead style={{textAlign:'center', fontWeight:'bold', fontSize: '1.4vh', whiteSpace: 'nowrap'}}>
                        <tr>
                            <th style={{width: '9vw'}}>일시</th>
                            <th style={{width: '6vw'}}>폰번호</th>
                            <th style={{width: '6vw'}}>닉네임</th>
                            <th style={{width: '6vw'}}>예약일</th>
                            <th style={{width: '6vw'}}>예약시간</th>
                            <th style={{width: '3vw'}}>예약룸</th>
                        </tr>
                    </thead>
                    <tbody style={{textAlign:'center', fontSize: '1.4vh', whiteSpace: 'nowrap'}}>
                        {list.map((book)=>{     
                            let _color = 'white';         
                            if (book.kind === 'cancel') {
                                _color = 'red';
                            }
                            return (
                                <tr key={book.book_id+'clist'} style={{backgroundColor: _color}} >
                                    <BookLog book={book} setList={setList}/>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                
            </div>
        );
    }
    else {
        return (
            <div>
                <Head/>
            </div>
        );
    }
}

function BookLog (props) {
    return(
        <>
        <td>{props.book.dt}</td>
        <td>{props.book.phone}</td>
        <td>{props.book.nick_name}</td>
        <td>{props.book.date}</td>
        <td>{Clock(props.book.start_time)}-{Clock(props.book.end_time)}</td>
        <td>{props.book.room_name}</td>
        </>
    );
}

export default TodayBook;
