import { useEffect } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import Title from "../brand";
import { frontend_address } from "../data";

// 예약내역 확정
function RedirectPage() {
    // 서버와의 연결이 끊겼다고 안내한 후 홈으로 재접속 시킴.
    useEffect(()=>{
        let timer = setTimeout(()=>{
            window.location.href = frontend_address;
        },5000);
        return ()=>{ clearTimeout(timer) }
    },[]);
    return (
        <div>
            <br/>
            <p>서버와의 연결이 끊어졌습니다.</p>
            <p>예약/변경/취소를 하시려던 중이었다면 다시 신청해 주세요.</p>
            <Button onClick={()=>{window.location.href = frontend_address}}>홈으로</Button>
        </div>
    );
}

export default RedirectPage;
