
import { useState, useRef, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import Title from "../brand";


function AdminLogIn() {
   
    return (        
        <div>
        <Navbar bg='dark' variant='dark' expand='lg'>
            <Container>
                <Title />
            </Container>
        </Navbar>
            <p>관리자로그인페이지</p>
            <p>아이디 입력</p>
            <p>비번 입력</p>
            <p>로그인버튼</p>
        </div> 
    );

}

export default AdminLogIn;