import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Root from './root/root';
import SignUp from './root/sign-up';

import Help from './root/help';
import Activation from './root/activation';
import BookHistory from './book/history';
import Confirm from './book/confirm';
import Account from './account/account';
import Resign from './account/resign';
import Bye from './account/bye';
import Manage from './manage/manage';
import AdminLogIn from './manage/login';
import Setting from './manage/setting';
import Profits from './manage/profits';
import Users from './manage/users';
import NonMembers from './manage/nonmembers';
import ManageCancel from './manage/cancel';
import React, { lazy, Suspense, useContext, useState } from 'react';
import { Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { NowLoading } from './brand';
import { backend_address, book_phone, StarTree, User } from './data';
import axios from 'axios';
import { useAsync } from 'react-async';
import TodayBook from './manage/todaybook';
import BookInfo from './manage/bookinfo';
import RedirectPage from './book/redirect';

let Book = lazy(() => {return import('./book/Book.js')});

const cookies = new Cookies();

async function initStarTree() {
  if (cookies.get('startree')) {
    cookies.remove('startree', {path: '/'});
  }
  let st = new StarTree();
  let res = await axios.get(backend_address + '/info');
  st.open_time = res.data.open_time;
  st.close_time = res.data.close_time;
  st.rooms = res.data.rooms;
  st.contact = res.data.service_phone;
  st.sp_price = res.data.sp_price;
  st.noti = res.data.noti;
  st.sp_price = res.data.sp_price;
  st.send_message = res.data.send_message;
  cookies.set('startree', st, {path: '/'});
  return st;
}

// context
let alert_context = React.createContext();
let info_context = React.createContext();
let date_context = React.createContext();
let log_context = React.createContext();
function App() {

  let [date, setDate] = useState(new Date());
  let [msg, setMsg] = useState('');
  let [alert, setAlert] = useState(false);
  let [user, setUser] = useState(new User());
  let [is_logged, setIs_logged] = useState(false);
  
  const { data: st, error, isLoading, reload} = useAsync({ promiseFn: initStarTree});
  if (isLoading) {
    return (
      <NowLoading/>
    );
  }
  if (error) {
    console.log(error);
    return (
      <div>
        <h4>예약사이트 정비작업중입니다</h4>
        <p>전화나 문자로 예약이 가능합니다.</p>
        <p>예약연락처: {book_phone}</p>
      </div>
    );
  }
  
  if (cookies.get('startree')) { 
    if (true) {
      // 경고창 스테이트 메시지랑 on/off
      
      return (
        <div className="App">
          
          {/* 합주실 처음접속하는 페이지, 로그인기능 */}
          <Route exact path='/'>
          <alert_context.Provider value={[setMsg,setAlert]}>
          <info_context.Provider value={[user, setUser]} >
          <log_context.Provider value={[is_logged, setIs_logged]}>
          <date_context.Provider value={[date,setDate]}>
            <Root />
          </date_context.Provider>
          </log_context.Provider>
            
          </info_context.Provider>
          </alert_context.Provider>
          
          </Route>

          {/* 회원가입 */}
          <Route exact path='/sign-up'>
          
          <alert_context.Provider value={[setMsg,setAlert]}>
          <info_context.Provider value={[user, setUser]} >
          <log_context.Provider value={[is_logged, setIs_logged]}>
            <SignUp />
          </log_context.Provider>
          </info_context.Provider>
          </alert_context.Provider>
          
          </Route>

          {/* ID/패스워드 찾기 */}
          <Route exact path='/help'>
            <alert_context.Provider value={[setMsg,setAlert]}>
              <Help /> 
            </alert_context.Provider>  
          </Route>
          
          {/* 휴면계정활성화 */}
          <Route exact path='/activation'>
            <alert_context.Provider value={[setMsg, setAlert]}>
              <Activation /> 
            </alert_context.Provider>
          </Route>

          {/* 예약 페이지 */}
          <Route exact path='/book'>
          <Suspense fallback = { 
            <NowLoading />
          }>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <info_context.Provider value={[user, setUser]} >
          <date_context.Provider value={[date,setDate]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
            <Book /> 
          </log_context.Provider>
          </date_context.Provider>
          </info_context.Provider>
          </alert_context.Provider>
          </Suspense>
          </Route>
          
          {/* 리다이렉트 페이지 */}
          <Route exact path='/redirect'>
            <RedirectPage />
          </Route>

          {/* 사용자 예약내역 */}
          <Route exact path='/book/history'>
            <BookHistory /> 
          </Route>

          {/* 예약 확정*/}
          <Route exact path='/book/confirm'>
            <Confirm />
          </Route>
          
          {/* 사용자 계정관리 */}
          <Route exact path='/account'>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <info_context.Provider value={[user, setUser]} >
          <date_context.Provider value={[date,setDate]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
            <Account /> 
          </log_context.Provider>
          </date_context.Provider>
          </info_context.Provider>
          </alert_context.Provider>
          </Route>

          {/* 회원탈퇴 */}
          <Route exact path='/account/resign'>
            <Resign /> 
          </Route>
          
          {/* 탈퇴완료 */}
          <Route exact path='/account/bye'>
            <Bye /> 
          </Route>

          {/* 관리 메인페이지 */}
          <Route exact path='/manage'>
          <Suspense fallback = { 
            <NowLoading />
          }>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <info_context.Provider value={[user, setUser]}>
          <date_context.Provider value={[date,setDate]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
            <Manage />
          </log_context.Provider>
          </date_context.Provider>
          </info_context.Provider>
          </alert_context.Provider>
          </Suspense>
          </Route>

          {/* 관리자 로그인 */}
          <Route exact path='/Adminlogin'>
            <AdminLogIn />
          </Route>

          {/* 장부일람 */}
          <Route exact path='/mng-profit'>
            <Profits />
          </Route>
          
          {/*취소관리*/}
          <Route exact path='/mng-cancel'>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <info_context.Provider value={[user, setUser]}>
          <date_context.Provider value={[date,setDate]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
            <ManageCancel />
          </log_context.Provider>
          </date_context.Provider>
          </info_context.Provider>
          </alert_context.Provider>
          </Route>

          {/*당일예약변동내역*/}
          <Route exact path='/todaybook'>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <info_context.Provider value={[user, setUser]}>
          <date_context.Provider value={[date,setDate]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
            <TodayBook />
          </log_context.Provider>
          </date_context.Provider>
          </info_context.Provider>
          </alert_context.Provider>
          </Route>

          {/*예약찾기 */}
          <Route exact path='/bookinfo'>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <info_context.Provider value={[user, setUser]}>
          <date_context.Provider value={[date,setDate]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
            <BookInfo />
          </log_context.Provider>
          </date_context.Provider>
          </info_context.Provider>
          </alert_context.Provider>
          </Route>

          {/* 유저일람 */}
          <Route exact path='/mng-users'>
          <Suspense fallback = { 
            <NowLoading />
          }>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <info_context.Provider value={[user, setUser]}>
          <date_context.Provider value={[date,setDate]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
            <Users />
          </log_context.Provider>
          </date_context.Provider>
          </info_context.Provider>
          </alert_context.Provider>
          </Suspense>
          </Route>

          {/* 비회원단골관리 */}
          <Route exact path='/mng-nonmembers'>
          <Suspense fallback = { 
            <NowLoading />
          }>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
          <date_context.Provider value={[date,setDate]}>
          <info_context.Provider value={[user, setUser]}>
            <NonMembers />
          </info_context.Provider>
          </date_context.Provider>
          </log_context.Provider>
          </alert_context.Provider>
          </Suspense>
          </Route>


          {/* 세팅 */}
          <Route exact path='/mng-setting'>
          <Suspense fallback = { 
            <NowLoading />
          }>
          <alert_context.Provider value={[setMsg, setAlert]}>
          <log_context.Provider value={[is_logged, setIs_logged]}>
          <date_context.Provider value={[date,setDate]}>
          <info_context.Provider value={[user, setUser]}>
            <Setting /> 
          </info_context.Provider>
          </date_context.Provider>
          </log_context.Provider>
          </alert_context.Provider>
          </Suspense>
          </Route>
          
          {/* alert 컴포넌트 */}
          <alert_context.Provider value={[msg, alert, setAlert]}>
            <AlertToast />
          </alert_context.Provider>
          {/* 토스트 컴포넌트 */}
        </div>
      );
    }
  }
}

function AlertToast() {
  let [msg, alert, setAlert] = useContext(alert_context);

  return (
    
    <ToastContainer className='p-3' position='middle-center' style={{zIndex:2}}> 
    <Toast position='middle-center' show={alert} onClose={()=>{setAlert(false)}}>
      <Toast.Header>
        {/* <img
          src="holder.js/20x20?text=%20"
          className="rounded me-2"
          alt=""
        /> */}
        <strong className="me-auto">알립니다!</strong>
      </Toast.Header>
      <Toast.Body>{msg}</Toast.Body>
    </Toast>
    </ToastContainer>
    
  );
}

export default App;
export { alert_context, info_context, date_context, log_context, cookies, initStarTree };