
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Navbar, Row, Button, ListGroup, Form, Alert, Toast, ToastContainer } from "react-bootstrap";
import { alert_context, cookies, date_context, info_context, log_context } from "../App";
import Title from "../brand";
import { backend_address, frontend_address, User } from "../data";
import Head from "../root/head";

//계정관리
function Account() {
    const [user, setUser] = useContext(info_context);
    const [setMsg, setAlert] = useContext(alert_context);
    const [nickedit, setNickedit] = useState(false);
    const [phoneedit, setPhoneedit] = useState(false);
    const [passedit, setPassedit] = useState(false);
    const [nick, setNick] = useState(user.nick_name);
    const [phone, setPhone] = useState(user.phone);
    const [password, setPassword] = useState('');
    const [newpass, setNewpass] = useState('');
    const [auth, setAuth] = useState('');
    const [is_send_phone, setIs_send_phone] = useState(false);
    const [toast, setToast] = useState(false);
    const [date, setDate] = useContext(date_context);
    const [is_logged, setIs_logged] = useContext(log_context);
    const [pass_ok, setPass_ok] = useState('');
    const [confirmpass, setConfirmpass] = useState('');
    const [same_password, setSame_password] = useState('');
    useEffect(()=>{
        setNick(user.nick_name);
        setPhone(user.phone);
        
    },[user]);

    if (user) {
        return (
            <div>
                <Head page='account' is_admin={false} />
                <div style={{ textAlign:'start', width: '340px', height: '300px', margin: 'auto', display: 'flex', alignItems: 'center'}}>
                <Container>
                    <ListGroup>
                        <ListGroup.Item>
                            <Row>
                                <Col style={{whiteSpace: 'nowrap', display: 'inline-block'}} xs={3}>
                                    <span style={{fontWeight: 'bold'}}>아이디</span>
                                </Col>
                                <Col>
                                    {user.user_id}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                
                        
                        <ListGroup.Item>
                            <Row>
                                <Col style={{whiteSpace: 'nowrap', display: 'inline-block'}} xs={3}>
                                    <span style={{fontWeight: 'bold'}}>전화번호</span>
                                </Col>
                                <Col>
                                    { !phoneedit && user.phone }
                                    { phoneedit && <Form>
                                        <Form.Control type='text' size='sm' value={phone} onChange={phoneHandler} />
                                        <Button size='sm' onClick={async ()=> await sendPhone(phone)}>전송</Button>
                                        <Form.Control type='text' value={auth} onChange={authHandler} autoComplete="one-time-code" placeholder="인증번호" />
                                        <Button  size='sm' onClick={async ()=> await sendAuth(auth)}>인증</Button>
                                    </Form>
                                    }

                                    
                                </Col>
                                <Col xs={4} style={{textAlign:'end'}}>
                                    <Button size='sm' onClick={()=>{
                                        let _edit = phoneedit;
                                        setPhone(user.phone);
                                        setPhoneedit(!_edit)}} >
                                        {!phoneedit && <span>변경</span>}
                                        {phoneedit && <span>취소</span>}
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col xs={3}>
                                    <span style={{fontWeight: 'bold'}}>닉네임</span>
                                </Col>
                                <Col>
                                    {!nickedit && user.nick_name}
                                    {nickedit && <Form>
                                        <Form.Control size='sm' type='text' value={nick} onChange={nickHandler} />
                                        <Button size='sm' onClick={async () => await sendNick()}>전송</Button>
                                    </Form>}
                                </Col>
                                <Col xs={4} style={{textAlign:'end'}}>
                                    <Button size='sm' onClick={()=>{
                                        let _nickedit = !nickedit;
                                        setNick(user.nick_name);
                                        setNickedit(_nickedit);
                                        }}>
                                        {!nickedit && <span>변경</span>}
                                        {nickedit && <span>취소</span>}
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col>
                                    {passedit && <Form>
                                        <Form.Control type='password' value={password} onChange={passHandler} placeholder='기존암호' />
                                        <Form.Control type='password' value={newpass} onChange={newpassHandler} placeholder='새암호' />
                                        <Form.Control type='password' value={confirmpass} onChange={cpHandler} placeholder='암호확인' />

                                        <Button style={{textAlign: 'center' }} size='sm' onClick={async ()=> await updatepass(newpass)}>변경</Button>
                                    </Form>}
                                </Col>
                                <Col xs={4} style={{textAlign:'end'}}>
                                    <Button size='sm' onClick={()=>{
                                        let _passedit = !passedit
                                        setPassword('');
                                        setNewpass('');
                                        setPass_ok('');
                                        setSame_password('');
                                        setConfirmpass('');
                                        setPassedit(_passedit);
                                    }}>
                                        {!passedit && <span style={{whiteSpace: 'nowrap', display: 'inline-block'}}>암호변경</span>}
                                        {passedit && <span>취소</span>}
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col>
                                    <Button style={{textAlign:'end'}} size='sm' variant='danger' onClick={()=>setToast(true)} >
                                        회원탈퇴
                                    </Button> 
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                </Container>
                </div>
                
                <ToastContainer className="p-3" position='middle-center'>
                    <Toast show={toast}>
                        <Toast.Header>
                            <strong>회원탈퇴 버튼을 누르셨습니다.</strong>
                        </Toast.Header>
                        <Toast.Body>
                            <p>탈퇴하시게 되면 계정복구가 불가능합니다. 정말 탈퇴하시겠습니까?</p>
                            <Row>
                                <Col>
                                    <Button variant='danger' onClick={async ()=> await resign(user)} >확인</Button>
                                </Col>
                                <Col>
                                    <Button onClick={()=>setToast(false)}>취소</Button>
                                </Col>
                            </Row>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        );
    }
    else {
        return (
            <div></div>
        );
    }
    function nickHandler(event) {
        let item = event.target.value;
        if(item.search(/\s/) !== -1) {}
        else if(item.search(/_/) !== -1) {}
        else if (item.length > 10) {}
        else setNick(item);
    }

    function authHandler(event) {
        let item = event.target.value;
        setAuth(item);
    }

    async function sendNick() {
        if (nick.length < 1) {
            return;    
        }
        else {
            await axios.post(backend_address + '/change-nick', {'nickname': nick}, {
                headers : { 'Authorization': cookies.get('access_token') }
            }).then(res => {nickChanged(res)})
        }
    }

    function nickChanged(res) {
        let _user = new User();
        _user.setMember(user);
        _user.nick_name = nick;
        setUser(_user);
        setNickedit(false);
    }

    async function sendPhone(phone) {
        var patternPhone = /01[016789][^0][0-9]{2,3}[0-9]{3,4}/;
        if (!patternPhone.test(phone)) {
            setMsg('전화번호를 확인해주세요');
            setAlert(true);
        }
        else {
            setIs_send_phone(true);
            await axios.post(backend_address + '/get-authnumber-for-signup', {'phone':phone}).then(setTimer).catch(err=>{phoneNotAvail(err)});
        }
        // 3분타이머 표시
    }
    async function sendAuth(auth) {
        if (is_send_phone) {
            await axios.post(backend_address + '/auth-for-change-phone', {'phone':phone, 'auth':auth}, {
                headers : { 'Authorization': cookies.get('access_token') }
            }).then(res => {authOK(res)}).catch(err => {deniedAuth(err)});
        }
    }


    function passHandler(event) {
        let item = event.target.value;
        setPassword(item);
    }

    function newpassHandler(event) {
        let item = event.target.value;

        if(item.search(/\s/) !== -1) setPass_ok('공백은 포함될 수 없습니다');
        else if(item.length < 8 || item.length > 20) {
            setPass_ok('패스워드 길이는 8-20자 입니다');
            setNewpass(item);
        }
        else {
            setPass_ok('OK!');
            setNewpass(item);
        }
    }

    function cpHandler(event) {
        let item = event.target.value;
        setConfirmpass(item);
        
        if (item == '') {
            setSame_password('암호를 확인해주세요');
        }
        else if (item == newpass) {
            setSame_password('OK!');
        }
        else {
            setSame_password('패스워드가 일치하지 않습니다');
        }
    }

    async function updatepass() {
        if (pass_ok === 'OK!' && same_password === 'OK!'){
            await axios.post(backend_address + '/update-password', {'password':password, 'newpassword':newpass}, {
                headers : { 'Authorization': cookies.get('access_token') }
            }).then(()=>passchanged())
        }
        else {
            if (pass_ok !== 'OK!') {
                setMsg(pass_ok);   
            }
            else {
                setMsg(same_password);
            }
            setAlert(true);
        }
    }

    function passchanged() {
        setSame_password('');
        setPass_ok('');
        setNewpass('');
        setConfirmpass('');
        setPassword('');
        setPassedit(false);
        setMsg('패스워드가 성공적으로 변경되었습니다.')
        setAlert(true);
    }

    async function authOK(result) {
        let _user = new User();
        _user.set(user);
        _user.phone = phone;
        setAuth('');
        setUser(_user);
        setPhoneedit(false);
        setMsg('전화번호가 변경되었습니다');
        setAlert(true);
    }

    function deniedAuth(err) {
        console.log('인증에러');
        console.log(err);
        setMsg('인증에 실패했습니다. 중복가입되었을 수 있으니 아이디/비번찾기를 진행해보세요.');
        setAlert(true);
    }

    function phoneHandler(event) {
        let item = event.target.value;
        setIs_send_phone(false);
        const regExp = /[^0-9]/g; 
        if(item.length > 11) {}
        else if(item=='') setPhone(item);
        else if(regExp.test(item)) {} 
        else setPhone(item);
    }

    function setTimer() {
        setMsg('인증번호가 전송되었습니다');
        setAlert(true);
        /* pass */
    }

    function phoneNotAvail(err) {
        console.log('sms에러');
        console.log(err);
        if (err.response.status === 400) {
            setMsg('이미 등록된 번호입니다. 아이디/비번찾기를 진행해보세요.');
            setAlert(true);
        }
        else {
            setMsg('인증번호를 보낼 수 없었습니다');
            setAlert(true);
        }
    }

    async function resign(user) {
        
        if (user.balance !== 0 ) {
            setMsg('현재 예약중이거나 정산할 금액이 존재하면 온라인 회원탈퇴가 불가능합니다.')
            setAlert(true);
            return;
        }
        await axios.post(backend_address + '/resign', '', {
            headers : { 'Authorization': cookies.get('access_token') }
        }).then(()=>{
            setUser(new User());
            try {
                cookies.remove('access_token');
                cookies.remove('oldbooks');
                setDate(new Date());
            } catch (error) {
                console.log(error)
            }
            setIs_logged(false);
            let cont = '0;url='+frontend_address
            return (
                <meta httpEquiv="refresh" content={cont}></meta>
            );
        });
    }
}

export default Account;