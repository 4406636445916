
import { Button, Col, Container, Dropdown, Form, Nav, Navbar, Row } from "react-bootstrap";
import Title from "../brand";
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";
import { backend_address, BookList, frontend_address, User } from "../data";
import { alert_context, cookies, date_context, log_context, info_context } from '../App';
import { useHistory, useLocation } from 'react-router-dom';

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {ko} from 'date-fns/esm/locale';
import { Redirect } from "react-router-dom";
import moment from "moment-timezone";
moment.tz.setDefault('Asia/Seoul');

// 아이디 입력받기
// 패스워드 입력받기
// ID/비밀번호 찾기로 이동기능
// 합주실 이용안내

function Head(props) {
    const [is_logged, setIs_logged] = useContext(log_context);
    const [is_book_page, setIs_book_page] = useState(false);

    useEffect(()=>{ 
        if (props.page === 'book' || props.page === 'manage') setIs_book_page(true);
        
    },[]);
    return (
        <Navbar bg='dark' variant='dark' expand='lg' sticky='top' fixed='top'>
            <Container fluid style={{display: 'inline-block', whiteSpace: 'nowrap'}} >
                <Title to='/' />
                
                
                { is_logged && <ShowUser page={props.page} /> }

                { is_logged && !is_book_page && <ToBook /> }
            
                { !is_logged && <LogInForm isBook={is_book_page} /> }

                { is_book_page && <SelectDate is_admin={props.is_admin} /> }
                
            </Container>
        </Navbar>
    );
}

function SelectDate(props) {

    let [date, setDate] = useContext(date_context);
    const CInput = React.forwardRef(({ value, onClick }, ref) => (
        <Button onClick={onClick} ref={ref}>
          {value}
        </Button>
    ));
    if (props.is_admin) {
        return (
            <span className="customDatePickerWidth">
                <DatePicker 
                dateFormat='MM월 dd일(eee)'
                locale={ko}
                selected={date}
                disabledKeyboardNavigation 
                customInput={<CInput />}
                onChange={(d) => {
                if (d != date) {
                    setDate(d);
                    }
                }}/>
            </span>
        );
    }
    else {
        return (
            <span className="customDatePickerWidth">
                <DatePicker 
                dateFormat='MM월 dd일(eee)'
                locale={ko}
                selected={date}
                disabledKeyboardNavigation 
                minDate={new Date()}
                maxDate={new Date().addDays(60)}
                placeholderText='오늘부터 60일 후까지 예약을 확인할 수 있습니다.'
                customInput={<CInput />}
                onChange={(d) => {
                if (d != date) {
                    setDate(d);
                    }
                }}/>
            </span>
        );
    }
}


function ToBook() {
    let history = useHistory();
    let [setMsg, setAlert] = useContext(alert_context);
    let [is_logged,setIs_logged] = useContext(log_context);
    
    function onHandler() {
        if (is_logged) {
            history.push('/book');
        }
        else {
            setMsg('예약하려면 로그인하셔야 합니다');
            setAlert(true);
        }
    }

    return (
        <Button onClick={onHandler}>예약하기</Button>
    );
}

function ShowUser(props) {
    const [is_admin, setIs_admin] = useState(false);
    let [user, setUser] = useContext(info_context);
    let history = useHistory();
    let [is_logged, setIs_logged] = useContext(log_context);
    let [date, setDate] = useContext(date_context);

    useEffect(()=>{
        if(user.nick_name === '') {
            checkToken(setUser, setDate, setIs_logged);  
        }
        if(user.id) {
            axios.post(backend_address + '/admincheck', '',{
                headers : { 'Authorization': cookies.get('access_token') }
            }).then((res)=>{
                if (res.data) {
                    setIs_admin(res.data);
                }
            }).catch(()=>{
                window.location.href = frontend_address;
            });
        }
    },[user]);
    useEffect(()=>{
        if(user.nick_name === '_탈퇴회원') {
            logOut(setUser, setDate, setIs_logged);
        }
    });
    
    return (
        <span style={{position: 'relative', float: 'right'}}>

        
        <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
                {user.nick_name}님
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item as='button' onClick={()=>logOut(setUser, setDate, setIs_logged)}>로그아웃</Dropdown.Item>
                <Dropdown.Item href='/account'>정보수정</Dropdown.Item>
                {/* <Dropdown.Item as='button'>이용내역</Dropdown.Item> */}
                {is_admin && <Dropdown.Divider />}
                {is_admin && <Dropdown.Item href='/manage'>예약관리</Dropdown.Item>}
                {is_admin && <Dropdown.Item href='/todaybook'>최근예약변동</Dropdown.Item>}
                {is_admin && <Dropdown.Item href='/bookinfo'>예약찾기</Dropdown.Item>}
                {is_admin && <Dropdown.Item href='/mng-cancel'>취소관리</Dropdown.Item>}
                {is_admin && <Dropdown.Item href='/mng-users'>유저관리</Dropdown.Item>}
                {is_admin && <Dropdown.Item href='/mng-nonmembers'>비회원관리</Dropdown.Item>}
                {is_admin && <Dropdown.Item href='/mng-setting'>합주실관리</Dropdown.Item>}
                
                
            </Dropdown.Menu>
        </Dropdown>
        </span>
    );
}

async function checkToken(setUser, setDate, setIs_logged) {
    let token = cookies.get('access_token');
    
    if (token) {
        // 유저정보가 비어서 넘어온거니까 토큰 있으면 유저정보 채워야함.
        axios.post(backend_address+'/user-info','',{
            headers: {
                'Authorization' : token
            }}).then((res)=>{ 
            let userAccount = new User();
            userAccount.set(res.data);
            setUser(userAccount);
            setIs_logged(true);
        }).catch(()=>{
            logOut(setUser, setDate, setIs_logged);
        })
        return
    }
    else{
        logOut(setUser, setDate, setIs_logged);
    }
}

function logOut(setUser, setDate, setIs_logged) {

    cookies.remove('access_token', {path: '/'});
    cookies.remove('oldbooks', {path: '/'});
    setDate(new Date());

    setUser(new User());
    setIs_logged(false);
    
    window.location.href = frontend_address;
}

// 로그인 Form
// 아이디, 비밀번호, 로그인버튼
// 로그인버튼 누르면 onSubmitHandler() 호출
function LogInForm(props) {
    const [is_logged,setIs_logged] = useContext(log_context);
    const history = useHistory();
    const location = useLocation();
    const [user,setUser] = useContext(info_context);
    const [user_id, setUser_id] = useState('');
    const [password, setPassword] = useState('');
    const [setMsg, setAlert] = useContext(alert_context);
    
    // 토큰 있는지만 체크해서 있으면 LogInForm 끄고 ShowUser 켜기
    useEffect(()=>{
        if (cookies.get('access_token')) {
            setIs_logged(true);
        }
        else if (location.pathname !== '/') history.push('/');
    });

    function idHandler(event) {
        setUser_id(event.target.value);
    }

    function passwordHandler(event) {
        setPassword(event.target.value);
    }   

    async function onSubmitHandler(event) {
        event.preventDefault();
        if (user_id == '') {
            // 아이디 입력하라고 경고
            setMsg('아이디를 입력해주세요');
            setAlert(true);
        }
        else if (password == '') {
            // 암호 입력하라고 경고
            setMsg('암호를 입력해주세요');
            setAlert(true);
        }
        else {
            let data = {
                'user_id' : user_id,
                'password' : password
            };
            // 백엔드로 데이터 전송
            await axios.post(backend_address+'/login', data).then( res => {success(res)}).catch(err => {deniedAuth(err)});
        }
    }

    function success(res) {
        // utc 시간 isoformat으로 넘어온 만료일. 여기에 지역시간계산해서 로컬시간으로 넣어야함.
        
        // let utc = new Date(res.data.exp);
        // let locale = new Date(utc - (new Date().getTimezoneOffset() * 60 *1000));
        // console.log('쿠키 만료시각:')
        // console.log(locale);
        //쿠키에 토큰 저장 만료시간 없으면 브라우저 꺼질때 로그아웃됨
        // cookies.set('access_token', res.data.access_token, {path: '/', expires: locale}); 
        if (res.data.user === '_sleeper') {
            window.location.href = '/activation';
            return;
        } 
        cookies.set('access_token', res.data.access_token, {path: '/'});
        let userAccount = new User();
        userAccount.set(res.data.user);
        setUser(userAccount);
        setIs_logged(true);
    }

    function deniedAuth(err) {
        
        setIs_logged(false);
        setUser_id('');
        setPassword('');
        setMsg('로그인에 실패했습니다');
        setAlert(true);
    }

    return (
        
        <Form>   
            <Row>
                <Col>
                    <Row>
                        <Col lg></Col>
                        <Col>
                        <Form.Group className='mb-3' controlId="formBasicId">
                            <Form.Control type='text' placeholder='아이디' value={user_id} onChange={idHandler}/>
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group className='mb-3' controlId="formBasicPassword">
                            <Form.Control type='password' placeholder='패스워드' value={password} onChange={passwordHandler} />
                        </Form.Group>
                        </Col>
                        <Col xs={3}>
                            <Button type='submit' onClick={onSubmitHandler}>로그인</Button>
                        </Col>
                    </Row>
                </Col>
                <Col md>
                    <Row xs={2}>
                        <Col>
                            <Button variant='dark' href='/sign-up'>회원가입</Button>
                        </Col>
                        <Col>
                            <Button variant='dark' href='help'>ID/비번분실</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
        
    );
}

export default Head;

