import axios from "axios";
import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { Button, Form, Table } from "react-bootstrap";
import { cookies } from "../App";
import { NowLoading } from "../brand";
import { backend_address, Clock } from "../data";
import Head from "../root/head";





async function loadCancelList({setList}) {
    let res = await axios.post(backend_address+'/get-cancel-list', '', {
        headers: {'Authorization': cookies.get('access_token')}
    })
    setList(res.data);
    return res.data;
}

function ManageCancel() {
    const [list, setList] = useState();
    const {data: clist, error, isLoading } = useAsync({promiseFn: loadCancelList, setList});
    
    if (isLoading) {
        return (
            <NowLoading />
        );
    }
    if(error) {
        console.log(error);
        return (
            <div></div>
        );
    }
    if(clist !== undefined) {
        
        return (
            <div>
                <Head />
                <h4>취소관리</h4>
                <Table>
                    <thead style={{textAlign:'center', fontWeight:'bold', fontSize: '1.4vh', whiteSpace: 'nowrap'}}>
                        <tr>
                            <th style={{width: '3.5vw'}}></th>
                            <th style={{width: '6vw'}}>폰번호</th>
                            <th style={{width: '6vw'}}>닉네임</th>
                            <th style={{width: '6vw'}}>예약일</th>
                            <th style={{width: '6vw'}}>예약시간</th>
                            <th style={{width: '3vw'}}>예약룸</th>
                            <th style={{width: '2vw'}}>선결제</th>
                            <th style={{width: '6vw'}}>취소일</th>
                            <th style={{width: '6vw'}}>위약금</th>
                            <th>메모</th>
                        </tr>
                    </thead>
                    <tbody style={{textAlign:'center', fontSize: '1.4vh', whiteSpace: 'nowrap'}}>
                        {list.map((book)=>(              
                            <tr key={book.book_id+'clist'}>
                                <CanceledBook book={book} setList={setList}/>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                
            </div>
        );
    }
    else {
        return (
            <div></div>
        );
    }
}

function CanceledBook (props) {
    const [editdesc, setEditdesc] = useState(false);
    const [desc, setDesc] = useState(props.book.description);

    return(
        <>
        <td><Button size='sm' variant='danger' style={{fontSize: '1.4vh'}} onClick={async ()=> await delBook(props.book, props.setList)}>삭제</Button></td>
        <td>{props.book.phone}</td>
        <td>{props.book.nick_name}</td>
        <td>{props.book.date}</td>
        <td>{Clock(props.book.start_time)}-{Clock(props.book.end_time)}</td>
        <td>{props.book.room_name}</td>
        <td>{props.book.paid_status}</td>
        <td>{props.book.cancel_date}</td>
        <td>{props.book.cancel_fee}</td>
        <td style={{textAlign: 'start'}}> 
            {!editdesc && <span style={{cursor:'pointer', fontSize:'1.4vh'}} onClick={()=>setEditdesc(true)}>➕{props.book.description}</span>}
            {editdesc && <div>
                <Form.Control type='text' size='sm' style={{fontSize: '1.4vh'}} value={desc} onChange={(e)=>descHandler(e,setDesc)} />
                <Button size='sm' onClick={async ()=> await updateDesc(desc, props.book, props.setList, setEditdesc)}>전송</Button>
            </div>}
        </td>
        </>
    );
}

function descHandler(event, setDesc) {
    setDesc(event.target.value);
}

async function updateDesc(desc, book, setList, setEditdesc) {
    book.description = desc;
    await axios.post(backend_address + '/update-canceled', book, {
        headers : { 'Authorization': cookies.get('access_token')}
    });
    let _list = await loadCancelList({setList});
    setList(_list);
    setEditdesc(false);
}

async function delBook(book, setList) {
    await axios.post(backend_address + '/delete-canceled', book, {
        headers : { 'Authorization': cookies.get('access_token')}
    });
    let _list = await loadCancelList({setList});
    setList(_list);
}

export default ManageCancel;
