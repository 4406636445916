// 합주실 세팅 페이지
import axios from "axios";
import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { cookies, initStarTree } from "../App";
import { NowLoading } from "../brand";
import { backend_address, frontend_address } from "../data";
import Head from "../root/head";

async function admincheck() {
    // 토큰 검사해서 true, false
    let result = await axios.post(backend_address + '/admincheck', '',{
        headers : { 'Authorization': cookies.get('access_token') }
    })
    return result.data;
}

function Setting() {
    const { data: result, error, isLoading } = useAsync({ promiseFn: admincheck });
    let admin_level = result;
    
    if (isLoading) {
        return (
            <NowLoading />
        );
    }

    if (error) {
        let cont = '0;url='+frontend_address
        return (
            <meta httpEquiv="refresh" content={cont}></meta>
        );
    }

    if (!result) {
        let cont = '0;url='+frontend_address
        return (
            <meta httpEquiv="refresh" content={cont}></meta>
        );
    }

    return (
        <div>
            
            <Head page='setting' />
            <SettingBody level={admin_level} />
            
        </div>
    );

}

function SettingBody(props) {
    let st = cookies.get('startree');
    const [noti,setNoti] = useState(st.noti);
    const [startree, setStartree] = useState(st);
    const [is_adminlevel, setIs_adminlevel] = useState(false);
    const [sms, setSms] = useState(startree.send_message)
    useEffect(()=>{
        
        if (props.level === 'admin') {
            setIs_adminlevel(true);
        }
        else {
            setIs_adminlevel(false);
        }
       
    },[props.level]);
    return(
        <Container style={{width: '350px'}}>
            <br/>
            <Form style={{textAlign: 'left', fontSize: '14px'}}>
                <Form.Group>
                    <Row>
                        <Col xs={2}>
                            <Form.Label style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'
                            }}>공지사항</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control as='textarea' rows='3' value={noti} onChange={(event)=>{
                                setNoti(event.target.value);
                            }} placeholder='공지사항을 입력하세요'/>
                        </Col>
                        <Col xs={2}>
                            {noti !== startree.noti && <Button style={{whiteSpace: 'nowrap'}} size='sm' onClick={async ()=>{
                                st = await sendNotification(noti);
                                setStartree({...st});
                            }}>전송</Button>}
                            {noti === startree.noti && <Button style={{whiteSpace: 'nowrap'}} size='sm' disabled>전송</Button>}
                        </Col>
                    </Row>
                </Form.Group>
                <br/>
                {st.rooms.map((room, index)=>(
                    <div key={room.name+index+'roomprice'}>
                        <RoomPrice room={room} index={index} />
                    </div>
                ))}
                
            </Form>
            <br/>
            <br/>
            
            {is_adminlevel && <ManagerList />}

            <Form style={{textAlign: 'left', fontSize: '14px'}}>
                <Form.Group>
                    <Form.Check 
                        type="switch"
                        id="message-switch"
                        label="문자전송"
                        checked={sms}
                        onChange={async (event)=>{
                            setSms(event.target.checked);
                            await setMessage(event.target.checked);
                        }}
                    />
                </Form.Group>
            </Form>
        </Container>
    );
}

async function get_adminlist() {
    let result = await axios.post(backend_address + '/get-adminlist', '', {
        headers : { 'Authorization' : cookies.get('access_token') }
    })
    return result.data;
}

function ManagerList(props) {
    const { data: list, error, isLoading } = useAsync({ promiseFn: get_adminlist });
    const [phone, setPhone] = useState('');
    const [adminlist, setAdminlist] = useState(list);
    useEffect(()=>{
        setAdminlist(list);
    },[list]);
    if (isLoading) {
        return (
            <NowLoading />
        );
    }

    if (error) {
        return (
            <div>에러났어요</div>
        );
    }

    if (!adminlist) {
        return (
            <div>
            </div>
        );
    }
    
    return (
        <div>
            
            <h5>관리자 리스트</h5>
            <Table>
                <thead style={{ width: '340px', textAlign:'center', fontSize: '15px', whiteSpace: 'nowrap'}}>
                <tr>
                    <th style={{width:'20px'}}></th>
                    <th>전화번호</th>
                    <th>닉네임</th>
                    <th>등급</th>
                </tr>
                </thead>
                <tbody style={{fontSize: '15px', whiteSpace: 'nowrap'}}>            
                    {adminlist.map((admin)=>(
                        <tr key={admin.id+'adinfo'} >
                            <AdminInfo admin={admin} setAdminlist={setAdminlist} />
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <Button size='sm' style={{fontSize: '15px', whiteSpace: 'nowrap'}} onClick={async ()=>{
                                await registerManager(phone);
                                let result = await axios.post(backend_address + '/get-adminlist', '', {
                                    headers : { 'Authorization' : cookies.get('access_token') }
                                })
                                setPhone('');
                                setAdminlist(result.data);
                            }}>추가</Button>
                        </td>
                        <td>
                            <Form.Control type='text' value={phone} onChange={phoneHandler} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
    
    
    function phoneHandler(event) {
        setPhone(event.target.value);
    }
}

async function registerManager(phone, level='manager') {
    await axios.post(backend_address+'/update-manager',{'phone': phone, 'level': level},{
        headers: {'Authorization': cookies.get('access_token')}
    });
}

async function deleteManager(phone) {
    await axios.post(backend_address+'/delete-manager', {'phone': phone}, {
        headers: {'Authorization': cookies.get('access_token')}
    });
}

function AdminInfo(props) {
    const [level, setLevel] = useState(props.admin.level);
    return (
        <>
            <td>
                <Button size='sm' variant='danger' style={{fontSize: '15px', whiteSpace: 'nowrap'}} onClick={async ()=>{
                    await deleteManager(props.admin.phone);
                    let result = await axios.post(backend_address + '/get-adminlist', '', {
                        headers : { 'Authorization' : cookies.get('access_token') }
                    });
                    props.setAdminlist(result.data);
                }} >삭제</Button>
            </td>
            <td>{props.admin.phone}</td>
            <td>{props.admin.nick_name}</td>
            <td>
                <Form.Select style={{whiteSpace: 'nowrap', fontSize: '1.4vh'}} size="sm" value={level} onChange={async (event)=> {await updateLevel(event)}}>
                    <option value='admin'>admin</option>
                    <option value='manager'>manager</option>
                </Form.Select>
            </td>
        </>
    );
    async function updateLevel(event) {
        setLevel(event.target.value);
        await registerManager(props.admin.phone, event.target.value);
        let result = await axios.post(backend_address + '/get-adminlist', '', {
            headers : { 'Authorization' : cookies.get('access_token') }
        });
        props.setAdminlist(result.data);

    }
}

function RoomPrice(props) {
    let st = cookies.get('startree');
    const [cost, setCost] = useState(props.room.price);
    const [startree, setStartree] = useState(st);
    return (
        <Form.Group>
        <Row>
            
            <Col xs={2}>
                <Form.Label style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'
                }}>{props.room.name}룸요금</Form.Label>
            </Col>
            <Col>
                <Form.Control type='number' value={cost} onChange={(event)=>{
                    setCost(event.target.value);
                }} placeholder={0}/>
            </Col>
            <Col xs={2}>
                {cost !== startree.rooms[props.index].price && <Button style={{whiteSpace: 'nowrap'}} size='sm' onClick={async ()=>{
                    st = await sendRooms(cost, props.index);
                    setStartree({...st});
                }}>전송</Button>}
                {cost === startree.rooms[props.index].price && <Button style={{whiteSpace: 'nowrap'}} size='sm' disabled>전송</Button>}
            </Col>
        </Row>
        </Form.Group>
    );
}

async function sendRooms(cost, index) {
    let st = cookies.get('startree');
    st.rooms[index].price = cost;
    await axios.post(backend_address+'/set-rooms', {'rooms': JSON.stringify(st.rooms)}, {
        headers: { 'Authorization' : cookies.get('access_token') }
    })
    await initStarTree();
    return cookies.get('startree');
}

async function sendNotification(noti) {
    await axios.post(backend_address+'/set-notification', {'noti': noti}, {
        headers: { 'Authorization' : cookies.get('access_token') }
    })
    await initStarTree();
    return cookies.get('startree');
}   

async function setMessage(flag) {
    await axios.post(backend_address+'/set-message', {'send': flag}, {
        headers: { 'Authorization' : cookies.get('access_token') }
    })
    await initStarTree();
    return cookies.get('startree');
}

export default Setting;