import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Form, Overlay, Popover, Table } from 'react-bootstrap';
import { cookies } from '../App';
import { backend_address, miniClock } from '../data';
import Head from '../root/head';


function BookInfo() {
    const [nick, setNick] = useState('');
    const [phone, setPhone] = useState('');
    const [booklist, setBooklist] = useState([]);
    
    
    return (
        <div>
            <Head />
            <Form>
                <Form.Control value={nick} onChange={async (e)=> await nickHandler(e, setNick, setBooklist)} placeholder='닉' />
                <Form.Control value={phone} onChange={async (e)=> await phoneHandler(e, setPhone, setBooklist)} placeholder='폰번호' />
            </Form>
            
            <RecentBook list={booklist} />
        </div>
    );
}

async function nickHandler(e, setNick, setBooklist) {
    let item = e.target.value;
    if (item.length > 10) {}
    else {
        setNick(item);
    }
    if (item.length >= 1) {
        let request = {
            'type' : 'nick',
            'data' : item
        }
        await axios.post(backend_address+'/searchbooks', request, {
            headers : { 'Authorization': cookies.get('access_token') }
        }).then((res)=>{
            res.data.sort(function(a,b) {
                return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
            });
            setBooklist(res.data);
        });
    }
    else {
        setBooklist([]);
    }
}

async function phoneHandler(e, setPhone, setBooklist) {
    let item = e.target.value;
    const regExp = /[^0-9]/g; 
    if (item.length > 11) {}
    else if(item=='') setPhone(item);
    else if(regExp.test(item)) {} 
    else {
        setPhone(item);
    }
    if (item.length > 6) {
        let request = {
            'type' : 'phone',
            'data' : item
        }
        await axios.post(backend_address+'/searchbooks', request, {
            headers : { 'Authorization': cookies.get('access_token') }
        }).then((res)=>{
            res.data.sort(function(a,b) {
                return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
            });
            setBooklist(res.data);
        });
    }
    else {
        setBooklist([]);
    }
}

function RecentBook(props) {
    return (
        <Table>
            <thead style={{textAlign:'center', fontSize: '1.3vh', whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                <tr>
                    <th style={{width: '7vw'}}>예약일</th>
                    <th style={{width: '7vw'}}>시간</th>
                    <th style={{width: '7vw'}}>룸</th>
                    <th style={{width: '6vw'}}>닉네임</th>
                    <th style={{width: '6vw'}}>폰번호</th>
                    <th style={{width: '6vw'}}>결제</th>
                    <th style={{width: '3.5vw'}}>회차</th>
                    <th style={{width: '3.5vw'}}>횟수</th>
                    <th >메모</th>
                </tr>
            </thead>
            <tbody>
                {props.list.map((book)=>(
                    <tr key={book['book_id']} style={{fontSize: '1.3vh', whiteSpace: 'nowrap'}}>
                        <Book book={book} />
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

function Book(props) {
    const [prepay,setPrepay] = useState(' ');
    const [paid, setPaid] = useState(props.book['paid_status']);
    const [max, setMax] = useState(' ');
    useEffect(()=>{
        
        if (props.book.user['prepayment'] !== 0) {
            setPrepay(props.book['prepaycount']);
            setPaid('선불');
            setMax(props.book.user['count_max']);
        }
        else if (props.book.user['postpayment']) {
            setPaid('장부');
            setPrepay(' ');
            setMax(' ');
        }
        else {
            setPrepay(' ');
            setPaid(props.book['paid_status']);
            setMax(' ');
        }

    },[props.book]);
    return (
        <>
            <td>{props.book['date']}</td>
            <td>{miniClock(props.book['start_time'])}-{miniClock(props.book['end_time'])}</td>
            <td>{props.book['room_name']}</td>
            <td>{props.book['nick_name']}</td>
            <td>{props.book['phone']}</td>
            <td>{paid}</td>
            <td>{prepay}</td>
            <td>{max}</td>
            <td>{props.book['description']}</td>
        </> 
    )
}

export default BookInfo;