import axios from "axios";
import { useContext } from "react";
import { alert_context, cookies, startree } from "./App";
// 백엔드서버 주소
const backend_address = 'https://byulnamu.co.kr';
// const backend_address = 'http://localhost:8000';
const frontend_address = 'https://startree-frontend-new.vercel.app';
// 예약문의 전화번호
const book_phone = '010-3878-6642';
const COLOR = ['#64CD3C', '#A0A0FF', '#2ABCB4', '#FFEB46', '#BC8F8F', '#EEE8AA']

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate()+days);
    return date;
}
// 자바스크립트 날짜객체를 'YYYY-MM-DD' 서식의 스트링으로 변환
Date.prototype.getDateString = function() {
    let offset = this.getTimezoneOffset()
    let d = new Date(this.getTime()-(offset*60*1000));
    return d.toISOString().split('T')[0];
}

// 딥카피
function cloneObject(obj) {
    var clone = {};
    for (var key in obj) {
      if (typeof obj[key] == "object" && obj[key] != null) {
        clone[key] = cloneObject(obj[key]);
      } else {
        clone[key] = obj[key];
      }
    }
  
    return clone;
}

class StarTree {
    constructor() {
        this.open_time = 8;
        this.close_time = 31;
        this.rooms = [];
        this.contact = {};
        this.cancel_policy = {};
        this.fees = {};
        this.noti = '';
        this.sp_price = 10000;
        this.send_message = false;
    }
}

class Book {
    constructor(date, start_time, end_time, room_name, id, nick_name, created_at, book_id=null, color='') {
        this.date = date;
        this.start_time = start_time;
        this.end_time = end_time;
        this.room_name = room_name;
        this.id = id;
        this.phone= '';
        this.nick_name = nick_name;
        this.created_at = created_at;
        this.book_id = book_id;
        this.color = color;
    }
    
    getItemList() {
        let _items = [];
        for (let _i = this.start_time; _i < this.end_time; _i++) {
            _items.push(new BookItem(this.date, _i, this.room_name, this.id, this.nick_name, this.book_id, this.color));
        }
        return _items;
    }
    getData() {
        let bookdata = {
            'date' : this.date,
            'start_time' : this.start_time,
            'end_time' : this.end_time,
            'room_name' : this.room_name,
            'id' : this.id,
            'nick_name' : this.nick_name,
            'phone' : this.phone,
            'created_at' : this.created_at,
            'book_id' : this.book_id,
            'color' : this.color
        

        }
        return bookdata;
    }
}

class BookClass {
    constructor(date, start_time, end_time, room_name, id, nick_name, created_at, book_id=null, color='') {
        this.date = date;
        this.start_time = start_time;
        this.end_time = end_time;
        this.room_name = room_name;
        this.id = id;
        this.nick_name = nick_name;
        this.created_at = created_at;
        this.book_id = book_id;
        this.color = color
    }
    getItemList() {
        let _items = [];
        for (let _i = this.start_time; _i < this.end_time; _i++) {
            _items.push(new BookItem(this.date, _i, this.room_name, this.id, this.nick_name, this.book_id, this.color));
        }
        return _items;
    }
    
}

class BookManage extends Book {
    constructor(date, start_time, end_time, room_name, id, nick_name, created_at, book_id=null, color='') {
        super(date, start_time, end_time, room_name, id, nick_name, created_at, book_id, color)

    this.extra_fee = [];
    this.paid_status = '미납';
    this.pay_method = [];
    this.description = '';
    this.user = new User();
    this.apply_prepay = true;
    this.prepaycount = 0;
    }
    getCopy() {
        return new BookManage(this.date, this.start_time, this.end_time, this.room_name, this.id, this.nick_name, this.created_at, this.book_id, this.color);        
    }

    getData() {
        let bookdata = {
            'date' : this.date,
            'start_time' : this.start_time,
            'end_time' : this.end_time,
            'room_name' : this.room_name,
            'id' : this.id,
            'nick_name' : this.nick_name,
            'phone' : this.phone,
            'is_sp' : this.is_sp,
            'created_at' : this.created_at,
            'book_id' : this.book_id,
            'color' : this.color,
            'paid_status' : this.paid_status,
            'pay_method' : JSON.stringify(this.pay_method),
            'description' : this.description,
            'apply_prepay' : this.apply_prepay,
            'prepaycount' : this.prepaycount,
            'extra_fee' : JSON.stringify(this.extra_fee)
        }
        return bookdata;
    }

    setBookManage(obj) {
        
        if(obj !== undefined) {
            this.phone = obj.phone
            if (typeof obj.extra_fee === 'string') {
                
                this.extra_fee = JSON.parse(obj.extra_fee);
            }
            else {
                this.extra_fee = obj.extra_fee;
            }
            this.paid_status = obj.paid_status;
            if (typeof obj.pay_method === 'string') {
                this.pay_method = JSON.parse(obj.pay_method);
            }
            else {
                this.pay_method = obj.pay_method;
            }
            
            this.description = obj.description;
            this.is_sp = obj.is_sp;
            this.apply_prepay = obj.apply_prepay;
            this.prepaycount = obj.prepaycount;
            if (obj.user !== undefined) {
                this.user.id = obj.id;
                this.user.user_id = obj.user.user_id;
                this.user.phone = obj.phone;
                this.user.nick_name = obj.nick_name;
                this.user.balance = obj.user.balance;
                this.user.description = obj.user.description;
                this.user.last_contact = obj.user.last_contact;
                this.user.created_at = obj.user.created_at;
                this.user.custom = obj.user.custom;
                this.user.custom_price = obj.user.custom_price;
                this.user.prepayment = obj.user.prepayment;
                this.user.postpayment = obj.user.postpayment;
                this.user.count_max = obj.user.count_max;
                this.user.count_now = obj.user.count_now;
            }
        }
    }
    addFee(item,cost) {
        this.extra_fee[item] = cost;
    }
    pay(item,cost) {
        this.pay_method[item] = cost;
    }
    totalPrice(startree, roomstate) {
        
        let extra_fee = 0;
        for (let item of this.extra_fee) {
            extra_fee += item[1];
        }
        if (this.apply_prepay && this.user.prepayment > 0) {
            return extra_fee;
        }
        else {
            return this.roomPrice(startree, roomstate) + extra_fee;
        }
    }
    roomPrice(startree, roomstate) {

        let room_price;
        if (this.is_sp) {
            room_price = startree.sp_price * (this.end_time - this.start_time);
        }
        
        else if (this.user.custom) {
            room_price = this.user.custom_price[this.room_name] * (this.end_time - this.start_time);
        }
        
        else {            
            room_price = roomstate[this.room_name] * (this.end_time - this.start_time);
        }
        return room_price;
    }

    pay_stat(startree, roomstate) {
        let total = 0;
        for (let payment of this.pay_method) {
            if (payment[0] === '계좌') {
                
                if (payment[3]) { 
                    total += payment[1];
                    if (payment[2] === '' || payment[2] === undefined) {
                        payment[2] = new Date().getDateString();
                    }
                    
                }
            }
            else {
                total += payment[1];
                if (payment[2] === '' || payment[2] === undefined) {
                    payment[2] = new Date().getDateString();
                }
            }
        }
        if (this.totalPrice(startree, roomstate) - total === 0) {
            
            this.paid_status = '결제완료';
        }
        else {
            this.paid_status = '미납';
        }
        return this.paid_status;
    }
}

// =================== BookItem(날짜, 시작시간, 방이름, 유저아이디, 생성시간)
// 타임테이블에 표시하기 좋도록 예약을 시간단위로 쪼갠 객체

class BookItem {
    constructor(date, time, room_name, id, nick_name, book_id=undefined, color='') {
        this.date = date;
        this.time = time;
        this.room_name = room_name;
        this.id = id;
        this.nick_name = nick_name;
        this.book_id = book_id;
        this.color = color;
    }
}

class BookList {
    constructor (obj) {
        this.books = [];
        this.nick_name = '';
        if (obj !== undefined) {
            for (let unit of obj) {
                let _book = new Book(unit.date,unit.start_time,unit.end_time,unit.room_name,unit.id,unit.nick_name,unit.created_at,unit.book_id,unit.color);
                this.books.push(_book);
            }
        }
    }
    // 예약내역 정렬
    sort(mode='admin') {
        let roomidx = {}
        let startree = cookies.get('startree');
        let i = 0;
        for (let room of startree.rooms) {
            roomidx[room.name] = i;
            i++;
        }
        if (this.books.length > 1) {
            this.books.sort(function(a,b) {
                if (a.date === b.date) {
                    if (a.start_time === b.start_time) {
                        return roomidx[a.room_name] < roomidx[b.room_name] ? -1 : roomidx[a.room_name] > roomidx[b.room_name] ? 1 : 0;
                    }
                    return a.start_time < b.start_time ? -1 : a.start_time > b.start_time ? 1 : 0;
                }
                return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
            })
        
        }
        if(mode === 'admin') {
            for (let idx in this.books) {
                let idx2 = this.books.findIndex((book)=>{
                    return book.phone === this.books[idx].phone;
                });
                if (idx > idx2 + 1) {
                    
                    let temp = this.books[idx];
                    this.books.splice(idx, 1);
                    this.books.splice(idx2+1, 0, temp);
                }
            }
        }
    }
    
    // 
    setDailyBooks(books) {
        // Book 펑션 사용을 위해 Book 객체로 저장해야함
        this.books = [];
        this.nick_name = '';
        let _books = books;
        for (let i in _books) {
            let book = new Book(
                _books[i]['date'], 
                _books[i]['start_time'],
                _books[i]['end_time'],
                _books[i]['room_name'],
                _books[i]['id'],
                _books[i]['nick_name'],
                _books[i]['created_at'],
                _books[i]['book_id'],
                _books[i]['color']
                );
            this.books.push(book);
        }
    }
    setUserBooks(books) {
        // Book 펑션 사용을 위해 Book 객체로 저장해야함
        this.nick_name = books.nick_name;
        this.books = [];
        let _books = books.books;
        for (let i in _books) {
            let book = new Book(
                _books[i]['date'], 
                _books[i]['start_time'],
                _books[i]['end_time'],
                _books[i]['room_name'],
                Number(_books[i]['id']),
                _books[i]['nick_name'],
                _books[i]['created_at'],
                _books[i]['book_id'],
                _books[i]['color']
                );
            this.books.push(book);
        }
    }
    // 예약리스트를 BookItemList로 바꿔서 리턴
    getItemList() {
        let temp_items = [];
        let items = [];
        for (let i in this.books) {
            temp_items = this.books[i].getItemList();
            items.push(...temp_items);
        }
        return items;
    }
    // bookItem을 받아서 예약내역을 변경한다.
    update(bookitem) {
        // item.id = id 인 경우(예약추가된 경우), 
        //      item의 인접 시간에 예약이 있는지 확인한다.
        //      있으면 기존 예약 변경이므로, 기존 예약의 start_time이나 end_time을 변경한다.
        //      없으면 신규예약이므로, 신규예약내용을 생성한다.
    
        // 예약추가의 경우
        if (bookitem.id !== '_empty') {
            //아이템.time 이 end_time인 예약이 있는지 확인한다.
            let idx = this.books.findIndex((book)=>{
                return book.end_time === bookitem.time && book.date === bookitem.date && book.room_name === bookitem.room_name;
            });
            // 아이템.time +1이 start_time인 예약이 있는지 확인한다.
            let idx2 = this.books.findIndex((book)=>{
                return book.start_time === bookitem.time+1 && book.date === bookitem.date && book.room_name === bookitem.room_name;
            });
            // 둘다 있으면 두 예약 이어붙인다.
            if (idx > -1 && idx2 > -1 ) {
                this.books[idx].end_time = this.books[idx2].end_time;
                this.books[idx].created_at = new Date().getDateString();
                this.books.splice(idx2,1);
            } 
            else if (idx > -1) {
                this.books[idx].end_time++;
            }
            else if (idx2 > -1) {
                this.books[idx2].start_time--;
            }
            // 다 아니면 신규예약이니까 추가한다
            else {
                let new_book = new Book(
                    bookitem.date, bookitem.time, bookitem.time+1, 
                    bookitem.room_name, bookitem.id, this.nick_name, new Date().getDateString());
                this.books.push(new_book);
            }
        }
        // 예약 취소의 경우
        else {
            // bookitem.time 이 start_time 인 예약이 있는지 확인
            let idx = this.books.findIndex((book)=>{
                return book.start_time === bookitem.time && book.date === bookitem.date && book.room_name === bookitem.room_name;
            });
            // bookitem.time 이 end_time-1 인 예약이 있는지 확인
            let idx2 = this.books.findIndex((book)=>{
                return book.end_time-1 === bookitem.time && book.date === bookitem.date && book.room_name === bookitem.room_name;
            });
            // 둘다 충족하면 한시간짜리 예약이니까 예약 삭제
            if (idx > -1 && idx2 > -1) {
                this.books.splice(idx,1);
            }
            else if ( idx > -1 ) {
                this.books[idx].start_time++;
            }
            else if ( idx2 > -1 ) {
                this.books[idx2].end_time--;
            }
            //둘다 아니면 예약이 둘로 쪼개지는거임
            else {
                let idx3 = this.books.findIndex((book)=>{
                    return book.start_time < bookitem.time && bookitem.time < book.end_time -1 && book.date === bookitem.date && book.room_name === bookitem.room_name;
                });
                let new_book = new Book(
                    this.books[idx3].date, bookitem.time+1, this.books[idx3].end_time, 
                    this.books[idx3].room_name, this.books[idx3].id, 
                    this.books[idx3].nick_name, this.books[idx3].created_at);
                
                this.books[idx3].end_time = bookitem.time;
                this.books.push(new_book);
            }
        }
    }
    
}

class BookListManage extends BookList {
    constructor(obj) {
        super(obj)
        this.books = [];
        if (obj !== undefined) {
            for (let unit of obj) {
                let _book = new BookManage(unit.date,unit.start_time,unit.end_time,unit.room_name,unit.id,unit.nick_name,unit.created_at,unit.book_id,unit.color);
                _book.setBookManage(unit);
                this.books.push(_book);
            }
        }
    }
    
    updateManage(bookitem) {
        // item.id = id 인 경우(예약추가된 경우), 
        //      item의 인접 시간에 예약이 있는지 확인한다.
        //      있으면 기존 예약 변경이므로, 기존 예약의 start_time이나 end_time을 변경한다.
        //      없으면 신규예약이므로, 신규예약내용을 생성한다.
    
        // 예약추가의 경우
        if (bookitem.id !== '_empty') {
            //아이템.time 이 end_time인 예약이 있는지 확인한다.
            
            let idx = this.books.findIndex((book)=>{
                return book.end_time === bookitem.time && book.date === bookitem.date && book.room_name === bookitem.room_name && book.book_id == bookitem.book_id;
            });
            
            // 아이템.time +1이 start_time인 예약이 있는지 확인한다.
            let idx2 = this.books.findIndex((book)=>{
                return book.start_time === bookitem.time+1 && book.date === bookitem.date && book.room_name === bookitem.room_name && book.book_id == bookitem.book_id;
            });
            // 둘다 있으면 두 예약 이어붙인다.
            if (idx > -1 && idx2 > -1 ) {
                this.books[idx].end_time = this.books[idx2].end_time;
                this.books[idx].created_at = new Date().getDateString();
                this.books.splice(idx2,1);
            } 
            else if (idx > -1) {
                this.books[idx].end_time++;
            }
            else if (idx2 > -1) {
                this.books[idx2].start_time--;
            }
            // 다 아니면 신규예약이니까 추가한다
            else {
                let new_book = new BookManage(
                    bookitem.date, bookitem.time, bookitem.time+1, 
                    bookitem.room_name, bookitem.id, this.nick_name, new Date().getDateString(),'', '');
                this.books.push(new_book);
            }
        }
    

        // 예약 취소의 경우
        else {
            // bookitem.time 이 start_time 인 예약이 있는지 확인
            let idx = this.books.findIndex((book)=>{
                return book.start_time === bookitem.time && book.date === bookitem.date && book.room_name === bookitem.room_name;
            });
            // bookitem.time 이 end_time-1 인 예약이 있는지 확인
            let idx2 = this.books.findIndex((book)=>{
                return book.end_time-1 === bookitem.time && book.date === bookitem.date && book.room_name === bookitem.room_name;
            });
            // 둘다 충족하면 한시간짜리 예약이니까 예약 삭제
            if (idx > -1 && idx2 > -1) {
                this.books.splice(idx,1);
            }
            else if ( idx > -1 ) {
                this.books[idx].start_time++;
            }
            else if ( idx2 > -1 ) {
                this.books[idx2].end_time--;
            }
            //둘다 아니면 예약이 둘로 쪼개지는거임
            else {
                let idx3 = this.books.findIndex((book)=>{
                    return book.start_time < bookitem.time && bookitem.time < book.end_time -1 && book.date === bookitem.date && book.room_name === bookitem.room_name;
                });
                let new_book = new BookManage(
                    this.books[idx3].date, bookitem.time+1, this.books[idx3].end_time, 
                    this.books[idx3].room_name, this.books[idx3].id, 
                    this.books[idx3].nick_name, this.books[idx3].created_at,'', '');
                
                this.books[idx3].end_time = bookitem.time;
                this.books.push(new_book);
            }
        }
    }
}


class ScheduleTable {
    constructor(startree = '', date = new Date()) {
        if (startree === '') {
            this.open_time = 8;
            this.close_time = 31;
            this.rooms = [];
        }
        else {
            this.open_time = startree.open_time;
            this.close_time = startree.close_time;
            this.rooms = startree.rooms;
        }
        
        this.date = date;
        this.table = {};
        this.init();
    }
    init() {
        this.table = {};
        for (let i in this.rooms) {
            let schedule = [];
            for( let t = this.open_time; t < this.close_time; t++) {
                let unit = new BookItem(this.date.getDateString(), t, this.rooms[i].name, '_empty', '');
                schedule.push(unit);
            }
            this.table[this.rooms[i].name] = schedule;
        }
    }

    copyTable(scheduletable) {
        this.open_time = scheduletable.open_time;
        this.close_time = scheduletable.close_time;
        this.rooms = scheduletable.rooms;
        this.date = scheduletable.date;
        this.table = {...scheduletable.table};
    }

    setDailyTable(dailybooks, current_booklist, id) {
        //loadDailyTable 호출전에 항상 new ScheduleTable(startree, date)로 테이블을 초기화해야한다.
        // 서버에서 해당일자 예약 리스트를 불러온다 
        let templist = new BookList();
        templist.setDailyBooks(dailybooks.books);
        
        // 불러온 예약 리스트에서 id인 리스트를 삭제한다.
        templist.books = templist.books.filter((book)=>{
            return book.id != id;
        });
        
        // 현재 편집중에 있는 유저 예약 리스트중 해당 날짜의 예약을 추가한다.
        // daily에서 겹치는 예약을 지우기 떄문에 예약편집중에 기존 예약의 created_at을 보존하도록 잘 다루어야한다.
        let tempbooks = current_booklist.books.filter((book)=>{
            return book.date === this.date.getDateString();
        });
        
        templist.books.push(...tempbooks);
        // 예약 리스트를 BookItem 리스트로 바꿔서 테이블에 집어넣는다.
        let tempitems = templist.getItemList();
        for (let i in tempitems) {
            this.table[tempitems[i].room_name][tempitems[i].time-this.open_time] = tempitems[i];
        }
    }
    setManageTable(daily_booklist) {
        let tempitems = daily_booklist.getItemList();
        for (let item of tempitems) {
            this.table[item.room_name][item.time-this.open_time] = item;
        }
    }
    // 당일 합주실 여는 시간. 예약이 없는 날에는 close_time을 반환한다.
    startTime() {
        // 테이블 아이템을 꺼낸다.
        let opentime = this.close_time;
        this.rooms.map((room)=>{
            let idx = this.table[room.name].findIndex((item)=>{
                return item.id !== '_empty';
            });
            if ( idx <= -1) {
                //pass
            }
            // 더 빠른 시간
            else if (opentime > idx + this.open_time) {
                opentime = idx + this.open_time;
            }
        });
        return opentime; 
    }
    // 당일 합주실 닫는 시간. 예약이 없는 날에는 open_time을 반환한다.
    endTime() {
        // 테이블 아이템을 꺼낸다.
        let closetime = this.open_time;
        this.rooms.map((room)=>{
            let booked = this.table[room.name].filter((item)=>{
                 return item.id !== '_empty';
            });
            
            if ( booked.length === 0) {
                //pass
            }
            else {
                
                if (closetime < booked[booked.length-1]['time'] + 1 ) {
                
                    closetime = booked[booked.length-1]['time'] + 1;
                }

            } 
        });
        return closetime;

    }

}   

class User {
    constructor() {
        this.user_id='';         
        this.phone='';
        this.nick_name='';
        this.balance = 0;
        this.description = '';
        this.last_contact='';
        this.created_at='';
        this.id=null;
        this.books = [];
        this.custom = false;
        this.custom_price = {};
        this.postpayment = false;
        this.prepayment = 0;
        this.count_max = 0;
        this.count_now = 0;


    }
    /*
    get(obj) {
        this.user_id = obj['user_id'];
        this.phone = obj['phone'];
        this.nick_name = obj['nick_name'];
        this.last_contact = obj['last_contact'];
        this.created_at = obj['created_at'];
        this.id = Number(obj['id']);
        this.books = [...obj['books']];
    }
    */
    set(obj) {
        this.id = obj.id;
        this.user_id = obj.user_id;
        this.phone = obj.phone;
        this.nick_name = obj.nick_name;
        this.last_contact = obj.last_contact;
        this.created_at = obj.created_at;
        this.books = new BookList(obj.books.books);
        // 시간 지난 예약 솎아내기
        let today = new Date();
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate()-1);

        this.books.books = this.books.books.filter((book)=>{
            return book.date > today.getDateString() || (book.date === today.getDateString() && book.end_time >= today.getHours()+1) || (book.date === yesterday.getDateString() && book.end_time >= 24 && book.end_time >= today.getHours()+25);
        });
        this.books.nick_name = obj.nick_name;
    }
    setMember(obj) {
        this.id = obj.id;
        this.user_id = obj.user_id;
        this.phone = obj.phone;
        this.nick_name = obj.nick_name;
        this.balance = obj.balance;
        this.description = obj.description;
        this.last_contact = obj.last_contact;
        this.created_at = obj.created_at;
        this.prepayment = obj.prepayment;
        this.postpayment = obj.postpayment;
        this.count_max = obj.count_max;
        this.count_now = obj.count_now;
        this.custom = obj.custom;
        this.custom_price = obj.custom_price;  // custom_price = {'V': 20000, 'R': 18000,....}
        
        if (obj.books === undefined) {
            this.books = new BookListManage();
        }
        
        else if (obj.books.books !== undefined ) {
            
            this.books = new BookListManage(obj.books.books);
        }

        else {
           
            this.books = new BookListManage(obj.books);
        }
        this.books.nick_name = obj.nick_name;
    }
    getData() {
        let data = {
            'id': this.id,
            'user_id': this.user_id,
            'phone': this.phone,
            'nick_name': this.nick_name,
            'balance': this.balance,
            'description': this.description,
            'last_contact': this.last_contact,
            'created_at': this.created_at,
            'prepayment': this.prepayment,
            'postpayment': this.postpayment,
            'count_max': this.count_max,
            'count_now': this.count_now,
            'custom': this.custom,
            'custom_price': JSON.stringify(this.custom_price)
        }
        return data;
    }

}


class DayCalc {
    constructor(obj) {
        if (obj) {
            this.date = obj.date;
            this.cash_begin1 = obj.cash_begin1;
            this.cash_begin2 = obj.cash_begin2;
            this.cash_end1 = obj.cash_end1;
            this.cash_end2 = obj.cash_end2;
            this.cash_total = obj.cash_total;
            this.card_total = obj.card_total;
            this.trans_total = obj.trans_total;
            this.description = obj.description;
            
            if (typeof obj.room_state === 'string') {
                this.room_state = JSON.parse(obj.room_state);
            }
            else {
                this.room_state = obj.room_state;
            }
            if (typeof obj.extra_fee === 'string') {
                this.extra_fee = JSON.parse(obj.extra_fee);
            }
            else {
                this.extra_fee = obj.extra_fee;
            }
        }
        else {
            this.date = '';
            this.cash_begin1 = -1;
            this.cash_begin2 = -1;
            this.cash_end1 = -1;
            this.cash_end2 = -1;
            this.extra_fee = [];
            this.cash_total = 0;
            this.card_total = 0;
            this.trans_total = 0;
            this.description = '';
            this.room_state = {};
        }
    }   
    getData() {
        let data = {};
        data['date'] = this.date;
        data['cash_begin1'] = this.cash_begin1;
        data['cash_begin2'] = this.cash_begin2;
        data['extra_fee'] = JSON.stringify(this.extra_fee);
        data['description'] = this.description;
        data['card_total'] = this.card_total;
        data['cash_total'] = this.cash_total;
        data['trans_total'] = this.trans_total;
        data['cash_end1'] = this.cash_end1;
        data['cash_end2'] = this.cash_end2;
        data['room_state'] = JSON.stringify(this.room_state);
        return data;
    }
}


// AM 9:00 ---------------------- 출력
function TimeUnit(props) {
    return (
      <div className="timeline">
        { props.time }        
      </div>
    );
  }

// int를 받아서 AM 0:00 형식으로 출력
function Clock(t) {
  if (t >= 24) {
    return "AM "+String(t-24)+":00";
  }
  else if (t > 12) {
    return "PM "+String(t-12)+":00";
  }
  else if (t === 12) {
    return "PM "+String(t)+":00";
  }
  else {
    return "AM "+String(t)+":00";
  }
}

function miniClock(t) {
    if (t >= 24) {
      return String(t-24);
    }
    else if (t > 12) {
      return String(t-12);
    }
    else if (t === 12) {
      return String(t);
    }
    else {
      return String(t);
    }
  }

function is_book_conflict(bookA, bookB) {
        return bookB.date === bookA.date && 
            bookB.room_name === bookA.room_name && (
            (bookB.start_time <= bookA.start_time && bookA.start_time < bookB.end_time) ||
            (bookB.start_time < bookA.end_time && bookA.end_time <= bookB.end_time) ||
            (bookA.start_time <= bookB.start_time && bookB.end_time <= bookA.end_time)
            )
}

function* pickColor() {
    
    yield COLOR[0];
    yield COLOR[1];
    yield COLOR[2];
    yield COLOR[3];
}

function ToClipboard(props) {
    const startree = cookies.get('startree');
    const [setMsg, setAlert] = useContext(alert_context);
    let text ='';
    function maketext() {
        text += props.date+'\n';
        text += ('시작현금 '+props.daycalc.cash_begin1+'+'+props.daycalc.cash_begin2+'\n');
        text += (props.daycalc.description+'\n');
        props.daycalc.extra_fee.map((fee)=>{
            text += (fee[0]+fee[1]+fee[2]+'완료\n');
        });
        text += '====================\n'
        props.daily_booklist.books.map((book)=>{
            let totalPrice = book.totalPrice(startree, props.daycalc.room_state);
            text += (book.nick_name+'님'+' '+book.room_name+miniClock(book.start_time)+'-'+miniClock(book.end_time)+'\n');
            text += (book.description+'\n');
            if (book.apply_prepay === true && book.user.prepayment > 0) {
                text += (book.prepaycount + '/'+ book.user.count_max+'\n');
            }
            else {
                text += book.roomPrice(startree, props.daycalc.room_state);
            }
            book.extra_fee.map((fee)=>{
                text += ('+'+fee[0]+fee[1]);
            });
            if (totalPrice !== book.roomPrice(startree, props.daycalc.room_state)) {
                text += ('='+ totalPrice);
            }
            text += ' ';
            book.pay_method.map((method)=>{
                if (method[2] !== props.date) {
                    text += (method[2] + ' ');
                }
                if (method[1] === totalPrice && method[0] === '계좌') {
                    if (method[3]) {
                        text += '계좌이체완료\n';
                    }
                    else {
                        text += '계좌이체';
                    }
                }
                else if (method[1] === totalPrice) {
                    text += (method[0] +'결제완료');
                }
                else {
                    text += '\n';
                    if(method[0] === '계좌') {
                        if(method[3]) {
                            text += ('계좌'+method[1]+'완료 ');
                        }
                        else {
                            text += '계좌'+method[1]+' ';
                        }
                    }
                    else{
                        text += (method[0] + method[1] + ' ');
                    }
                }
            });
            text += '\n';
            text += '====================\n';
        });
        
        text += ('카드'+props.daycalc.card_total+' 현금'+(props.daycalc.cash_total+props.daycalc.trans_total)+' (계좌'+props.daycalc.trans_total+' 매장'+props.daycalc.cash_total+')'+'\n');
        text += ('종료현금 '+props.daycalc.cash_end1+'+'+props.daycalc.cash_end2);
    }
    return(
        <span style={{cursor: 'pointer'}} onClick={()=>{
            maketext();
            navigator.clipboard.writeText(text).then(()=>{
                setMsg('정산이 클립보드에 복사되었습니다.');
                setAlert(true);
            });
        }}>📋️</span>
    );
}


export { ToClipboard, DayCalc, BookItem, miniClock, pickColor, BookListManage, BookManage, book_phone, cloneObject, StarTree, backend_address, frontend_address, User, TimeUnit, Clock, ScheduleTable, BookList, BookClass, is_book_conflict, COLOR }